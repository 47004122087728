import React from "react";

export default function DashboardWidgetDetailsToggle({
  showDetails,
  handleSetShowDetails,
}) {
  function toggleShowDetails() {
    handleSetShowDetails(!showDetails);
  }

  return (
    <div
      className={`border-2 border-gray-100 dark:border-gray-700 font-bold select-none ${
        showDetails
          ? "bg-gray-100 hover:bg-gray-200 active:bg-white hover:border-gray-200 dark:bg-gray-700 dark:active:bg-gray-800 dark:active:border-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300 dark:active:text-gray-400"
          : "bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 hover:border-gray-100 dark:hover:border-gray-700 active:bg-gray-200 active:border-gray-200 dark:active:bg-gray-600 dark:active:border-gray-600 text-gray-400 dark:text-gray-400 dark:active:text-gray-300"
      } h-full rounded-xl cursor-pointer flex items-center justify-center px-3`}
      onClick={() => toggleShowDetails()}
    >
      Details
    </div>
  );
}
