import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import SettingsMenu from "./SettingsMenu";
import CharacterAndFontSettings from "./CharacterAndFontSettings";
import AudioSettings from "./AudioSettings";
import AlgorithmSettings from "./AlgorithmSettings";
import ResetSettings from "./ResetSettings";
import ExportSettings from "./ExportSettings";
import FinickySettings from "./FinickySettings";
import TonesSettings from "./TonesSettings";
import StudySettings from "./StudySettings";
import IntegrationSettings from "./IntegrationSettings";
import { API_ENDPOINT } from "./constants";

export default function Settings({
  token,
  userDarkMode,
  userCharacterSet,
  userSimplifiedFont,
  userTraditionalFont,
  userToneMarks,
  userColoredCharacters,
  userColoredPinyin,
  userPlaybackGender,
  userPlaybackSpeed,
  userReviewMode,
  userAutoCopyWords,
  userDistinguishHardWords,
  userLearningPace,
  userAssumptionCheckRate,
  userPIM,
  userNIM,
  userPauseMemoryDecay,
  userFirstDestination,
  userPinyinOnly,
  userShortcutReminders,
  userShowSentences,
  numWordsAssumedKnown,
  userMasteryThreshold,
  userLeechThreshold,
  userFlatReviews,
  userRandomizedIntervals,
  numWordsSurpassedMasteryThresholds,
  numWordsWithLifetimeFailTotals,
  userNumReviewWords,
  personalLists,
}) {
  const [darkMode, setDarkMode] = useState(userDarkMode);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [activeScreen, setActiveScreen] = useState("characters");
  const [characterSet, setCharacterSet] = useState(userCharacterSet);
  const [toneMarks, setToneMarks] = useState(userToneMarks);
  const [coloredCharacters, setColoredCharacters] = useState(
    userColoredCharacters
  );
  const [coloredPinyin, setColoredPinyin] = useState(userColoredPinyin);
  const [simplifiedFont, setSimplifiedFont] = useState(userSimplifiedFont);
  const [traditionalFont, setTraditionalFont] = useState(userTraditionalFont);

  const [playbackGender, setPlaybackGender] = useState(userPlaybackGender);
  const [playbackSpeed, setPlaybackSpeed] = useState(userPlaybackSpeed);

  const [reviewMode, setReviewMode] = useState(userReviewMode);
  const [autoCopyWords, setAutoCopyWords] = useState(userAutoCopyWords);
  const [distinguishHardWords, setDistinguishHardWords] = useState(
    userDistinguishHardWords
  );

  const [learningPace, setLearningPace] = useState(userLearningPace);
  const [assumptionCheckRate, setAssumptionCheckRate] = useState(
    userAssumptionCheckRate
  );

  const [positiveIntervalModifier, setPositiveIntervalModifier] =
    useState(userPIM);
  const [negativeIntervalModifier, setNegativeIntervalModifier] =
    useState(userNIM);

  const [masteryThreshold, setMasteryThreshold] =
    useState(userMasteryThreshold);

  const [leechThreshold, setLeechThreshold] = useState(userLeechThreshold);

  const [flatReviews, setFlatReviews] = useState(userFlatReviews);

  const [randomizedIntervals, setRandomizedIntervals] = useState(
    userRandomizedIntervals
  );

  const [pauseMemoryDecay, setPauseMemoryDecay] =
    useState(userPauseMemoryDecay);

  const [firstDestination, setFirstDestination] =
    useState(userFirstDestination);
  const [pinyinOnly, setPinyinOnly] = useState(userPinyinOnly);
  const [shortcutReminders, setShortcutReminders] = useState(
    userShortcutReminders
  );
  const [showSentences, setShowSentences] = useState(userShowSentences);

  const tl = useRef();
  const blurryBackgroundRef = useRef();
  const closeSettingsModalRef = useRef();
  // const settingsModalRef = useRef();
  const positionHelperRef = useRef();
  const whiteRef = useRef();
  const menuRef = useRef();
  const contentsRef = useRef();

  useEffect(() => {
    tl.current = gsap
      .timeline({ paused: true })
      .from(blurryBackgroundRef.current, {
        opacity: 0,
        duration: 0.2,
      })

      .from(
        positionHelperRef.current,
        {
          xPercent: -100,
          duration: 0.4,
        },
        "<"
      )

      .from(
        menuRef.current,
        {
          xPercent: -100,
          duration: 0.6,
          ease: "back",
        },
        "<"
      )
      .from(
        contentsRef.current,
        {
          xPercent: -100,
          duration: 0.7,
          ease: "back",
        },
        "<"
      )
      .from(
        closeSettingsModalRef.current,
        {
          // x: -100,
          opacity: 0,
          duration: 0.4,
          // ease: "back",
        },
        "<"
      );
  }, []);

  useEffect(() => {
    if (showSettingsModal) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [showSettingsModal]);

  function handleCloseSettingsModal() {
    setShowSettingsModal(false);
  }

  function toggleDarkMode() {
    saveSettings("dark_mode", !darkMode);
    setDarkMode(!darkMode);
    document.getElementsByTagName("html")[0].classList.toggle("dark");
  }

  function handleSetActiveScreenWithSelect() {
    var e = document.getElementById("screenSelector");
    setActiveScreen(e.value);
  }

  function removeClassByPrefix(node, prefix) {
    var regx = new RegExp(`\\b${prefix}[^ ]*[ ]?\\b`, "g");
    node.className = node.className.replace(regx, "");
    return node;
  }

  function saveSettings(option, choice) {
    const data = {
      option: option,
      choice: choice,
    };

    fetch(`${API_ENDPOINT}/set_study_options`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    })
      // .then((response) => response.json())
      .then((response) => {
        // console.log(`response from server: ${JSON.stringify(response)}`);
      });
  }

  // put overflow-hidden and h-80vh on #main-content when this modal appears in order to remove the scrollbar that isn't part of the modal
  // use tailwind scrollbars, so good!

  return (
    <>
      <div
        className="nav-btn settings-btn md:mr-3"
        onClick={() => setShowSettingsModal(true)}
      >
        <div className="rounded-xl h-full md:px-6 flex flex-col items-center justify-center">
          <div className="h-[26px] flex flex-col md:flex-row items-center justify-center">
            <i className="fa-regular fa-cog text-base md:mr-2"></i>
            <div>Settings</div>
          </div>
        </div>
      </div>

      <div
        ref={blurryBackgroundRef}
        className={`force-blurry fixed ${
          showSettingsModal ? "pointer-events-auto" : "pointer-events-none"
        } inset-0 z-[60]`}
      ></div>

      <div className="fixed top-0 left-0 h-screen w-screen z-[60] pointer-events-none">
        <div className="relative mx-auto h-full a-container-width">
          <div
            ref={positionHelperRef}
            className="flex w-full h-full md:absolute inset-0 inset-y-0 md:-left-[1080px] md:w-[1080px]"
          >
            <div
              ref={whiteRef}
              className="absolute opacity-0 md:opacity-100 inset-0 z-30 md:bg-white md:dark:bg-gray-700"
            >
              white
            </div>
            <div
              ref={menuRef}
              className="opacity-0 pointer-events-none md:opacity-100 md:pointer-events-auto md:absolute flex flex-col inset-y-0 z-30 w-[100px] md:w-[252px] md:-right-[212px] lg:w-[328px] lg:-right-[288px] bg-white dark:bg-gray-700 pt-3 md:pl-[40px] md:pt-14"
            >
              <SettingsMenu
                activeScreen={activeScreen}
                setActiveScreen={setActiveScreen}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
              />
            </div>
            <div
              id="settings-modal"
              ref={contentsRef}
              className="absolute flex flex-col inset-y-0 z-10 w-full px-3 md:px-6 md:w-[630px] md:-right-[768px] lg:w-[886px] lg:-right-[1024px]  wrong-xl:w-[1142px] xl-[852px] xl:-right-[990px] wrong-xl:-right-[1280px] md:pl-[86px] lg:pl-[235px] border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-700 shadow-2xl md:rounded-r-2xl pointer-events-auto text-gray-500 dark:text-gray-400 pt-26 md:py-14 xmd:pr-[50px] md:pr-0"
            >
              <div
                ref={closeSettingsModalRef}
                onClick={() => handleCloseSettingsModal()}
                className="fixed top-0 right-0 mt-5 mr-6 hidden md:flex items-center justify-center h-12 px-6 cursor-pointer text-gray-400 dark:text-gray-400  hover:bg-gray-100 dark:hover:bg-gray-600 rounded-2xl text-2xl z-30"
              >
                <i className="fa-regular fa-xmark"></i>
              </div>
              <div className="z-50 absolute top-0 left-0 w-full md:hidden flex h-20 bg-gray-200 text-gray-600 dark:text-gray-300 dark:bg-gray-600 text-lg">
                <div className="h-full w-3/5 p-3">
                  <div className="text-xs font-bold">Settings for:</div>
                  <select
                    id="screenSelector"
                    value={activeScreen}
                    onChange={() => handleSetActiveScreenWithSelect()}
                    name="secondary-settings-options"
                    className="bg-gray-100 dark:bg-gray-500 dark:text-white outline-none rounded-xl h-10 mt-1 w-full text-sm px-3"
                  >
                    <option value="characters">Characters</option>
                    <option value="tones">Tones</option>
                    <option value="audio">Audio</option>
                    <option value="algorithm">Algorithm</option>
                    <option value="study">Study</option>
                    <option value="integrations">Integrations</option>
                    <option value="finicky">Finicky</option>
                    <option value="export">Export</option>
                  </select>
                </div>
                <div
                  className="flex flex-col items-center justify-center h-full w-1/5 p-3 text-xs border-l border-gray-300 dark:border-gray-500 text-center"
                  onClick={() => toggleDarkMode()}
                >
                  <span className="mb-2 font-bold">Dark Mode</span>

                  <div
                    className={`dark-mode-toggle flex items-center p-2px w-7 rounded-xl h-4 transition-all duration-300 bg-white dark:bg-black ${
                      darkMode ? "justify-end" : ""
                    }`}
                  >
                    <div className="h-3 w-3 rounded-full bg-gray-400 dark:bg-blue-neon"></div>
                  </div>
                </div>
                <div
                  className="h-full p-3 w-1/5 flex items-center justify-center flex-col border-l border-gray-300 dark:border-gray-500 text-2xl"
                  onClick={() => handleCloseSettingsModal()}
                >
                  <i className="fa-regular fa-xmark"></i>
                </div>
              </div>
              <div
                className={`relative w-full max-w-prose overflow-y-auto scrollbar pr-6 md:pr-12 pb-12 pt-24 md:pt-0 ${
                  coloredCharacters ? "colored-characters" : ""
                } ${coloredPinyin ? "colored-pinyin" : ""}`}
              >
                {activeScreen === "characters" && (
                  <CharacterAndFontSettings
                    removeClassByPrefix={removeClassByPrefix}
                    saveSettings={saveSettings}
                    characterSet={characterSet}
                    setCharacterSet={setCharacterSet}
                    toneMarks={toneMarks}
                    setToneMarks={setToneMarks}
                    coloredCharacters={coloredCharacters}
                    setColoredCharacters={setColoredCharacters}
                    coloredPinyin={coloredPinyin}
                    setColoredPinyin={setColoredPinyin}
                    simplifiedFont={simplifiedFont}
                    setSimplifiedFont={setSimplifiedFont}
                    traditionalFont={traditionalFont}
                    setTraditionalFont={setTraditionalFont}
                  />
                )}

                {activeScreen === "tones" && (
                  <TonesSettings
                    removeClassByPrefix={removeClassByPrefix}
                    saveSettings={saveSettings}
                    characterSet={characterSet}
                    toneMarks={toneMarks}
                    setToneMarks={setToneMarks}
                    coloredCharacters={coloredCharacters}
                    setColoredCharacters={setColoredCharacters}
                    coloredPinyin={coloredPinyin}
                    setColoredPinyin={setColoredPinyin}
                  />
                )}

                {activeScreen === "audio" && (
                  <AudioSettings
                    token={token}
                    saveSettings={saveSettings}
                    playbackGender={playbackGender}
                    setPlaybackGender={setPlaybackGender}
                    playbackSpeed={playbackSpeed}
                    setPlaybackSpeed={setPlaybackSpeed}
                  />
                )}
                {activeScreen === "algorithm" && (
                  <AlgorithmSettings
                    saveSettings={saveSettings}
                    learningPace={learningPace}
                    setLearningPace={setLearningPace}
                    assumptionCheckRate={assumptionCheckRate}
                    positiveIntervalModifier={positiveIntervalModifier}
                    setPositiveIntervalModifier={setPositiveIntervalModifier}
                    negativeIntervalModifier={negativeIntervalModifier}
                    setNegativeIntervalModifier={setNegativeIntervalModifier}
                    numWordsAssumedKnown={numWordsAssumedKnown}
                    setAssumptionCheckRate={setAssumptionCheckRate}
                    pauseMemoryDecay={pauseMemoryDecay}
                    setPauseMemoryDecay={setPauseMemoryDecay}
                    masteryThreshold={masteryThreshold}
                    setMasteryThreshold={setMasteryThreshold}
                    leechThreshold={leechThreshold}
                    setLeechThreshold={setLeechThreshold}
                    flatReviews={flatReviews}
                    setFlatReviews={setFlatReviews}
                    randomizedIntervals={randomizedIntervals}
                    setRandomizedIntervals={setRandomizedIntervals}
                    numWordsSurpassedMasteryThresholds={
                      numWordsSurpassedMasteryThresholds
                    }
                    numWordsWithLifetimeFailTotals={
                      numWordsWithLifetimeFailTotals
                    }
                    userNumReviewWords={userNumReviewWords}
                  />
                )}
                {activeScreen === "study" && (
                  <StudySettings
                    saveSettings={saveSettings}
                    reviewMode={reviewMode}
                    setReviewMode={setReviewMode}
                    autoCopyWords={autoCopyWords}
                    setAutoCopyWords={setAutoCopyWords}
                    distinguishHardWords={distinguishHardWords}
                    setDistinguishHardWords={setDistinguishHardWords}
                  />
                )}

                {activeScreen === "finicky" && (
                  <FinickySettings
                    saveSettings={saveSettings}
                    firstDestination={firstDestination}
                    setFirstDestination={setFirstDestination}
                    showSentences={showSentences}
                    setShowSentences={setShowSentences}
                    pinyinOnly={pinyinOnly}
                    setPinyinOnly={setPinyinOnly}
                    shortcutReminders={shortcutReminders}
                    setShortcutReminders={setShortcutReminders}
                  />
                )}

                {activeScreen === "integrations" && (
                  <IntegrationSettings saveSettings={saveSettings} />
                )}

                {activeScreen === "export" && (
                  <ExportSettings personalLists={personalLists} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
