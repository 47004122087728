import React from "react";

export default function WordImportNav({
  screen,
  setScreen,
  numMatches,
  numDuplicates,
  numAmbiguous,
  numMissing,
  ambiguousComplete,
  missingComplete,
}) {
  return (
    <div className="flex justify-center w-full space-x-3 h-[196px] fixed bg-white dark:bg-gray-800 py-12 z-10 text-xs sm:text-sm md:text-base">
      <div
        onClick={() => setScreen("matches")}
        className="group cursor-pointer flex items-center flex-col"
      >
        <div
          className={`wis-nav-button complete  ${
            screen === "matches" ? "selected" : ""
          } `}
        >
          <i className="fa-solid fa-check md:text-lg"></i>
        </div>

        <div className="mt-3  text-gray-400">Matches</div>
        <div className="text-xs md:text-sm text-gray-400">
          {numMatches} word{`${numMatches != 1 ? "s" : ""}`}
        </div>
      </div>
      <div className="flex h-8 items-center justify-center">
        <div className="w-[2px] h-8 sm:w-8 lg:w-24 sm:h-[2px] bg-gray-100 dark:bg-gray-600"></div>
      </div>
      <div
        onClick={() => setScreen("duplicates")}
        className="group cursor-pointer flex flex-col items-center"
      >
        <div
          className={`wis-nav-button complete  ${
            screen === "duplicates" ? "selected" : ""
          } `}
        >
          <i className="fa-solid fa-check md:text-lg"></i>
        </div>
        <div className="mt-3  text-gray-400">Duplicates</div>
        <div className="text-xs md:text-sm text-gray-400">
          {numDuplicates} word{`${numDuplicates != 1 ? "s" : ""}`}
        </div>
      </div>
      <div className="flex h-8 items-center justify-center">
        <div className="w-[2px] h-8 sm:w-8 lg:w-24 sm:h-[2px] bg-gray-100 dark:bg-gray-600"></div>
      </div>
      <div
        onClick={() => setScreen("ambiguous")}
        className="group cursor-pointer flex items-center flex-col"
      >
        <div
          className={`wis-nav-button ${ambiguousComplete ? "complete" : ""}  ${
            screen === "ambiguous" ? "selected" : ""
          } `}
        >
          {ambiguousComplete && (
            <i className="fa-solid fa-check md:text-lg"></i>
          )}
          {!ambiguousComplete && "3"}
        </div>
        <div className="mt-3 ">Ambiguous</div>
        <div className="text-xs md:text-sm">
          {numAmbiguous} word{`${numAmbiguous != 1 ? "s" : ""}`}
        </div>
      </div>
      <div className="flex h-8 items-center justify-center">
        <div className="w-[2px] h-8 sm:w-8 lg:w-24 sm:h-[2px] bg-gray-100 dark:bg-gray-600"></div>
      </div>
      <div
        onClick={() => setScreen("missing")}
        className="group cursor-pointer flex items-center flex-col"
      >
        <div
          className={`wis-nav-button ${missingComplete ? "complete" : ""} ${
            screen === "missing" ? "selected" : ""
          } `}
        >
          {missingComplete && <i className="fa-solid fa-check md:text-lg"></i>}
          {!missingComplete && "4"}
        </div>
        <div className="mt-3  text-center">Missing</div>
        <div className="text-xs md:text-sm">
          {numMissing} word{`${numMissing != 1 ? "s" : ""}`}
        </div>
      </div>
      <div className="flex h-8 items-center justify-center">
        <div className="w-[2px] h-8 sm:w-8 lg:w-24 sm:h-[2px] bg-gray-100 dark:bg-gray-600"></div>
      </div>
      <div
        onClick={() => setScreen("finish")}
        className="flex items-center flex-col"
      >
        <div
          className={`wis-nav-button  ${
            screen === "finish" ? "selected" : ""
          } `}
        >
          5
        </div>
        <div className="mt-3  text-center">Finish</div>
      </div>
    </div>
  );
}
