import React, { useState, useEffect } from "react";
import PreviouslySubmittedWordRow from "./PreviouslySubmittedWordRow";

export default function ConfirmPreviouslySubmittedWords({
  token,
  listsAndFocuses,
  previouslySubmittedWords,
}) {
  return (
    <div className="flex flex-col text-lg mt-12">
      {previouslySubmittedWords.map((item, index) => (
        <PreviouslySubmittedWordRow
          key={index}
          token={token}
          listsAndFocuses={listsAndFocuses}
          pendingWord={item}
        />
      ))}
    </div>
  );
}
