import React, { useState, useEffect } from "react";
import NewStudySessionLimits from "./NewStudySessionLimits";
import CustomStudyModes from "./CustomStudyModes";
import RegularStudyModes from "./RegularStudyModes";
import { API_ENDPOINT } from "./constants";

export default function NewStudySession({
  cramMode,
  desiredStudyDuration,
  preferredStudyLimit,
  preferredStudyWords,
  token,
  hasAssumed,
  hasHard,
  hasStrong,
  hasWeak,
  focus_id,
  exitPath,
  controller,
}) {
  const [mode, setMode] = useState(null);
  const [limit, setLimit] = useState(preferredStudyLimit);
  const [minutes, setMinutes] = useState(desiredStudyDuration);
  const [words, setWords] = useState(preferredStudyWords);
  const [loading, setLoading] = useState(false);
  const [studyModesToDisplay, setStudyModesToDisplay] = useState("regular");

  useEffect(() => {
    if (mode) {
      setLoading(true);
      if (exitPath) {
        localStorage.setItem("ssExitPath", exitPath);
      } else {
        localStorage.removeItem("ssExitPath");
      }

      document
        .getElementById("close-study-session-setup-modal")
        ?.classList.add("hidden");

      startStudySession();
    }
  }, [mode]);

  function startStudySession() {
    const data = {
      study_session: {
        limit: limit,
        minutes: minutes,
        words: words,
        mode: mode,
        focus_id: focus_id,
      },
    };

    fetch(`${API_ENDPOINT}/study_sessions/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        window.location.href = response.path;
      });
  }

  return (
    <>
      <div
        className={`flex flex-col relative transition-all duration-1000 ease-in `}
      >
        <div
          className={`overlay-modal fixed inset-0 transition-all duration-1000 bg-black z-50 flex justify-center items-center ${
            loading
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <span className="loader"></span>
        </div>

        <div
          className={`${
            loading ? "transition-all duration-1000 scale-75" : ""
          }`}
        >
          <NewStudySessionLimits
            limit={limit}
            setLimit={setLimit}
            minutes={minutes}
            setMinutes={setMinutes}
            words={words}
            setWords={setWords}
            token={token}
          />
          {cramMode === false && (
            <>
              {studyModesToDisplay === "regular" && (
                <RegularStudyModes
                  setMode={setMode}
                  setStudyModesToDisplay={setStudyModesToDisplay}
                  controller={controller}
                />
              )}
              {studyModesToDisplay === "custom" && (
                <CustomStudyModes
                  setMode={setMode}
                  setStudyModesToDisplay={setStudyModesToDisplay}
                  hasAssumed={hasAssumed}
                  hasHard={hasHard}
                  hasWeak={hasWeak}
                  hasStrong={hasStrong}
                />
              )}

              <div
                id="study-selection-hint"
                className="w-full transition duration-200 -mb-10 lg:-mb-16 absolute bottom-0 flex items-center justify-center text-sm"
              >
                <p className="text-sm w-56 text-center text-gray-600 dark:text-gray-400">
                  Choose study limits and mode, or change your Settings.
                </p>
              </div>
            </>
          )}
          {cramMode && (
            <div className="flex flex-col items-center justify-center mx-auto">
              <div
                className="study-mode-btn group major h-24"
                onClick={() => setMode("cram")}
              >
                <div className="study-mode-btn-panel flex w-1/3 lg:w-full group-hover:border-green group-hover:bg-green group-hover:text-white">
                  <i className="fa-solid fa-signal-stream"></i>
                </div>
                <div className="study-mode-btn-desc major w-2/3 lg:w-full group-hover:border-green">
                  <div className="lg:text-xl group-hover:text-green font-semibold mb-1 lg:mb-4 dark:text-white">
                    Cram
                  </div>
                  <div className="text-xs lg:text-sm lg:text-center">
                    Strengthen your short-term memories from this list only.
                  </div>
                </div>
              </div>
              <p className="mt-10 text-sm text-center">
                For best results, use sparingly.
                <br />
                <a href="/study-guide/cramming" className="textlink">
                  Learn more
                </a>
                .
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
