import React, { useState, useEffect } from "react";

export default function AlgorithmSettings({
  saveSettings,
  learningPace,
  setLearningPace,
  numWordsAssumedKnown,
  assumptionCheckRate,
  setAssumptionCheckRate,
  positiveIntervalModifier,
  setPositiveIntervalModifier,
  negativeIntervalModifier,
  setNegativeIntervalModifier,
  masteryThreshold,
  setMasteryThreshold,
  leechThreshold,
  setLeechThreshold,
  flatReviews,
  setFlatReviews,
  randomizedIntervals,
  setRandomizedIntervals,
  pauseMemoryDecay,
  setPauseMemoryDecay,
  numWordsSurpassedMasteryThresholds,
  numWordsWithLifetimeFailTotals,
  userNumReviewWords,
}) {
  const [showPauseDecayConfirmation, setShowPauseDecayConfirmation] =
    useState(false);

  function handleSetLearningPace(pace) {
    setLearningPace(pace);
    saveSettings("new_word_limit", pace);
  }

  function handleSetAssumptionCheckRate(rate) {
    setAssumptionCheckRate(rate);
    saveSettings("num_assumptions_to_check_daily", rate);
  }

  function handleSetPositiveIntervalModifier(pim) {
    setPositiveIntervalModifier(pim);
    saveSettings("pim", pim);
  }

  function handleSetNegativeIntervalModifier(nim) {
    setNegativeIntervalModifier(nim);
    saveSettings("nim", nim);
  }

  function handleSetMasteryThreshold(threshold) {
    setMasteryThreshold(threshold);
    saveSettings("mastery_threshold", threshold);
  }

  function handleSetLeechThreshold(threshold) {
    setLeechThreshold(threshold);
    saveSettings("leech_threshold", threshold);
  }

  function toggleFlatReviews() {
    saveSettings("flat_reviews", !flatReviews);
    setFlatReviews(!flatReviews);
  }

  function toggleRandomizedIntervals() {
    saveSettings("randomized_intervals", !randomizedIntervals);
    setRandomizedIntervals(!randomizedIntervals);
  }

  function handlePauseDecay() {
    saveSettings("memory_decay", !pauseMemoryDecay);
    setPauseMemoryDecay(!pauseMemoryDecay);
    setShowPauseDecayConfirmation(false);
  }

  function handleToggleMemoryDecay() {
    if (pauseMemoryDecay) {
      saveSettings("memory_decay", false);
      setPauseMemoryDecay(false);
    } else {
      setShowPauseDecayConfirmation(true);
    }
  }

  return (
    <>
      <div className="setting-title mt-4">Learning Speed (# Words per Day)</div>
      <div className="flex mt-2">
        <div className="flex text-gray-400 font-semibold">
          <div
            className={`multi-toggle small ${
              learningPace === 0 ? "selected" : ""
            }`}
            onClick={() => handleSetLearningPace(0)}
          >
            0
          </div>
          <div
            className={`multi-toggle small ${
              learningPace === 3 ? "selected" : ""
            }`}
            onClick={() => handleSetLearningPace(3)}
          >
            3
          </div>
          <div
            className={`multi-toggle small ${
              learningPace === 5 ? "selected" : ""
            }`}
            onClick={() => handleSetLearningPace(5)}
          >
            5
          </div>
          <div
            className={`multi-toggle small ${
              learningPace === 8 ? "selected" : ""
            }`}
            onClick={() => handleSetLearningPace(8)}
          >
            8
          </div>
          <div
            className={`multi-toggle small ${
              learningPace === 10 ? "selected" : ""
            }`}
            onClick={() => handleSetLearningPace(10)}
          >
            10
          </div>
          <div
            className={`multi-toggle small ${
              learningPace === 20 ? "selected" : ""
            }`}
            onClick={() => handleSetLearningPace(20)}
          >
            20
          </div>

          <div
            className={`multi-toggle small ${
              learningPace === 10000 ? "selected" : ""
            }`}
            onClick={() => handleSetLearningPace(10000)}
          >
            <i className="fa-solid fa-infinity"></i>
          </div>
        </div>
      </div>
      <table className="mt-3 text-sm text-gray-400">
        <tbody>
          <tr>
            <td className="w-[130px] font-semibold text-gray-500 dark:text-gray-300 pr-3 border-b border-gray-100 dark:border-gray-600">
              {learningPace === 0 && "0 words"}
              {learningPace === 3 && "1,095 words"}
              {learningPace === 5 && "1,825 words"}
              {learningPace === 8 && "2,920 words"}
              {learningPace === 10 && "3,650 words"}
              {learningPace === 20 && "7,300 words"}
              {learningPace === 10000 && "Variable"}
            </td>
            <td className="border-b border-gray-100 dark:border-gray-600">
              annual vocabulary growth
            </td>
          </tr>
          <tr>
            <td className="w-[130px] font-semibold text-gray-500 dark:text-gray-300 pr-3">
              {learningPace === 0 && "Zero"}
              {learningPace === 3 && "Extremely Low"}
              {learningPace === 5 && "Low"}
              {learningPace === 8 && "Low"}
              {learningPace === 10 && "Moderate"}
              {learningPace === 20 && "High"}
              {learningPace === 10000 && "Extremely High"}
            </td>
            <td className="">risk of burnout</td>
          </tr>
        </tbody>
      </table>
      <p>
        Limiting the number of words you learn each day is smart. Read{" "}
        <a
          href="https://www.hackchinese.com/study-guide/our-best-advice-slow-fast"
          className="textlink"
          target="_blank"
          rel="noreferrer"
        >
          this article
        </a>{" "}
        to learn why.
      </p>
      <div className="setting-title mt-12">
        Assumption Check Rate (# Words per Day)
      </div>
      <div className="flex mt-2">
        <div className="flex text-gray-400 font-semibold">
          <div
            className={`multi-toggle small 0 ${
              assumptionCheckRate === 0 ? "selected" : ""
            }`}
            onClick={() => handleSetAssumptionCheckRate(0)}
          >
            0
          </div>
          <div
            className={`multi-toggle small ${
              assumptionCheckRate === 5 ? "selected" : ""
            }`}
            onClick={() => handleSetAssumptionCheckRate(5)}
          >
            5
          </div>
          <div
            className={`multi-toggle small ${
              assumptionCheckRate === 10 ? "selected" : ""
            }`}
            onClick={() => handleSetAssumptionCheckRate(10)}
          >
            10
          </div>
          <div
            className={`multi-toggle small ${
              assumptionCheckRate === 15 ? "selected" : ""
            }`}
            onClick={() => handleSetAssumptionCheckRate(15)}
          >
            15
          </div>

          <div
            className={`multi-toggle small ${
              assumptionCheckRate === 30 ? "selected" : ""
            }`}
            onClick={() => handleSetAssumptionCheckRate(30)}
          >
            30
          </div>

          <div
            className={`multi-toggle small ${
              assumptionCheckRate === 10000 ? "selected" : ""
            }`}
            onClick={() => handleSetAssumptionCheckRate(10000)}
          >
            <i className="fa-solid fa-infinity"></i>
          </div>
        </div>
      </div>
      <table className="mt-3 text-sm text-gray-400">
        <tbody>
          <tr>
            <td className="w-[130px] font-semibold text-gray-500 dark:text-gray-300 pr-3 border-b border-gray-100 dark:border-gray-600">
              <a href="/assumed" className="textlink">
                {numWordsAssumedKnown.toLocaleString("en-US")} words
              </a>
            </td>
            <td className="border-b border-gray-100 dark:border-gray-600">
              we assume you know
            </td>
          </tr>
          <tr>
            <td className="w-[130px] font-semibold text-gray-500 dark:text-gray-300 pr-3">
              {Math.round(
                numWordsAssumedKnown / assumptionCheckRate
              ).toLocaleString("en-US")}{" "}
              days
            </td>
            <td>until we have checked all our assumptions</td>
          </tr>
        </tbody>
      </table>
      <p>
        If you've studied Chinese before using Hack Chinese, you can mark words
        (or lists of words) as Assumed Known. Doing so puts a higher priority on
        learning other words (that are more likely truly "new" to you).{" "}
        <a
          href="https://www.hackchinese.com/study-guide/assuming-knowledge"
          target="_blank"
          className="textlink"
          rel="noreferrer"
        >
          Learn more
        </a>
        .
      </p>

      <div className="setting-title mt-12">
        Positive Interval Modifier (PIM)
      </div>
      <div className="flex mt-2">
        <div className="flex flex-col">
          <div className="grid grid-cols-4 gap-0 text-gray-400 font-semibold">
            <div
              className={`multi-toggle ${
                positiveIntervalModifier === 1.4 ? "selected" : ""
              }`}
              onClick={() => handleSetPositiveIntervalModifier(1.4)}
            >
              140%
            </div>

            <div
              className={`multi-toggle ${
                positiveIntervalModifier === 1.9 ? "selected" : ""
              }`}
              onClick={() => handleSetPositiveIntervalModifier(1.9)}
            >
              190%
            </div>
            <div
              className={`multi-toggle ${
                positiveIntervalModifier === 2.5 ? "selected" : ""
              }`}
              onClick={() => handleSetPositiveIntervalModifier(2.5)}
            >
              250%
            </div>
            <div
              className={`multi-toggle ${
                positiveIntervalModifier === 3.1 ? "selected" : ""
              }`}
              onClick={() => handleSetPositiveIntervalModifier(3.1)}
            >
              310%
            </div>
          </div>
          <div className="w-full flex justify-between items-center border-2 border-gray-100 dark:border-gray-600 rounded-xl mt-3 text-xs md:text-sm h-[40px] md:h-[50px]">
            <div className="pl-3 w-24 md:w-32">More reviews</div>

            <div className="text-center">
              <i className="fa-solid fa-arrows-left-right text-lg"></i>
            </div>

            <div className="pr-3 text-right w-24 md:w-32">Fewer reviews</div>
          </div>
        </div>
      </div>

      <p>
        When you remember a word, we calculate a new interval (the amount of
        time we wait before testing you on that word again) by multiplying the
        "time elapsed since previous review" by the PIM. A higher PIM requires
        fewer reviews but may result in a lower retention rate.
        <div className="w-full flex flex-col mt-4">
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-14 shrink-0">140%</div>
            <div className="flex-grow">
              Our "classic" PIM (the default until 2023) required 12 consecutive
              successful reviews in the first year and delivered an average
              retention rate of ~86%.
            </div>
          </div>

          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-14 shrink-0">190%</div>
            <div className="flex-grow">
              Our new default PIM requires 9 consecutive successful reviews in
              the first year.
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-14 shrink-0">250%</div>
            <div className="flex-grow">
              This PIM requires 6 consecutive successful reviews in the first
              year and works best when complemented with extensive
              comprehensible input (reading and listening practice).
            </div>
          </div>
          <div className="flex">
            <div className="w-14 shrink-0">310%</div>
            <div className="flex-grow">
              A relaxed PIM for people who have retired from "active" studying,
              are naturally immersed (typically as expats in China), and want a
              fail-safe against forgetting.
            </div>
          </div>
        </div>
        <div className="mt-3">
          <span className="text-green font-bold">Good News: </span> Changing
          your PIM will change your current review schedule!
        </div>
        <div className="hidden">
          The more important your perfect retention is in the short term (for
          example, students with at test coming soon), the more a lower PIM
          makes sense. The more you know you will be studying for a long time
          and you are engaging in other study activities as well, the higher you
          might put your PIM.
        </div>
      </p>

      <div className="setting-title mt-12">
        Negative Interval Modifier (NIM)
      </div>
      <div className="flex mt-2">
        <div className="flex flex-col">
          <div className="grid grid-cols-3 gap-0 text-gray-400 font-semibold">
            <div
              className={`multi-toggle ${
                negativeIntervalModifier === 0 ? "selected" : ""
              }`}
              onClick={() => handleSetNegativeIntervalModifier(0)}
            >
              0%
            </div>
            <div
              className={`multi-toggle ${
                negativeIntervalModifier === 0.25 ? "selected" : ""
              }`}
              onClick={() => handleSetNegativeIntervalModifier(0.25)}
            >
              25%
            </div>
            <div
              className={`multi-toggle ${
                negativeIntervalModifier === 0.5 ? "selected" : ""
              }`}
              onClick={() => handleSetNegativeIntervalModifier(0.5)}
            >
              50%
            </div>
          </div>
          <div className="w-full flex justify-between items-center border-2 border-gray-100 dark:border-gray-600 rounded-xl mt-3 text-xs md:text-sm h-[40px] md:h-[50px]">
            <div className="pl-3 w-24 md:w-32">More reviews</div>

            <div className="text-center">
              <i className="fa-solid fa-arrows-left-right text-lg"></i>
            </div>

            <div className="pr-3 text-right w-24 md:w-32">Fewer reviews</div>
          </div>
        </div>
      </div>

      <p>
        When you forget a word, it goes into a brief "re-learning" stage, after
        which we calculate a new interval for that word by multiplying the
        previous interval by the NIM.
        <div className="w-full flex flex-col mt-4">
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-14 shrink-0">0%</div>
            <div className="flex-grow">
              Our "classic" NIM (the default until 2023) set the interval of
              forgotten words to zero, forcing you to learn them from the
              beginning (even if you had previously remembered them for long
              stretches of time).
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-14 shrink-0">25%</div>
            <div className="flex-grow">
              Our new default NIM acknowledges that forgotten words need to be
              reviewed more frequently, but also recognizes that erasing all of
              your progress can be counterproductive.
            </div>
          </div>
          <div className="flex">
            <div className="w-14 shrink-0">50%</div>
            <div className="flex-grow">
              A NIM of 50% is significantly less penalizing to your hard-earned
              intervals and works best when complemented with extensive
              comprehensible input.
            </div>
          </div>
        </div>
      </p>

      <div className="setting-title mt-12">
        Leech Suspension Threshold (# of Lapses)
      </div>
      <div className="flex mt-2">
        <div className="flex flex-col">
          <div className="flex text-gray-400 font-semibold">
            <div
              className={`multi-toggle ${
                leechThreshold === 10000 ? "selected" : ""
              }`}
              onClick={() => handleSetLeechThreshold(10000)}
            >
              None
            </div>
            <div
              className={`multi-toggle small ${
                leechThreshold === 12 ? "selected" : ""
              }`}
              onClick={() => handleSetLeechThreshold(12)}
            >
              12
            </div>
            <div
              className={`multi-toggle small ${
                leechThreshold === 10 ? "selected" : ""
              }`}
              onClick={() => handleSetLeechThreshold(10)}
            >
              10
            </div>
            <div
              className={`multi-toggle small ${
                leechThreshold === 8 ? "selected" : ""
              }`}
              onClick={() => handleSetLeechThreshold(8)}
            >
              8
            </div>
            <div
              className={`multi-toggle small ${
                leechThreshold === 6 ? "selected" : ""
              }`}
              onClick={() => handleSetLeechThreshold(6)}
            >
              6
            </div>
          </div>
          <div className="w-full flex justify-between items-center border-2 border-gray-100 dark:border-gray-600 rounded-xl mt-3 text-xs md:text-sm h-[40px] md:h-[50px]">
            <div className="pl-3 w-24 md:w-32">More reviews</div>

            <div className="text-center">
              <i className="fa-solid fa-arrows-left-right text-lg"></i>
            </div>

            <div className="pr-3 text-right w-24 md:w-32">Fewer reviews</div>
          </div>
        </div>
      </div>

      <p>
        When you forget a review word, it is called a "lapse". Words you lapse
        on frequently are called "leeches". Suspending (ceasing to review)
        leeches can greatly decrease study time without significantly decreasing
        total vocabulary size.
        <br />
        <br />
        In general, we recommend a leech suspension threshold that represents
        2-5% of your total vocabulary.
        <div className="w-full flex flex-col mt-4">
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">None</div>
            <div className="flex-grow">Never suspend leeches.</div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">12 lapses</div>
            <div className="flex-grow">
              {(
                (numWordsWithLifetimeFailTotals[12] / userNumReviewWords) *
                100
              ).toFixed(1)}
              % of your vocabulary ({numWordsWithLifetimeFailTotals[12]} words).
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">10 lapses</div>
            <div className="flex-grow">
              {(
                (numWordsWithLifetimeFailTotals[10] / userNumReviewWords) *
                100
              ).toFixed(1)}
              % of your vocabulary ({numWordsWithLifetimeFailTotals[10]} words).
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">8 lapses</div>
            <div className="flex-grow">
              {(
                (numWordsWithLifetimeFailTotals[8] / userNumReviewWords) *
                100
              ).toFixed(1)}
              % of your vocabulary ({numWordsWithLifetimeFailTotals[8]} words).
            </div>
          </div>
          <div className="flex">
            <div className="w-24 shrink-0">6 lapses</div>
            <div className="flex-grow">
              {(
                (numWordsWithLifetimeFailTotals[6] / userNumReviewWords) *
                100
              ).toFixed(1)}
              % of your vocabulary ({numWordsWithLifetimeFailTotals[6]} words).
            </div>
          </div>
        </div>
        <br />
        See your{" "}
        <a href="/leeches" className="textlink">
          leeches
        </a>
        .
      </p>

      <div className="setting-title mt-12">Mastery Threshold</div>
      <div className="flex mt-2">
        <div className="flex flex-col">
          <div className="grid grid-cols-4 gap-0 text-gray-400 font-semibold">
            <div
              className={`multi-toggle small extra ${
                masteryThreshold === 10000 ? "selected" : ""
              }`}
              onClick={() => handleSetMasteryThreshold(10000)}
            >
              None
            </div>
            <div
              className={`multi-toggle small extra ${
                masteryThreshold === 365 ? "selected" : ""
              }`}
              onClick={() => handleSetMasteryThreshold(365)}
            >
              1 year
            </div>
            <div
              className={`multi-toggle small extra ${
                masteryThreshold === 270 ? "selected" : ""
              }`}
              onClick={() => handleSetMasteryThreshold(270)}
            >
              9 months
            </div>
            <div
              className={`multi-toggle small extra ${
                masteryThreshold === 180 ? "selected" : ""
              }`}
              onClick={() => handleSetMasteryThreshold(180)}
            >
              6 months
            </div>
          </div>
          <div className="w-full flex justify-between items-center border-2 border-gray-100 dark:border-gray-600 rounded-xl mt-3 text-xs md:text-sm h-[40px] md:h-[50px]">
            <div className="pl-3 w-24 md:w-32">More reviews</div>

            <div className="text-center">
              <i className="fa-solid fa-arrows-left-right text-lg"></i>
            </div>

            <div className="pr-3 text-right w-24 md:w-32">Fewer reviews</div>
          </div>
        </div>
      </div>

      <p>
        {" "}
        Set the length of time you must remember a word before it is considered
        "mastered". Mastered words are not scheduled for further review.
        <div className="w-full flex flex-col mt-4">
          <div className="hidden flex font-bold border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">Threshold</div>
            <div className="flex-grow"># Words Reached Threshold</div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">None</div>
            <div className="flex-grow">
              With no mastery threshold, you will continue reviewing words
              forever. This is the default because spaced repetition naturally
              requires less frequent reviews as intervals grow.
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">1 year</div>
            <div className="flex-grow">
              {numWordsSurpassedMasteryThresholds[365]} review words have
              reached this threshold.
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">9 months</div>
            <div className="flex-grow">
              {numWordsSurpassedMasteryThresholds[270]} review words have
              reached this threshold.
            </div>
          </div>
          <div className="flex">
            <div className="w-24 shrink-0">6 months</div>
            <div className="flex-grow">
              {numWordsSurpassedMasteryThresholds[180]} review words have
              reached this threshold.
            </div>
          </div>
        </div>
        <div className="hidden w-full flex flex-col mt-4">
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">None</div>
            <div className="flex-grow">
              Our default is to never suspend words automatically, as growing
              intervals naturally result in less frequent reviews (on a per-word
              basis) anyway.
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">1 year</div>
            <div className="flex-grow">
              {positiveIntervalModifier === 1.4 && (
                <>
                  With your current PIM of 1.4, you will review each word about
                  16 times over the course of ~2 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 1.9 && (
                <>
                  With your current PIM of 1.9, you will review each word about
                  11 times over the course of ~2.5 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 2.5 && (
                <>
                  With your current PIM of 2.5, you will review each word about
                  9 times over the course of ~2 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 3.1 && (
                <>
                  With your current PIM of 3.1, you will review each word about
                  8 times over the course of ~2.3 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              So far, you've been able to remember{" "}
              {numWordsSurpassedMasteryThresholds[365]} words for at least one
              year.
            </div>
          </div>
          <div className="flex border-b border-gray-100 dark:border-gray-600 pb-2 mb-2">
            <div className="w-24 shrink-0">9 months</div>
            <div className="flex-grow">
              {positiveIntervalModifier === 1.4 && (
                <>
                  With your current PIM of 1.4, you will review each word about
                  15 times over the course of ~1.3 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 1.9 && (
                <>
                  With your current PIM of 1.9, you will review each word about
                  10 times over the course of ~1.4 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 2.5 && (
                <>
                  With your current PIM of 2.5, you will review each word about
                  8 times over the course of ~1 year before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 3.1 && (
                <>
                  With your current PIM of 3.1, you will review each word about
                  8 times over the course of ~2.3 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              So far, you've been able to remember{" "}
              {numWordsSurpassedMasteryThresholds[270]} words for at least nine
              months. You will not be tested on these words again if you choose
              the 9-month suspension threshold.
            </div>
          </div>
          <div className="flex ">
            <div className="w-24 shrink-0">6 months</div>
            <div className="flex-grow">
              {positiveIntervalModifier === 1.4 && (
                <>
                  With your current PIM of 1.4, you will review each word about
                  14 times over the course of ~1 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 1.9 && (
                <>
                  With your current PIM of 1.9, you will review each word about
                  9 times over the course of ~9 months before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 2.5 && (
                <>
                  With your current PIM of 2.5, you will review each word about
                  8 times over the course of ~1 year before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              {positiveIntervalModifier === 3.1 && (
                <>
                  With your current PIM of 3.1, you will review each word about
                  8 times over the course of ~2.3 years before it reaches this
                  suspension threshold.{" "}
                </>
              )}
              So far, you've been able to remember{" "}
              {numWordsSurpassedMasteryThresholds[180]} words for at least six
              months. You will not be tested on these words again if you choose
              the 6-month suspension threshold.
            </div>
          </div>
        </div>
        <br />
        See your{" "}
        <a href="/mastered" className="textlink">
          mastered words
        </a>
        .
      </p>

      <div
        className="flex items-center mt-12"
        onClick={() => toggleFlatReviews()}
      >
        <div className={`two-way-toggle ${flatReviews ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">Flat Review Schedule</div>
      </div>
      <p>
        Spaced repetition can naturally produce an uneven review schedule (i.e.
        50 reviews due today, 500 due tomorrow). You can "flatten" your review
        schedule by reviewing some words early. Flat review schedules are
        slightly less efficient, but some people find them much easier to
        follow.
      </p>

      <div
        className="flex items-center mt-12"
        onClick={() => toggleRandomizedIntervals()}
      >
        <div className={`two-way-toggle ${randomizedIntervals ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">
          Randomize New Intervals by +/- 5%
        </div>
      </div>
      <p>
        By randomly adjusting all new intervals by a small amount, words learned
        together are less likely to be reviewed together. Separating reviews in
        this way may help eliminate an artificial performance boost that can
        occur when seeing one word makes you think of another word.
      </p>

      {showPauseDecayConfirmation && (
        <div className="flex flex-col w-full p-3 md:p-8 bg-white border-4 border-red text-black rounded">
          <div className="text-2xl font-black">
            Are you{" "}
            <span className="bg-red bg-opacity-20 italic skew-x-12 px-1">
              sure?
            </span>
          </div>
          <div className="mt-3 text-sm md:text-base">
            By continuing, you are confirming that you have read{" "}
            <a
              className="textlink"
              href="https://www.hackchinese.com/study-guide/disabling-memory-decay"
              target="_blank"
              rel="noreferrer"
            >
              this article
            </a>{" "}
            and you are aware of the serious negative side-effects.
          </div>

          <div className="grid grid-cols-2 gap-3 md:gap-6 w-full h-12 mt-3 text-lg md:text-xl">
            <div
              className="flex items-center justify-center border-2 rounded h-full cursor-pointer shadow active:shadow-sm hover:bg-gray-50"
              onClick={() => setShowPauseDecayConfirmation(false)}
            >
              Nevermind
            </div>
            <div
              className="flex items-center justify-center h-full rounded bg-red text-white cursor-pointer shadow active:shadow-sm hover:bg-opacity-90"
              onClick={() => handlePauseDecay()}
            >
              PAUSE DECAY
            </div>
          </div>
        </div>
      )}

      {!showPauseDecayConfirmation && (
        <>
          <div
            className="flex items-center mt-12"
            onClick={() => handleToggleMemoryDecay()}
          >
            <div className={`two-way-toggle ${pauseMemoryDecay ? "on" : ""}`}>
              <div></div>
            </div>
            <div className="setting-title ml-4">
              Pause Memory Decay [Not Recommended]
            </div>
          </div>
          <p className="no-border">
            Students who cannot study for an extended period of time may wish to
            pause the decay of their memory strength in order to prevent a
            build-up of reviews due. Pausing memory decay distorts the memory
            model we are creating for you, resulting in a less optimal review
            order, so we highly recommend you read{" "}
            <a
              className="textlink"
              href="https://www.hackchinese.com/study-guide/disabling-memory-decay"
              target="_blank"
              rel="noreferrer"
            >
              this article
            </a>{" "}
            before pausing memory decay.
          </p>
        </>
      )}
    </>
  );
}
