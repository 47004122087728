import React, { useState } from "react";

export default function WordImportAmbiguousRow({
  ambiguousWordsWithSuggestions,
  aw,
  setChosenWordIds,
  chosenWordIds,
  rowNumber,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleChosenSuggestion(index) {
    setSelectedIndex(index);
    setChosenWordIds({
      ...chosenWordIds,
      [rowNumber]: ambiguousWordsWithSuggestions[aw][index]?.id,
    });
  }

  return (
    <div className="w-full flex ambiguous-row">
      <div className="w-full grid grid-cols-4 gap-8 ambiguous-word-options py-12">
        <div className="flex items-center justify-center simplified text-6xl">
          {aw}
        </div>
        <div className="grid grid-cols-3 gap-8 col-span-3">
          {ambiguousWordsWithSuggestions[aw].map((suggestion, index) => {
            return (
              <div
                onClick={() => handleChosenSuggestion(index)}
                key={suggestion.id}
                className={`${
                  selectedIndex == index
                    ? "ambiguous-selected"
                    : "hover:border-blue-brand/50 dark:hover:border-blue-neon/75 dark:border-gray-600"
                } relative flex flex-col w-full border-4 rounded-xl p-6 cursor-pointer bg-white dark:bg-gray-800`}
              >
                <div className="absolute checkmark top-0 right-0 mt-6 mr-6 text-blue-brand dark:text-blue-neon text-2xl">
                  <i className="fa-solid fa-circle-check"></i>
                </div>

                <div className="mt-3 pinyin text-4xl">{suggestion.opinyin}</div>
                <div className="mt-3 pinyin text-4xl">{suggestion.score}</div>
                <div className="mt-3 flex flex-wrap ">
                  {suggestion.english
                    .split("/")
                    .slice(0, 3)
                    .map((word, index) => {
                      return (
                        <div
                          key={`${word.simplified}-${index}`}
                          className="english bg-gray-100 dark:bg-gray-700 rounded p-2 mr-2 mb-2"
                        >
                          {word}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}

          <div
            onClick={() => handleChosenSuggestion(null)}
            className={`${
              selectedIndex == null
                ? "ambiguous-selected no-import"
                : "hover:border-red/50 dark:hover:border-red/75"
            } relative flex flex-col items-center justify-center w-full border-4 dark:border-gray-600 border-dashed rounded-xl p-6 cursor-pointer bg-white dark:bg-gray-800`}
          >
            <div className="absolute checkmark top-0 right-0 mt-6 mr-6 text-red text-2xl">
              <i className="fa-solid fa-ban"></i>
            </div>

            <div className="font-medium text-gray-400 text-lg">Do Not Add</div>
          </div>
        </div>
      </div>
    </div>
  );
}
