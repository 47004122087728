import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

export default function ToneSettings({
  saveSettings,
  toneMarks,
  setToneMarks,
  coloredCharacters,
  setColoredCharacters,
  coloredPinyin,
  setColoredPinyin,
}) {
  let bodyNode = document.getElementsByTagName("body")[0];

  function toggleColoredCharacters() {
    saveSettings("colored_characters", !coloredCharacters);
    setColoredCharacters(!coloredCharacters);
    document
      .getElementsByTagName("body")[0]
      .classList.toggle("colored-characters");
  }
  function toggleColoredPinyin() {
    saveSettings("colored_pinyin", !coloredPinyin);
    setColoredPinyin(!coloredPinyin);
    document.getElementsByTagName("body")[0].classList.toggle("colored-pinyin");
  }

  function handleSetToneMarks(tones) {
    setToneMarks(tones);
    saveSettings("pinyin_style", tones);
  }
  return (
    <>
      <div className="relative flex text-center items-end mt-4 h-[104px]">
        <div className="absolute grid grid-cols-6 gap-1">
          {toneMarks === "none" && (
            <>
              <div className="pinyin text-3xl t2">lao</div>
              <div className="pinyin text-3xl t3">ban</div>

              <div></div>
              <div className="pinyin text-3xl t4">bu</div>
              <div className="pinyin text-3xl t4">ke</div>
              <div className="pinyin text-3xl t4">qi</div>
            </>
          )}

          {toneMarks === "original" && (
            <>
              <div className="pinyin text-3xl t2">lǎo</div>
              <div className="pinyin text-3xl t3">bǎn</div>

              <div></div>
              <div className="pinyin text-3xl t4">bù</div>
              <div className="pinyin text-3xl t4">kè</div>
              <div className="pinyin text-3xl t4">qì</div>
            </>
          )}

          {toneMarks === "spoken" && (
            <>
              <div className="pinyin text-3xl t2">láo</div>
              <div className="pinyin text-3xl t3">bǎn</div>

              <div></div>
              <div className="pinyin text-3xl t2">bú</div>
              <div className="pinyin text-3xl t4">kè</div>
              <div className="pinyin text-3xl t4">qì</div>
            </>
          )}
          {toneMarks === "both" && (
            <>
              <div className="pinyin text-3xl t2">láo</div>
              <div className="pinyin text-3xl t3">bǎn</div>

              <div></div>
              <div className="pinyin text-3xl t2">bú</div>
              <div className="pinyin text-3xl t4">kè</div>
              <div className="pinyin text-3xl t4">qì</div>

              <div className="text-base mt-2 text-gray-300 dark:text-gray-500">
                <i className="fa-regular fa-up-long"></i>
              </div>
              <div
                className={`${
                  toneMarks === "both" ? "opacity-100" : "opacity-0"
                }`}
              ></div>

              <div
                className={`${
                  toneMarks === "both" ? "opacity-100" : "opacity-0"
                }`}
              ></div>

              <div
                className={`${
                  toneMarks === "both" ? "opacity-100" : "opacity-0"
                } text-base mt-2 text-gray-300 dark:text-gray-500`}
              >
                <i className="fa-regular fa-up-long"></i>
              </div>
              <div></div>
              <div></div>
              <div
                className={`${
                  toneMarks === "both" ? "opacity-100" : "opacity-0"
                } pinyin text-3xl t3 text-gray-300 dark:text-gray-500`}
              >
                lǎo
              </div>
              <div></div>
              <div></div>

              <div
                className={`${
                  toneMarks === "both" ? "opacity-100" : "opacity-0"
                } pinyin text-3xl t4 text-gray-300 dark:text-gray-500`}
              >
                bù
              </div>
              <div
                className={`${
                  toneMarks === "both" ? "opacity-100" : "opacity-0"
                } pinyin text-3xl t4`}
              ></div>
              <div
                className={`${
                  toneMarks === "both" ? "opacity-100" : "opacity-0"
                } pinyin text-3xl t4`}
              ></div>
            </>
          )}
        </div>
      </div>

      <div className="setting-title mt-6">Tone Marks</div>
      <div className="flex mt-2">
        <div className="grid grid-cols-4 gap-0 rounded-xl xborder-2 border-gray-200 text-gray-400 font-semibold">
          <div
            className={`multi-toggle ${toneMarks === "none" ? "selected" : ""}`}
            onClick={() => handleSetToneMarks("none")}
          >
            None
          </div>
          <div
            className={`multi-toggle ${
              toneMarks === "original" ? "selected" : ""
            }`}
            onClick={() => handleSetToneMarks("original")}
          >
            Original
          </div>
          <div
            className={`multi-toggle ${
              toneMarks === "spoken" ? "selected" : ""
            }`}
            onClick={() => handleSetToneMarks("spoken")}
          >
            Spoken
          </div>
          <div
            className={`multi-toggle ${toneMarks === "both" ? "selected" : ""}`}
            onClick={() => handleSetToneMarks("both")}
          >
            Both
          </div>
        </div>
      </div>
      <p>
        Most learning resources put the original tone marks (unaltered by
        tone-change rules) above Pinyin. Some modern resources use the spoken
        (or "effective") tone marks.
      </p>
      <div className="flex mt-10">
        <div className="grid grid-cols-5 gap-x-3 gap-y-1">
          <div className="t1 text-4xl simplified characters">妈</div>
          <div className="t2 text-4xl simplified characters">麻</div>
          <div className="t3 text-4xl simplified characters">马</div>
          <div className="t4 text-4xl simplified characters">骂</div>
          <div className="t5 text-4xl simplified characters">吗</div>
          <div className="t1 text-2xl pinyin">mā</div>
          <div className="t2 text-2xl pinyin">má</div>
          <div className="t3 text-2xl pinyin">mǎ</div>
          <div className="t4 text-2xl pinyin">mà</div>
          <div className="t5 text-2xl pinyin">ma</div>
        </div>
      </div>
      <div
        className="flex items-center mt-8"
        onClick={() => toggleColoredCharacters()}
      >
        <div className={`two-way-toggle ${coloredCharacters ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">Color Characters by Tone</div>
      </div>
      <div
        className="flex items-center mt-6"
        onClick={() => toggleColoredPinyin()}
      >
        <div className={`two-way-toggle ${coloredPinyin ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">Color Pinyin by Tone</div>
      </div>
      <p className="no-border">
        Pleco and other apps have popularized the coloring of elements according
        to their tone. Given the widespread adoption, we have elected to use the
        same coloring scheme. If you can think of a great reason why other color
        schemes should be available, let us know!
      </p>
    </>
  );
}
