import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { API_ENDPOINT } from "./constants";

export default function NewStudySessionLimits({
  limit,
  setLimit,
  minutes,
  setMinutes,
  words,
  setWords,
  token,
}) {
  function saveStudyOptions(option, choice) {
    const data = {
      option: option,
      choice: choice,
    };

    fetch(`${API_ENDPOINT}/set_study_options`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    });
  }

  function handleChangeLimitValue(value) {
    if (limit === "minutes") {
      setMinutes(value);
      saveStudyOptions("desired_study_duration", value);
    } else {
      setWords(value);
      saveStudyOptions("preferred_study_words", value);
    }
  }

  function handleChangeLimitType(type) {
    setLimit(type);
    saveStudyOptions("preferred_study_limit", type);
  }

  return (
    <div className="flex flex-col md:flex-row text-sm font-semibold items-center justify-center md:mt-16 pb-6 dark:text-gray-200">
      <div className="flex border-b-2 pb-3 mb-2 md:mb-0 md:border-b-0 md:border-r-2 md:pr-3 md:py-2 md:mr-2 border-gray-200 dark:border-gray-600 md:ml-[10px]">
        <div
          className={`flex items-center justify-center h-9 md:h-7 px-4 md:px-3 mr-1 rounded-xl cursor-pointer ${
            limit === "minutes"
              ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
              : "hover:bg-gray-100 dark:hover:bg-gray-700"
          }`}
          onClick={() => handleChangeLimitType("minutes")}
        >
          Minutes
        </div>
        <div
          className={`flex items-center justify-center h-9 md:h-7 px-4 md:px-3 rounded-xl cursor-pointer ${
            limit === "words"
              ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
              : "hover:bg-gray-100 dark:hover:bg-gray-700"
          }`}
          onClick={() => handleChangeLimitType("words")}
        >
          Words
        </div>
      </div>
      <div className="flex justify-center">
        {limit === "minutes" && (
          <div className="grid grid-cols-5 gap-1 p-1">
            <div
              className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
                minutes === 2
                  ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                  : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              }`}
              onClick={() => handleChangeLimitValue(2)}
            >
              2
            </div>
            <div
              className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
                minutes === 3
                  ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                  : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              }`}
              onClick={() => handleChangeLimitValue(3)}
            >
              3
            </div>
            <div
              className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
                minutes === 6
                  ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                  : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              }`}
              onClick={() => handleChangeLimitValue(6)}
            >
              6
            </div>
            <div
              className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
                minutes === 10
                  ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                  : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              }`}
              onClick={() => handleChangeLimitValue(10)}
            >
              10
            </div>
            <div
              className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
                minutes === 20
                  ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                  : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              }`}
              onClick={() => handleChangeLimitValue(20)}
            >
              20
            </div>
          </div>
        )}
      </div>
      {limit === "words" && (
        <div className="grid grid-cols-5 gap-1 p-1">
          <div
            className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
              words === 10
                ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
            }`}
            onClick={() => handleChangeLimitValue(10)}
          >
            10
          </div>
          <div
            className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
              words === 20
                ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
            }`}
            onClick={() => handleChangeLimitValue(20)}
          >
            20
          </div>
          <div
            className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
              words === 30
                ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
            }`}
            onClick={() => handleChangeLimitValue(30)}
          >
            30
          </div>
          <div
            className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
              words === 60
                ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
            }`}
            onClick={() => handleChangeLimitValue(60)}
          >
            60
          </div>
          <div
            className={`flex justify-center items-center h-9 w-9 md:h-7 md:w-7 rounded-xl ${
              words === 100
                ? "bg-gray-200 dark:bg-gray-600 dark:text-white"
                : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
            }`}
            onClick={() => handleChangeLimitValue(100)}
          >
            100
          </div>
        </div>
      )}
    </div>
  );
}
