import React, { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";

export default function ReviewsScreen({
  cards,
  numWeakMemories,
  numMemoriesRefreshed,
  numEarlyReviewsCompleted,
  allReviewsCleared,
  setAllReviewsCleared,
  seenReviewsScreen,
  setSeenReviewsScreen,
  playAudioFromTable,
  characterSet,
}) {
  const reviewsBarRef = useRef();
  const reviewsRemainingNumberRef = useRef();
  const reviewsCompletedNumber = { val: 0 };
  const reviewsCompletedNumberRef = useRef();
  const reviewsRemainingNumber = { val: 18 };
  const reviewsBarProgressRef = useRef();
  const reviewsTextRef = useRef();
  const reviewsStatsRef = useRef();
  const q = gsap.utils.selector(reviewsStatsRef);
  const forgottenWordsTableRef = useRef();
  const forgottenWordsTable = gsap.utils.selector(forgottenWordsTableRef);
  const forgottenWordsRef = useRef();

  const wordsForgotten = cards.filter((card) => {
    return (
      (card.type === "review" || card.type === "early_review") &&
      card.first_result === "fail"
    );
  });

  const numWordsRemembered = cards.filter((card) => {
    return (
      (card.type === "review" || card.type == "early_review") &&
      (card.first_result === "pass" ||
        card.first_result === "boost" ||
        card.first_result === "memorize")
    );
  }).length;

  const celebrateAllReviewsClearedIfNecessary = () => {
    if (numMemoriesRefreshed === numWeakMemories && numWeakMemories > 0) {
      setAllReviewsCleared(true);
    }
  };

  const roundNumber = () => {
    var final = gsap.utils.snap(1, reviewsCompletedNumber.val);
    reviewsCompletedNumberRef.current.innerHTML = final;
  };

  const roundReviewsRemaining = () => {
    var final = gsap.utils.snap(1, reviewsRemainingNumber.val);
    reviewsRemainingNumberRef.current.innerHTML = final;
  };

  useLayoutEffect(() => {
    let timeForProgressMeter = 0;
    if (numMemoriesRefreshed > 0) {
      // variable, but between 1 and 3 seconds
      timeForProgressMeter = Math.min(
        Math.max(numMemoriesRefreshed * 0.25, 1),
        3
      );
    }

    let tl = gsap
      .timeline({
        delay: 0.6,
        onComplete: setSeenReviewsScreen,
        onCompleteParams: [true],
      })
      .from(reviewsBarRef.current, {
        x: 100,
        opacity: 0,
        duration: 0.5,
        ease: "back",
      })

      .to(
        reviewsBarProgressRef.current,
        {
          width: `${(numMemoriesRefreshed / numWeakMemories) * 100}%`,
          duration: timeForProgressMeter,

          ease: "power4.out",
          onComplete: celebrateAllReviewsClearedIfNecessary,
        },
        ">-.05"
      )

      .fromTo(
        reviewsCompletedNumber,
        { val: 0 },
        {
          val: numMemoriesRefreshed,
          duration: timeForProgressMeter,
          onUpdate: roundNumber,
          ease: "power2.out",
        },
        "<"
      )
      .fromTo(
        reviewsRemainingNumber,
        { val: numWeakMemories },
        {
          val: numWeakMemories - numMemoriesRefreshed,
          duration: timeForProgressMeter,
          onUpdate: roundReviewsRemaining,
          ease: "power2.out",
        },
        "<"
      )
      .from(
        reviewsCompletedNumberRef.current,
        { opacity: 0, duration: 0.5 },
        "<"
      )
      .from(reviewsTextRef.current, { opacity: 0, y: -10, duration: 0.5 })

      .from(q(".stat-box"), {
        scale: 0.5,
        opacity: 0,
        stagger: 0.1,
        duration: 0.6,
        ease: "back",
      })

      .from(
        forgottenWordsRef.current,
        {
          opacity: 0,

          scale: 0.8,
          duration: 0.7,
          ease: "back",
        },
        ">+0.025"
      )
      .from(
        forgottenWordsTable("tr"),
        {
          x: 100,
          opacity: 0,

          stagger: 0.03,
          duration: 0.4,
        },
        ">-0.3"
      );

    if (seenReviewsScreen) {
      tl.progress(1);
    }

    return () => {
      setSeenReviewsScreen(true);
      tl.kill();
    };
  }, []);

  return (
    <div className={`allReviewsCleared ? "all-reviews-cleared" : ""`}>
      <div
        className="flex flex-col justify-end pt-12 drop-in px-12 xxs:px-6 sm:px-0"
        ref={reviewsBarRef}
      >
        <div className="flex w-full rounded-full bg-gray-100 dark:bg-gray-700 h-6">
          <div
            className={`${
              allReviewsCleared ? "bg-green" : "bg-gray-300 dark:bg-gray-500"
            } rounded-full w-0 h-full border-r-2 border-white dark:border-gray-800 relative`}
            ref={reviewsBarProgressRef}
          >
            <div
              className="absolute bottom-[24px] right-[8px] text-4xl xxs:text-5xl sm:text-5xl md:text-6xl lg:text-7xl font-black text-gray-400 dark:text-gray-300 green-text-if-cleared"
              ref={reviewsCompletedNumberRef}
            >
              0
            </div>

            <div
              className={`${
                allReviewsCleared ? "hidden" : ""
              } absolute right-[-2px] bottom-[30px] h-5 w-[2px] bg-gray-200 dark:bg-gray-500`}
            ></div>
          </div>
          <div
            className={`${
              allReviewsCleared ? "hidden" : ""
            } relative bottom-[29px] left-[6px] text-xl font-semibold text-gray-300 dark:text-gray-500`}
            ref={reviewsRemainingNumberRef}
          >
            0
          </div>
        </div>
        <div
          className={`${
            allReviewsCleared ? "text-green" : ""
          } mt-2 text-lg dark:text-gray-300`}
          ref={reviewsTextRef}
        >
          {numMemoriesRefreshed === numWeakMemories && (
            <span className="font-bold">
              You strengthened all your weak memories!
            </span>
          )}
          {numMemoriesRefreshed != numWeakMemories && (
            <>
              <span className="font-bold">
                You strengthened {numMemoriesRefreshed}{" "}
                {numMemoriesRefreshed.length === 1 ? "memory" : "memories"}.
              </span>
              <span className="ml-2">
                {" "}
                {numWeakMemories - numMemoriesRefreshed}{" "}
                {numWeakMemories - numMemoriesRefreshed === 1
                  ? "memory is still"
                  : "memories are still"}{" "}
                weak and in need of review.
              </span>
            </>
          )}
        </div>
      </div>
      <div
        className={`review-screen-stats-container grid ${
          numEarlyReviewsCompleted > 0
            ? "grid-cols-1 md:grid-cols-4"
            : "grid-cols-3"
        } gap-3 md:gap-6 mt-6 lg:mt-10`}
        ref={reviewsStatsRef}
      >
        {numEarlyReviewsCompleted > 0 && (
          <div className="stat-box">
            <div className="stat">+{numWordsRemembered}</div>
            <div className="stat-label">early reviews</div>
            <div className="absolute flex justify-center items-center bottom-0 left-0 w-full h-6 mb-1 text-sm">
              (for a flat review schedule)
            </div>
          </div>
        )}

        <div className="stat-box">
          <div className="stat">{numWordsRemembered}</div>
          <div className="stat-label">
            {numWordsRemembered === 1 ? "word remembered" : "words remembered"}
          </div>
        </div>

        <div className="stat-box">
          <div className="stat">{wordsForgotten.length}</div>
          <div className="stat-label">
            {wordsForgotten.length === 1 ? "word" : "words"}
            <br className="sm:hidden" /> forgotten
          </div>
          {wordsForgotten.length > 0 && (
            <div className="absolute hidden sm:flex justify-center items-center bottom-0 left-0 w-full h-6 mb-1 text-sm">
              and relearned
            </div>
          )}
        </div>

        <div className="stat-box">
          <div className="stat">
            {numMemoriesRefreshed === 0
              ? 0
              : Math.round(
                  (numWordsRemembered /
                    (numWordsRemembered + wordsForgotten.length)) *
                    100
                )}

            <span className="text-xl">%</span>
          </div>
          <div className="stat-label">
            <span className="sm:hidden">
              retention
              <br />
              rate
            </span>
            <span className="hidden sm:inline">retention rate</span>
          </div>
        </div>
      </div>

      <div className="pb-32 sm:pb-24" ref={forgottenWordsRef}>
        {wordsForgotten.length > 0 && (
          <div className="font-medium text-xl font-bold mt-10 mb-3">
            Words you forgot:
          </div>
        )}
        <table className="victory-table-striped text-lg w-full mb-10 dark:text-gray-300">
          <tbody ref={forgottenWordsTableRef}>
            {wordsForgotten.map((card, i) => {
              return (
                <>
                  <tr key={`fw-${i}`}>
                    <td className="px-2 py-1 w-[64px]">
                      {" "}
                      <div
                        className="group flex items-center justify-center cursor-pointer w-12 h-12 text-sm"
                        onClick={() => playAudioFromTable(card.word_id)}
                      >
                        <audio
                          id={card.word_id}
                          src={card.word_audio_url}
                        ></audio>
                        <div className="victory-screen-play-button-container">
                          <i className="fas fa-play ml-2px"></i>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-1 text-2xl simplified ">
                      {characterSet === "simplified" && card.simplified}
                      {characterSet != "simplified" && card.traditional}
                    </td>
                    <td className="hidden sm:table-cell px-4 py-1 text-xl font-ubuntu-condensed">
                      {card.opinyin}
                    </td>
                    <td className="hidden sm:table-cell px-2 py-1 text-xl font-ubuntu-condensed">
                      {card.common_definitions[0]}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
