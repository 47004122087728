import React, { useState } from "react";
import DashboardWidgetExpansionToggle from "./DashboardWidgetExpansionToggle";
import DashboardWidgetDetailsToggle from "./DashboardWidgetDetailsToggle";
import DashboardWidgetRangeSelector from "./DashboardWidgetRangeSelector";
import HistoricBrainRecordBar from "./HistoricBrainRecordBar";
import { API_ENDPOINT } from "./constants";

export default function VocabularyGrowthWidget({
  token,
  historicBrainRecords,
  maxWords,
  numWordsAssumedKnown,
  numWordsBlocked,
  numLeeches,
  numWordsSuspended,
  numWordsStrong,
  numWordsStrongAllTypes,
  numWordsMemorized,
  numWordsMastered,
  numReviewsDueNow,
  numWordsKnown,
  userRange,
  userShowDetails,
}) {
  const [range, setRange] = useState(userRange);
  const [expanded, setExpanded] = useState(false);
  const [showDetails, setShowDetails] = useState(userShowDetails);

  function classOfDayItem(i) {
    if (i < 29) {
      return "30";
    } else if (i < 59) {
      return "60";
    } else if (i < 89) {
      return "90";
    } else if (i < 179) {
      return "180";
    } else {
      return "365";
    }
  }

  function saveSettings(option, choice) {
    const data = {
      option: option,
      choice: choice,
    };

    fetch(`${API_ENDPOINT}/set_study_options`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    })
      // .then((response) => response.json())
      .then((response) => {
        // console.log(`response from server: ${JSON.stringify(response)}`);
      });
  }

  function handleSetShowDetails(newShowDetails) {
    setShowDetails(newShowDetails);
    saveSettings("vocab_growth_details", newShowDetails);
  }

  function setAndSaveRange(newRange) {
    setRange(newRange);
    saveSettings("vocab_growth_range", newRange);
  }

  return (
    <>
      <div className="flex relative pb-10">
        <div className="text-xl font-bold text-gray-600 dark:text-gray-300 tracking-wide">
          Vocabulary Growth
        </div>

        <div className="flex h-8 text-xs relative ml-auto gap-4">
          <DashboardWidgetExpansionToggle
            expanded={expanded}
            setExpanded={setExpanded}
          />

          <DashboardWidgetDetailsToggle
            showDetails={showDetails}
            handleSetShowDetails={handleSetShowDetails}
          />

          <DashboardWidgetRangeSelector
            range={range}
            setAndSaveRange={setAndSaveRange}
          />
        </div>
      </div>
      <div className="flex flex-col w-full pt-3">
        <div
          className={`direction-rtl grid dynamic-range-${range} justify-end transition-all ease-in ${
            expanded ? "pht-expanded" : "h-32"
          } ${
            showDetails ? "vocab-details" : ""
          } w-full text-gray-400 dark:text-gray-500 border-b-2 border-gray-200 dark:border-gray-600`}
        >
          <div
            key={`hbr-now`}
            className={
              "relative group cursor-pointer flex flex-col justify-end h-full direction-ltr"
            }
          >
            <HistoricBrainRecordBar
              date={"Now"}
              numWordsBlocked={numWordsBlocked}
              numLeeches={numLeeches}
              numWordsWeak={numReviewsDueNow}
              maxWords={maxWords}
              numWordsStrong={numWordsStrong}
              numWordsStrongAllTypes={numWordsStrongAllTypes}
              numWordsMemorized={numWordsMemorized}
              numWordsAssumed={numWordsAssumedKnown}
              numWordsMastered={numWordsMastered}
              numWordsKnown={numWordsKnown}
              numWordsSuspended={numWordsSuspended}
            />
          </div>

          {historicBrainRecords.map((hbr, i) => {
            return (
              <div
                key={`hbr-${i}`}
                className={`relative group cursor-pointer flex flex-col day-group-${classOfDayItem(
                  i
                )} justify-end h-full direction-ltr`}
              >
                <HistoricBrainRecordBar
                  date={hbr.date}
                  numWordsBlocked={hbr.num_blocked}
                  numLeeches={hbr.num_leeches}
                  numWordsWeak={hbr.num_words_weak}
                  numWordsStrong={hbr.num_words_strong}
                  numWordsMemorized={hbr.num_memorized}
                  numWordsMastered={hbr.num_mastered}
                  numWordsStrongAllTypes={hbr.num_strong_all_types}
                  numWordsAssumed={hbr.num_words_assumed}
                  maxWords={maxWords}
                  numWordsKnown={hbr.num_words_known}
                  numWordsSuspended={hbr.num_leeches + hbr.num_blocked}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-between w-full text-sm text-gray-500 pt-1">
        <div>{range} days ago</div>
        <div>Today</div>
      </div>
    </>
  );
}
