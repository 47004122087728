import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Face from "./Face";
import StudySessionTimer from "./StudySessionTimer";
import QuestionResponse from "./QuestionResponse";
import ShortcutLegend from "./ShortcutLegend";
import ShortcutEncouragement from "./ShortcutEncouragement";
import PerformancePixels from "./PerformancePixels";
import VictoryScreen from "./VictoryScreen";
import useMousetrap from "react-hook-mousetrap";
import AdvancedResponseTooltips from "./AdvancedResponseTooltips";
import { gsap } from "gsap";
import unmute from "../utilities/Unmute";
import { API_ENDPOINT } from "./constants";

import {
  loadSoundFile,
  playSound,
  stopSound,
  base64ToBuffer,
  clearAudioFromLocalStorage,
} from "../utilities/AudioManagement";

export default function StudySession({
  studySessionId,
  limitType,
  limitValue,
  initCards,
  wordIds,
  reviewSchema,
  newSchema,
  highlightHard,
  simplifiedFont,
  traditionalFont,
  characterSet,
  userAutoCopy,
  userShortcutsReminder,
  userShortcutHints,
  userAutoPlay,
  userSentences,
  dark,
  token,
  pinyinStyle,
  numWeakMemories,
  vocabularyLists,
  coloredCharacters,
  coloredPinyin,
}) {
  const studyInterfaceRef = useRef();
  const [timeStarted, setTimeStarted] = useState(new Date().getTime());
  const [totalTimePaused, setTotalTimePaused] = useState(0);
  const [timePaused, setTimePaused] = useState(); // can't be null, and will be overwritten anyway
  const [secondsLeft, setSecondsLeft] = useState(5000);
  const [elapsedPercentage, setElapsedPercentage] = useState(0);
  const [characterSize, setCharacterSize] = useState(
    parseInt(localStorage.getItem("characterFontSize")) || 0
  );
  const [pinyinSize, setPinyinSize] = useState(
    parseInt(localStorage.getItem("pinyinFontSize")) || 0
  );
  const [englishSize, setEnglishSize] = useState(
    parseInt(localStorage.getItem("englishFontSize")) || 0
  );
  const [sentenceEnglishSize, setSentenceEnglishSize] = useState(
    parseInt(localStorage.getItem("sentenceEnglishFontSize")) || 0
  );
  const [sentenceCharacterSize, setSentenceCharacterSize] = useState(
    parseInt(localStorage.getItem("sentenceCharacterFontSize")) || 0
  );

  const [editingNote, setEditingNote] = useState(false);
  const undoTimeline = useRef();

  const studyFace = {
    character: true,
    pinyin: true,
    word_audio: true,
    sentence_audio: true,
    note: true,
    english: true,
    sentence: true,
    sentence_cn: true,
    sentence_en: true,
    q_pinyin: false,
    q_pinyin_confirm: false,
    q_english: false,
    q_english_confirm: false,
    you_forgot: true, // needs to be different if it's just a new card (blue)
    r_no_think_so: false,
    r_no_yes: true,
    q_do_you_know: false,
  };

  const [studySessionDoneLoading, setStudySessionDoneLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [cards, setCards] = useState(initCards);
  const [cardsNeedingAdditionalReview, setCardsNeedingAdditionalReview] =
    useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [currentCard, setCurrentCard] = useState(cards[currentCardIndex]);
  const [previousCardIndex, setPreviousCardIndex] = useState(0);
  const [previousCard, setPreviousCard] = useState(cards[currentCardIndex]);
  const [currentFaceIndex, setCurrentFaceIndex] = useState(0);
  const [currentFace, setCurrentFace] = useState(
    currentCard.type === "new" ? studyFace : reviewSchema[0]
  );
  const [isStudying, setIsStudying] = useState(currentCard.type === "new");
  const [isStudyingNew, setIsStudyingNew] = useState(
    currentCard.type === "new"
  );
  const [layout, setLayout] = useState("auto");
  const [darkMode, setDarkMode] = useState(dark);
  const [autoCopy, setAutoCopy] = useState(userAutoCopy);
  const [autoPlay, setAutoPlay] = useState(userAutoPlay);
  const [showAdvancedResponses, setShowAdvancedResponses] = useState(true);
  const [showReplayButtons, setShowReplayButtons] = useState(
    localStorage.getItem("showReplayButtons") === null
      ? true
      : localStorage.getItem("showReplayButtons") === "true"
  );
  const [numCardsToLoad, setNumCardsToLoad] = useState(1);
  const [studyComplete, setStudyComplete] = useState(false);
  const [uncoverElements, setUncoverElements] = useState(false);
  const [revealTimer, setRevealTimer] = useState(null);
  const [timesUp, setTimesUp] = useState(false);
  const [soundLocked, setSoundLocked] = useState(currentFace.word_audio);
  const [shortcutsReminder, setShortcutsReminder] = useState(
    userShortcutsReminder
  );
  const [shortcutHints, setShortcutHints] = useState(userShortcutHints);
  const [mouseClicks, setMouseClicks] = useState(0);
  const [remindedAboutShortcuts, setRemindedAboutShortcuts] = useState(false);
  const [showBlockTooltip, setShowBlockTooltip] = useState(false);
  const [showBoostTooltip, setShowBoostTooltip] = useState(false);
  const [showMemorizeTooltip, setShowMemorizeTooltip] = useState(false);
  const [showMoreResponses, setShowMoreResponses] = useState(false);
  const [blockTooltipRequired, setBlockTooltipRequired] = useState(true);
  const [boostTooltipRequired, setBoostTooltipRequired] = useState(true);
  const [memorizeTooltipRequired, setMemorizeTooltipRequired] = useState(true);
  const [charactersAdjusting, setCharactersAdjusting] = useState(false);
  const [pinyinAdjusting, setPinyinAdjusting] = useState(false);
  const [englishAdjusting, setEnglishAdjusting] = useState(false);
  const [sentenceEnglishAdjusting, setSentenceEnglishAdjusting] =
    useState(false);
  const [sentenceCharacterAdjusting, setSentenceCharacterAdjusting] =
    useState(false);

  const [numCardsToFinish, setNumCardsToFinish] = useState(1);

  const [userWordNote, setUserWordNote] = useState(currentCard.note);

  const undoOverlayRef = useRef();

  // "zoom-in" effect when SS starts
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("scale-75");
    setTimeout(() => {
      setStudySessionDoneLoading(true);
    }, 1000); // should be set to just a few MS greater than it takes to finish the transition above
  }, []);

  // clear audio from local storage and load audio for initial cards
  useEffect(() => {
    clearAudioFromLocalStorage();

    window.soundContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    unmute(window.soundContext);

    window.source = null;

    cards.map(async (card) => {
      await loadSoundFile(card.word_audio_url, `word-${card.word_id}`);
      await loadSoundFile(card.sentence_audio_url, `sentence-${card.word_id}`);
    });
  }, []);

  // load audio as more cards arrive
  useEffect(() => {
    cards.slice(-3).map(async (card) => {
      await loadSoundFile(card.word_audio_url, `word-${card.word_id}`);
      await loadSoundFile(card.sentence_audio_url, `sentence-${card.word_id}`);
    });
  }, [cards.length]);

  // play audio if necessary
  useLayoutEffect(() => {
    if (autoPlay) {
      if (
        currentFace.word_audio ||
        (currentCard.type === "new" && !currentCard.seen)
      ) {
        playAudio("word", currentCard.word_id, currentCard.simplified);
      }
    }
  }, [currentFace]);

  useEffect(() => {
    setUserWordNote(currentCard.note);
  }, [currentCard]);

  useEffect(() => {
    if (currentCard.type === "new" && currentCard.seen != true) {
      currentCard.remaining_delay = 1;
      addCurrentCardToAdditionalReviewQueue();
    }
    setIsStudyingNew(currentCard.type === "new" && !currentCard.seen); // todo may need to alter 2nd part
    currentCard.seen = true;

    setNumCardsToFinish(
      cards.filter(
        (card) =>
          card.seen === true &&
          (card.result === "fail" || card.result == undefined) // any other result means we are 100% done. failed cards must be seen again until result changes to 'pass'
      ).length
    );
  }, [currentCard]);

  const updateTimer = () => {
    let timeToCountUntil;

    if (isPaused) {
      timeToCountUntil = timePaused || new Date().getTime(); // if never set, default to current time
    } else {
      timeToCountUntil = new Date().getTime();
    }

    let millisecondsElapsed =
      timeToCountUntil - timeStarted - totalTimePaused * 1000;

    let left = (limitValue * 60 * 1000 - millisecondsElapsed) / 1000; // # seconds left

    if (left > 0) {
      setSecondsLeft(left);
      if (limitType === "minutes") {
        setElapsedPercentage((1 - secondsLeft / (limitValue * 60)) * 100);
      } else {
        setElapsedPercentage((currentCardIndex / limitValue) * 100);
      }
    } else {
      setTimesUp(true);
      setSecondsLeft(0);
      setElapsedPercentage(100);
      // TODO: rakib: should we kill the interval timer here too?
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      updateTimer();
    }, 250);

    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (editingNote || settingsOpen) {
      if (!isPaused) {
        pause();
      }
    } else {
      if (isPaused) {
        unPause();
      }
    }
  }, [editingNote, settingsOpen]);

  const unLockAudio = () => {
    setSoundLocked(false);
    setTimeStarted(new Date().getTime());
    playAudio("word", currentCard.word_id, currentCard.simplified);
  };

  const endSessionEarly = () => {
    sendQueuedResultsToServer();
    setStudyComplete(true);
  };

  function hideAllAdvancedResponseToolTips() {
    setShowBlockTooltip(false);
    setShowBoostTooltip(false);
    setShowMemorizeTooltip(false);
  }

  function undo() {
    undoTimeline.current = gsap.timeline().fromTo(
      undoOverlayRef.current,
      {
        xPercent: 200,
        duration: 0.3,
      },
      {
        xPercent: -200,
        duration: 0.3,
      }
    );

    if (currentFaceIndex > 0) {
      setCurrentFaceIndex(0);
      setCurrentFace(
        currentCard.type === "new" ? newSchema[0] : reviewSchema[0]
      );
    } else {
      if (previousCard.type === "new" && previousCard.previous_seen === false) {
        removeCardFromDelayedList(previousCard.word_id);
        previousCard.remaining_delay = 0;
      }
      previousCard.num_times_marked_correct =
        previousCard.previous_num_times_marked_correct;
      previousCard.seen = previousCard.previous_seen;
      setCurrentCard(previousCard);
      setCurrentCardIndex(previousCardIndex);
    }
  }

  function saveStudyOptions(option, choice) {
    const data = {
      option: option,
      choice: choice,
    };

    fetch(`${API_ENDPOINT}/set_study_options`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    });
  }

  function saveUserWordNote() {
    const data = {
      word_id: currentCard.word_id,
      user_word_note: userWordNote,
    };

    fetch(`${API_ENDPOINT}/save_user_word_note`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    });

    currentCard.note = userWordNote;
  }

  function toggleShortcutsReminder() {
    saveStudyOptions("shortcuts_reminder", !shortcutsReminder);
    setShortcutsReminder(!shortcutsReminder);
  }

  function toggleDarkMode() {
    saveStudyOptions("dark_mode", !darkMode);
    setDarkMode(!darkMode);
    document.getElementsByTagName("html")[0].classList.toggle("bg-black");
    document.getElementsByTagName("body")[0].classList.toggle("dark");
  }

  function toggleShortcutHints() {
    saveStudyOptions("hotkey_hints", !shortcutHints);
    setShortcutHints(!shortcutHints);
  }

  function toggleAutoCopy() {
    saveStudyOptions("auto_copy_words", !autoCopy);
    setAutoCopy(!autoCopy);
  }

  function toggleAutoPlay() {
    saveStudyOptions("audio", !autoPlay);
    setAutoPlay(!autoPlay);
  }

  function sendQueuedResultsToServer() {
    let timeToCountUntil;

    if (isPaused) {
      timeToCountUntil = timePaused || new Date().getTime(); // if never set, default to current time
    } else {
      timeToCountUntil = new Date().getTime();
    }

    let millisecondsElapsed =
      timeToCountUntil - timeStarted - totalTimePaused * 1000;

    cards
      .filter((card) => card.send_status === "waiting")
      .map((card) => {
        const data = {
          study_session_id: studySessionId,
          elapsed_study_time: millisecondsElapsed / 1000,
          attempt: {
            result: card.result,
            word_id: card.word_id,
          },
        };

        fetch(`${API_ENDPOINT}/attempts`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
          body: JSON.stringify(data),
        });

        card.send_status = "sent";
        if (
          card.result === "pass" ||
          card.result === "boost" ||
          card.result === "memorize"
        ) {
          localStorage.removeItem(`audio-word-${card.word_id}`);
          localStorage.removeItem(`audio-sentence-${card.word_id}`);
        }
      });
  }

  useMousetrap("up", () => {
    playAudio("word", currentCard.word_id, currentCard.simplified);
  });

  useMousetrap("down", () => {
    playAudio("sentence", currentCard.word_id, currentCard.simplified);
  });

  useMousetrap("x", () => {
    if (!studyComplete && !isPaused) {
      block();
    }
  });

  useMousetrap("b", () => {
    if (!studyComplete && !isPaused) {
      boost();
    }
  });

  useMousetrap("m", () => {
    if (!studyComplete && !isPaused) {
      memorize();
    }
  });

  useMousetrap("right", () => {
    if (!studyComplete && !isPaused) {
      positiveResponse();
    }
  });

  useMousetrap("left", () => {
    if (!studyComplete && !isPaused) {
      negativeResponse();
    }
  });

  useMousetrap("space", () => {
    if (!studyComplete) {
      toggleOpenSettings();
    }
  });

  useMousetrap("d", () => {
    toggleDarkMode();
  });

  useMousetrap("a", () => {
    if (!editingNote) {
      setEditingNote(true);
    }
  });

  // timer for revealing elements with size adjustments in progress
  function startTimer() {
    setRevealTimer(
      setTimeout(function () {
        setUncoverElements(false);
        setCharactersAdjusting(false);
        setPinyinAdjusting(false);
        setEnglishAdjusting(false);
        setSentenceEnglishAdjusting(false);
        setSentenceCharacterAdjusting(false);
      }, 2000)
    );
  }

  const pause = () => {
    setIsPaused(true);
    setTimePaused(new Date().getTime());
  };

  const unPause = () => {
    setIsPaused(false);
    let timeSpentPaused = (new Date().getTime() - timePaused) / 1000;
    setTotalTimePaused(totalTimePaused + timeSpentPaused);
  };

  const toggleOpenSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  function revealElements() {
    clearTimeout(revealTimer);
    startTimer();
    setUncoverElements(true);
  }

  function resetFontSizes() {
    setCharacterSize(0);
    setPinyinSize(0);
    setEnglishSize(0);
    setSentenceEnglishSize(0);
    setSentenceCharacterSize(0);

    localStorage.setItem("characterFontSize", 0);
    localStorage.setItem("pinyinFontSize", 0);
    localStorage.setItem("englishFontSize", 0);
    localStorage.setItem("sentenceCharacterFontSize", 0);
    localStorage.setItem("sentenceEnglishFontSize", 0);
  }

  function increaseCharacterSize() {
    revealElements();
    setCharactersAdjusting(true);
    let new_font_size = parseInt(characterSize) + 1;
    setCharacterSize(new_font_size);
    localStorage.setItem("characterFontSize", new_font_size);
  }

  function decreaseCharacterSize() {
    revealElements();
    setCharactersAdjusting(true);
    let new_font_size = parseInt(characterSize) - 1;
    setCharacterSize(new_font_size);
    localStorage.setItem("characterFontSize", new_font_size);
  }

  function increasePinyinSize() {
    revealElements();
    setPinyinAdjusting(true);
    let new_font_size = parseInt(pinyinSize) + 1;
    setPinyinSize(new_font_size);
    localStorage.setItem("pinyinFontSize", new_font_size);
  }

  function decreasePinyinSize() {
    revealElements();
    setPinyinAdjusting(true);
    let new_font_size = parseInt(pinyinSize) - 1;
    setPinyinSize(new_font_size);
    localStorage.setItem("pinyinFontSize", new_font_size);
  }

  function increaseEnglishSize() {
    revealElements();
    setEnglishAdjusting(true);
    let new_font_size = parseInt(englishSize) + 1;
    setEnglishSize(new_font_size);
    localStorage.setItem("englishFontSize", new_font_size);
  }

  function decreaseEnglishSize() {
    revealElements();
    setEnglishAdjusting(true);
    let new_font_size = parseInt(englishSize) - 1;
    setEnglishSize(new_font_size);
    localStorage.setItem("englishFontSize", new_font_size);
  }

  function increaseSentenceEnglishSize() {
    revealElements();
    setSentenceEnglishAdjusting(true);
    let new_font_size = parseInt(sentenceEnglishSize) + 1;
    setSentenceEnglishSize(new_font_size);
    localStorage.setItem("sentenceEnglishFontSize", new_font_size);
  }

  function decreaseSentenceEnglishSize() {
    revealElements();
    setSentenceEnglishAdjusting(true);
    let new_font_size = parseInt(sentenceEnglishSize) - 1;
    setSentenceEnglishSize(new_font_size);
    localStorage.setItem("sentenceEnglishFontSize", new_font_size);
  }

  function increaseSentenceCharacterSize() {
    revealElements();
    setSentenceCharacterAdjusting(true);
    let new_font_size = parseInt(sentenceCharacterSize) + 1;
    setSentenceCharacterSize(new_font_size);
    localStorage.setItem("sentenceCharacterFontSize", new_font_size);
  }

  function decreaseSentenceCharacterSize() {
    revealElements();
    setSentenceCharacterAdjusting(true);
    let new_font_size = parseInt(sentenceCharacterSize) - 1;
    setSentenceCharacterSize(new_font_size);
    localStorage.setItem("sentenceCharacterFontSize", new_font_size);
  }

  function toggleShowAdvancedResponses() {
    setShowAdvancedResponses(!showAdvancedResponses);
  }

  function toggleShowReplayButtons() {
    localStorage.setItem("showReplayButtons", !showReplayButtons);
    setShowReplayButtons(!showReplayButtons);
  }

  // fetch more cards when instructed to
  useEffect(() => {
    const data = {
      study_session: {
        word_ids: wordIds.slice(cards.length, numCardsToLoad),
      },
    };

    fetch(`${API_ENDPOINT}/study_sessions/load_cards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        setCards(cards.concat(response.cards));
      });
  }, [numCardsToLoad]);

  const decrementAllDelays = () => {
    cardsNeedingAdditionalReview.forEach(function (card) {
      card.remaining_delay--;
    });
  };

  const removeAllDelays = () => {
    cardsNeedingAdditionalReview.forEach(function (card) {
      card.remaining_delay = 0;
    });
  };

  const playAudio = async (audioType, wordId, _simplified) => {
    if (window.source) window.source.stop(0); // Play immediately.
    var base64String = localStorage.getItem(`audio-${audioType}-${wordId}`);
    var audioFromString = base64ToBuffer(base64String);

    if (base64String === null) {
      return true;
    }

    await window.soundContext.resume();

    window.soundContext.decodeAudioData(audioFromString, function (buffer) {
      window.source = window.soundContext.createBufferSource();
      window.source.buffer = buffer;
      window.source.connect(window.soundContext.destination);
      window.source.start(0);
    });
  };

  const updateVictoryStats = () => {
    if ((currentCard.first_result == null) & (currentCard.result != null)) {
      currentCard.first_result = currentCard.result;

      if (currentCard.type === "new") {
        vocabularyLists.forEach(function (list) {
          if (list.word_ids.includes(currentCard.word_id)) {
            list.num_words_known_at_end++;
          }
        });
      }
    }
  };

  const positiveResponse = (responseType) => {
    if (isStudying) {
      confirmDoneStudying();
    } else {
      if (!isStudying || typeof responseType != "undefined") {
        setShowMoreResponses(false);

        hideAllAdvancedResponseToolTips();

        if (
          !responseType &&
          ((currentCard.type === "new" &&
            newSchema.length !== currentFaceIndex + 1) ||
            (currentCard.type != "new" &&
              reviewSchema.length !== currentFaceIndex + 1))
        ) {
          setCurrentFace(
            currentCard.type === "new"
              ? newSchema[currentFaceIndex + 1]
              : reviewSchema[currentFaceIndex + 1]
          );
          setCurrentFaceIndex(currentFaceIndex + 1);
        } else {
          sendQueuedResultsToServer();

          decrementAllDelays();

          currentCard.previous_num_times_marked_correct =
            currentCard.num_times_marked_correct;

          currentCard.num_times_marked_correct++;

          if (responseType) {
            currentCard.result = responseType; // memorize or boost
            currentCard.num_times_marked_correct = 3;
            currentCard.send_status = "waiting";
            removeCardFromDelayedList();
          } else {
            if (currentCard.num_times_marked_correct > 2) {
              currentCard.result = "pass";
              currentCard.send_status = "waiting";
              removeCardFromDelayedList(); // TODO RENAME
            }
          }

          currentCard.previous_remaining_delay = currentCard.remaining_delay;
          // TODO: we sometimes have previous_remaining_delay that is negative... how?

          currentCard.remaining_delay = Math.max(
            currentCard.num_times_marked_correct,
            1
          );

          updateVictoryStats(responseType); // might need to put this elsewhere to take undos into account

          showNextCard();
        }
      }
    }
  };

  const removeCardFromDelayedList = (wordId) => {
    let wordIdToRemove = currentCard.word_id;

    if (wordId) {
      wordIdToRemove = wordId;
    }

    let filteredReviewList = cardsNeedingAdditionalReview.filter(function (
      card
    ) {
      return card.word_id !== wordIdToRemove;
    });
    setCardsNeedingAdditionalReview(filteredReviewList);
  };

  const negativeResponse = (responseType) => {
    // undefined or "block"
    if (!isStudying || typeof responseType != "undefined") {
      setShowMoreResponses(false);
      hideAllAdvancedResponseToolTips();
      decrementAllDelays();
      sendQueuedResultsToServer();

      if (currentCard.type === "assumed") {
        currentCard.type = "new";
        vocabularyLists.forEach(function (list) {
          if (list.word_ids.includes(currentCard.word_id)) {
            list.num_words_known_at_end--;
            list.num_words_known_at_start--;
          }
        });
      } // let's not mark assumed as false as we may need to undo, and also will want to know how many words weren't really known for victory screen stats

      currentCard.previous_num_times_marked_correct =
        currentCard.num_times_marked_correct;

      currentCard.num_times_marked_correct = 0;
      currentCard.previous_remaining_delay = currentCard.remaining_delay;
      currentCard.remaining_delay = 1;
      currentCard.send_status = "waiting";

      if (responseType) {
        currentCard.result = responseType; // block
        removeCardFromDelayedList();
        showNextCard();
      } else {
        currentCard.result = "fail"; // todo what if this is a new card?
        addCurrentCardToAdditionalReviewQueue();
        setCurrentFace(studyFace);
        setIsStudying(true);
      }
    }

    updateVictoryStats(responseType);
  };

  const addCurrentCardToAdditionalReviewQueue = () => {
    let currentCardExists = cardsNeedingAdditionalReview.find(
      (card) => card.word_id === currentCard.word_id
    );

    if (currentCardExists === undefined) {
      // todo should this be == ? try in console vs. null!

      setCardsNeedingAdditionalReview(
        cardsNeedingAdditionalReview.concat(currentCard)
      );
    }
  };

  const cardWithLowestRemainingDelay = () => {
    let lowestDelayFound = 500;
    let indexOfCardWithLowestDelay = 500;

    for (var i = 0; i <= cardsNeedingAdditionalReview.length - 1; i++) {
      if (cardsNeedingAdditionalReview[i].remaining_delay < lowestDelayFound) {
        lowestDelayFound = cardsNeedingAdditionalReview[i].remaining_delay;
        indexOfCardWithLowestDelay = i;
      }
    }

    return cardsNeedingAdditionalReview[indexOfCardWithLowestDelay];
  };

  const showNextCard = () => {
    currentCard.previous_seen = currentCard.seen;
    setCurrentFaceIndex(0);
    setPreviousCard(currentCard);
    setPreviousCardIndex(currentCardIndex);

    let delayedCard = cardsNeedingAdditionalReview.find(
      (card) => card.remaining_delay < 1
    );

    if (delayedCard) {
      setIsStudying(false);
      setCurrentCard(delayedCard);
      setCurrentFace(
        delayedCard.type === "new" ? newSchema[0] : reviewSchema[0]
      );
    } else {
      if (timesUp || currentCardIndex == wordIds.length - 1) {
        let legitNeedsMoreReview = cardsNeedingAdditionalReview.filter(
          (card) => card.result === "fail" || card.result == undefined
        );

        if (legitNeedsMoreReview.length > 0) {
          delayedCard = cardWithLowestRemainingDelay();
          setIsStudying(false); // todo: these 4 lines can be pulled into a function as dupe of above
          setCurrentCard(delayedCard);
          setCurrentFace(
            delayedCard.type === "new" ? newSchema[0] : reviewSchema[0]
          );
        } else {
          sendQueuedResultsToServer();
          setStudyComplete(true);
        }
      } else {
        setIsStudying(cards[currentCardIndex + 1].type === "new");
        setCurrentCard(cards[currentCardIndex + 1]);

        setCurrentCardIndex(currentCardIndex + 1);
        setCurrentFace(
          cards[currentCardIndex + 1].type === "new"
            ? studyFace
            : reviewSchema[0]
        );
      }
    }

    if (cards.length - currentCardIndex < 3) {
      setNumCardsToLoad(cards.length + 3);
    }
  };

  const block = () => {
    if (blockTooltipRequired) {
      setShowBlockTooltip(true);
      setBlockTooltipRequired(false);
    } else {
      negativeResponse("block");
    }
  };

  const boost = () => {
    if (boostTooltipRequired) {
      setShowBoostTooltip(true);
      setBoostTooltipRequired(false);
    } else {
      positiveResponse("boost");
    }
  };

  const memorize = () => {
    if (memorizeTooltipRequired) {
      setShowMemorizeTooltip(true);
      setMemorizeTooltipRequired(false);
    } else {
      positiveResponse("memorize");
    }
  };

  const confirmDoneStudying = () => {
    if (isStudying) {
      currentCard.previous_num_times_marked_correct =
        currentCard.num_times_marked_correct;
      currentCard.num_times_marked_correct++;
      addCurrentCardToAdditionalReviewQueue();

      showNextCard();
    }
    decrementAllDelays();
  };

  return (
    <div
      ref={studyInterfaceRef}
      id="study-interface"
      className={`h-screen w-screen overflow-x-hidden transition-all duration-500 font-inter font-simplified-${simplifiedFont} font-traditional-${traditionalFont} layout-${layout} ${
        darkMode ? "bg-gray-800" : ""
      } ${coloredPinyin ? "colored-pinyin" : ""} ${
        coloredCharacters ? "colored-characters" : ""
      } ${isPaused ? "paused" : ""} ${settingsOpen ? "settings-open" : ""} ${
        editingNote ? "editing" : ""
      }`}
    >
      <CSSTransition
        mountOnEnter={true}
        unmountOnExit={true}
        in={!studyComplete}
        timeout={1000}
        className=""
        classNames="studysession-main"
      >
        <div
          className={`transition-all ease-in w-full h-full overflow-x-hidden ${
            false
              ? "-translate-x-[200px] ease-[cubic-bezier(0.36,0,0.66,-0.56)] opacity-0"
              : ""
          }`}
        >
          <StudySessionTimer
            unPause={unPause}
            timeStarted={timeStarted}
            secondsLeft={secondsLeft}
            elapsedPercentage={elapsedPercentage}
            studySessionDoneLoading={studySessionDoneLoading}
            setTimesUp={setTimesUp}
            increaseCharacterSize={increaseCharacterSize}
            decreaseCharacterSize={decreaseCharacterSize}
            increasePinyinSize={increasePinyinSize}
            decreasePinyinSize={decreasePinyinSize}
            increaseEnglishSize={increaseEnglishSize}
            decreaseEnglishSize={decreaseEnglishSize}
            increaseSentenceEnglishSize={increaseSentenceEnglishSize}
            decreaseSentenceEnglishSize={decreaseSentenceEnglishSize}
            increaseSentenceCharacterSize={increaseSentenceCharacterSize}
            decreaseSentenceCharacterSize={decreaseSentenceCharacterSize}
            pause={pause}
            isPaused={isPaused}
            setIsPaused={setIsPaused}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            allottedTime={limitValue * 60}
            limitType={limitType}
            limitValue={limitValue}
            layout={layout}
            setLayout={setLayout}
            darkMode={darkMode}
            toggleDarkMode={toggleDarkMode}
            showAdvancedResponses={showAdvancedResponses}
            toggleShowAdvancedResponses={toggleShowAdvancedResponses}
            showReplayButtons={showReplayButtons}
            toggleShowReplayButtons={toggleShowReplayButtons}
            uncoverElements={uncoverElements}
            resetFontSizes={resetFontSizes}
            undo={undo}
            currentCardIndex={currentCardIndex}
            shortcutHints={shortcutHints}
            toggleShortcutHints={toggleShortcutHints}
            numCardsToFinish={numCardsToFinish}
            endSessionEarly={endSessionEarly}
            autoCopy={autoCopy}
            toggleAutoCopy={toggleAutoCopy}
            autoPlay={autoPlay}
            toggleAutoPlay={toggleAutoPlay}
            userWordNote={userWordNote}
            setUserWordNote={setUserWordNote}
            saveUserWordNote={saveUserWordNote}
            card={currentCard}
            editingNote={editingNote}
            setEditingNote={setEditingNote}
          />

          <Face
            characterSize={characterSize}
            pinyinSize={pinyinSize}
            englishSize={englishSize}
            sentenceEnglishSize={sentenceEnglishSize}
            sentenceCharacterSize={sentenceCharacterSize}
            settingsOpen={settingsOpen}
            face={
              currentCard.type === "new" && !currentCard.seen
                ? studyFace
                : currentFace
            }
            card={currentCard}
            highlightHard={highlightHard}
            characterSet={characterSet}
            autoCopy={autoCopy}
            userSentences={userSentences}
            charactersAdjusting={charactersAdjusting}
            pinyinAdjusting={pinyinAdjusting}
            englishAdjusting={englishAdjusting}
            sentenceEnglishAdjusting={sentenceEnglishAdjusting}
            sentenceCharacterAdjusting={sentenceCharacterAdjusting}
            pinyinStyle={pinyinStyle} // rename to tone style?
            editingNote={editingNote}
            userWordNote={userWordNote}
            coloredCharacters={coloredCharacters}
            coloredPinyin={coloredPinyin}
          />
          <QuestionResponse
            isPaused={isPaused}
            toggleOpenSettings={toggleOpenSettings}
            face={
              currentCard.type === "new" && !currentCard.seen
                ? studyFace
                : currentFace
            }
            card={currentCard}
            positiveResponse={positiveResponse}
            negativeResponse={negativeResponse}
            confirmDoneStudying={confirmDoneStudying}
            isStudying={isStudying}
            isStudyingNew={isStudyingNew}
            highlightHard={highlightHard}
            showAdvancedResponses={showAdvancedResponses}
            showReplayButtons={showReplayButtons}
            setStudyComplete={setStudyComplete}
            undo={undo}
            playAudio={playAudio}
            mouseClicks={mouseClicks}
            setMouseClicks={setMouseClicks}
            block={block}
            boost={boost}
            memorize={memorize}
            showMoreResponses={showMoreResponses}
            setShowMoreResponses={setShowMoreResponses}
            shortcutHints={shortcutHints}
            editingNote={editingNote}
          />
          <ShortcutLegend
            shortcutHints={shortcutHints}
            settingsOpen={settingsOpen}
          />

          <CSSTransition
            mountOnEnter={true}
            unmountOnExit={true}
            in={
              userShortcutsReminder &&
              !remindedAboutShortcuts &&
              mouseClicks > 10 &&
              window.innerWidth > 768
            }
            timeout={1000}
            className=""
            classNames="shortcut-encouragement"
          >
            <ShortcutEncouragement
              toggleShortcutsReminder={toggleShortcutsReminder}
              shortcutsReminder={shortcutsReminder}
              setRemindedAboutShortcuts={setRemindedAboutShortcuts}
            />
          </CSSTransition>

          <div
            ref={undoOverlayRef}
            className={`${
              studySessionDoneLoading && !studyComplete
                ? "opacity-100"
                : "opacity-0"
            } fixed z-50 top-0 left-0 h-full w-full pointer-events-none bg-gradient-to-r from-transparent via-blue-neon to-transparent translate-x-full`}
          ></div>
        </div>
      </CSSTransition>
      {studySessionDoneLoading && (
        <AdvancedResponseTooltips
          showBlockTooltip={showBlockTooltip}
          setShowBlockTooltip={setShowBlockTooltip}
          setBlockTooltipRequired={setBlockTooltipRequired}
          showBoostTooltip={showBoostTooltip}
          setShowBoostTooltip={setShowBoostTooltip}
          setBoostTooltipRequired={setBoostTooltipRequired}
          showMemorizeTooltip={showMemorizeTooltip}
          setShowMemorizeTooltip={setShowMemorizeTooltip}
          setMemorizeTooltipRequired={setMemorizeTooltipRequired}
          positiveResponse={positiveResponse}
          block={block}
        />
      )}

      {soundLocked && (
        <div className="fixed bg-white dark:bg-gray-800 h-full w-full inset-0 flex flex-col items-center justify-center">
          <div
            className="button text-2xl bg-blue-brand text-white py-2 px-6"
            onClick={() => unLockAudio()}
          >
            Click to begin
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-300 w-[240px] text-center mt-6">
            Your browser requires a button-click from you before it will let us
            play audio.
          </div>
        </div>
      )}

      <CSSTransition
        mountOnEnter={true}
        unmountOnExit={false}
        in={studyComplete}
        timeout={1000}
        className=""
        classNames="victory-screen"
      >
        <VictoryScreen
          cards={cards}
          numWeakMemories={numWeakMemories}
          vocabularyLists={vocabularyLists}
          characterSet={characterSet}
        />
      </CSSTransition>
    </div>
  );
}
