import React from "react";

export default function WordImportDuplicates({ duplicates, numDuplicates }) {
  return (
    <div className="flex flex-col">
      <div className="text-3xl mx-auto">Duplicates</div>

      {numDuplicates == 0 && (
        <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
          We wouldn't let you add duplicate words to this list even if you
          tried. Fortunately, there were none!
        </div>
      )}

      {numDuplicates > 0 && (
        <>
          <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
            These words were already in your list and have not been added again.
          </div>
          <div className="flex flex-wrap mt-6 text-3xl simplified justify-center">
            {duplicates.split("/").map((word, i) => {
              return (
                <div
                  key={i}
                  className="relative bg-gray-200 dark:bg-gray-6N00 p-1 rounded flex items-center justify-center mr-2 mb-2"
                >
                  <div className="absolute -top-[6px] -right-[6px] text-sm text-red">
                    <i className="fa-solid fa-ban"></i>
                  </div>
                  {word}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
