import React, { useState } from "react";

export default function FutureReviewsWhenPausedWidget({}) {
  return (
    <>
      <div className="flex justify-between relative">
        <div className="text-xl font-bold text-gray-600 dark:text-gray-300 tracking-wide">
          Review Schedule: All Clear!
          <p className="font-normal text-sm w-full max-w-prose">
            Memory decay is paused. No additional words will come due for review
            until you resume memory decay.
          </p>
        </div>
      </div>
    </>
  );
}
