import React, { useState, useEffect } from "react";

export default function StudySettings({
  saveSettings,
  reviewMode,
  setReviewMode,
  autoCopyWords,
  setAutoCopyWords,
  distinguishHardWords,
  setDistinguishHardWords,
}) {
  function handleSetReviewMode(newReviewMode) {
    setReviewMode(newReviewMode);
    saveSettings("review_mode", newReviewMode);
  }

  function toggleAutoCopyWords() {
    saveSettings("auto_copy_words", !autoCopyWords);
    setAutoCopyWords(!autoCopyWords);
  }

  function toggleDistinguishHardWords() {
    saveSettings("distinguish_hard", !distinguishHardWords);
    setDistinguishHardWords(!distinguishHardWords);
  }
  return (
    <>
      <div className="setting-title mt-4">Review Mode</div>
      <div className="flex mt-2">
        <div className="grid grid-cols-3 gap-0 text-gray-400 font-semibold">
          <div
            className={`multi-toggle ${
              reviewMode === "4-click" ? "selected" : ""
            }`}
            onClick={() => handleSetReviewMode("4-click")}
          >
            4-click
          </div>
          <div
            className={`multi-toggle ${
              reviewMode === "2-click" ? "selected" : ""
            }`}
            onClick={() => handleSetReviewMode("2-click")}
          >
            2-click
          </div>
          <div
            className={`multi-toggle ${
              reviewMode === "excel" ? "selected" : ""
            }`}
            onClick={() => handleSetReviewMode("excel")}
          >
            Excel
          </div>
        </div>
      </div>
      <div className="flex mt-3 w-full">
        <div className="grid grid-cols-3 gap-[2px] bg-gray-100 dark:bg-gray-600 w-full">
          <div className="py-2 bg-white dark:bg-gray-700 pr-3">
            <div className="text-gray-600 dark:text-gray-300 font-medium">
              4-click
            </div>

            <ol className="mt-3 list-decimal list-inside ml-0 text-xs">
              <li className="ml-0">Do you know the pronunciation?</li>
              <li className="ml-0">Were you correct?</li>
              <li className="ml-0">Do you know the definition?</li>
              <li className="ml-0">Were you correct?</li>
            </ol>
          </div>
          <div className="bg-white dark:bg-gray-700 py-2 px-3">
            {" "}
            <div className="text-gray-600 dark:text-gray-300 font-medium">
              2-click
            </div>
            <ol className="mt-3 list-decimal list-inside ml-0 text-xs">
              <li className="ml-0">Do you know this word?</li>
              <li className="ml-0">Were you correct?</li>
            </ol>
          </div>
          <div className="bg-white dark:bg-gray-700 py-2 pl-3">
            <div className="text-gray-600 dark:text-gray-300 font-medium">
              Excel
            </div>
            <div className="mt-3 text-xs">
              "4-click" for new words, "2-click" for review words. In addition,
              example sentences appear along with the word, and all audio plays
              immediately on the first card face.
            </div>
          </div>
        </div>
      </div>
      <p>
        We recommend new students use the default "4-click" mode as they get
        used to actively recalling the pronunciation and definition of each
        word, and then switching to the faster "2-click" mode once they have
        some experience.
      </p>

      <div
        className="flex items-center mt-12"
        onClick={() => toggleAutoCopyWords()}
      >
        <div className={`two-way-toggle ${autoCopyWords ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">Auto-Copy</div>
      </div>

      <p>
        If you frequently pause your study sessions to do additional research on
        other websites, you can save time by automatically copying each word to
        your device's clipboard as you progress through your study sessions.
        <span className="text-blue-neon ml-1 font-semibold">
          Note: This only works reliably on desktops.
        </span>
      </p>

      <div
        className="flex items-center mt-12"
        onClick={() => toggleDistinguishHardWords()}
      >
        <div className={`two-way-toggle ${distinguishHardWords ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">Distinguish Hard Words</div>
      </div>

      <p>
        Words that are hard for you to remember will be colored in red so you
        can allow yourself more time to review (or pause to perform additional
        learning activities).
      </p>
      <div className="setting-title mt-12">Other Study Settings</div>

      <p className="no-border">
        You can change the layout, toggle audio, toggle a shortcut legend,
        change element sizes and more by pressing 'Pause/Settings' while
        studying.
      </p>
    </>
  );
}
