import React, { useState, useRef } from "react";

import ReviewsScreen from "./ReviewsScreen";
import AssumedScreen from "./AssumedScreen";
import NewScreen from "./NewScreen";
import VictoryScreenNav from "./VictoryScreenNav";

export default function VictoryScreen({
  numWeakMemories,
  cards,
  vocabularyLists,
  characterSet,
}) {
  const exitPath = localStorage.getItem("ssExitPath") || "/study_sessions/new";
  const [seenReviewsScreen, setSeenReviewsScreen] = useState(false);
  const [seenAssumedScreen, setSeenAssumedScreen] = useState(false);
  const [seenNewScreen, setSeenNewScreen] = useState(false);
  const [allReviewsCleared, setAllReviewsCleared] = useState(false);
  const [automatic, setAutomatic] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [numMemoriesRefreshed, setNumMemoriesRefreshed] = useState(
    cards.filter((card) => {
      return card.type === "review" && card.result === "pass";
    }).length
  );
  const numEarlyReviewsCompleted = cards.filter((card) => {
    return card.type === "early_review" && card.send_status === "sent";
  }).length;

  const wordsLearned = cards.filter((card) => {
    return card.type === "new" && card.result === "pass";
  });

  const wordAssumptionsChecked = cards.filter((card) => {
    return card.originally_assumed === true && card.first_result != null;
  });

  const [activeScreen, setActiveScreen] = useState(() => {
    if (numMemoriesRefreshed === 0 && numEarlyReviewsCompleted === 0) {
      if (wordAssumptionsChecked.length > 1) {
        return "assumed";
      } else {
        return "new";
      }
    } else {
      return "reviews";
    }
  });

  function playAudioFromTable(id) {
    document.getElementById(id).play();
  }

  return (
    <div
      className={`${
        allReviewsCleared ? "all-reviews-cleared" : ""
      } w-full h-screen flex-col items-center justify-center text-gray-400`}
    >
      <a
        href={exitPath}
        className={`fixed flex flex-col justify-center items-center bottom-0 right-0 rounded-full xs:hidden w-[60px] h-[60px] text-sm bg-${activeScreen}  text-white shadow-lg mr-6 mb-6 z-50`}
      >
        <i className="fa-solid fa-person-to-door"></i>
        <div className="mt-1">Exit</div>
      </a>

      <VictoryScreenNav
        activeScreen={activeScreen}
        setActiveScreen={setActiveScreen}
        automatic={automatic}
        setAutomatic={setAutomatic}
        playing={playing}
        setPlaying={setPlaying}
        numMemoriesRefreshed={numMemoriesRefreshed}
        numEarlyReviewsCompleted={numEarlyReviewsCompleted}
        numAssumptionsChecked={wordAssumptionsChecked.length}
        seenReviewsScreen={seenReviewsScreen}
        seenAssumedScreen={seenAssumedScreen}
        seenNewScreen={seenNewScreen}
        numWordsLearned={wordsLearned.length}
        exitPath={exitPath}
      />

      <div className="relative w-full max-w-screen-lg mx-auto px-3 mt-24 md:mt-32">
        {activeScreen === "reviews" && (
          <ReviewsScreen
            cards={cards}
            numWeakMemories={numWeakMemories}
            numMemoriesRefreshed={numMemoriesRefreshed}
            numEarlyReviewsCompleted={numEarlyReviewsCompleted}
            cards={cards}
            allReviewsCleared={allReviewsCleared}
            setAllReviewsCleared={setAllReviewsCleared}
            seenReviewsScreen={seenReviewsScreen}
            setSeenReviewsScreen={setSeenReviewsScreen}
            playAudioFromTable={playAudioFromTable}
            characterSet={characterSet}
          />
        )}

        {activeScreen === "assumed" && (
          <AssumedScreen
            wordAssumptionsChecked={wordAssumptionsChecked}
            seenAssumedScreen={seenAssumedScreen}
            setSeenAssumedScreen={setSeenAssumedScreen}
            characterSet={characterSet}
          />
        )}

        {activeScreen === "new" && (
          <NewScreen
            cards={cards}
            vocabularyLists={vocabularyLists}
            seenNewScreen={seenNewScreen}
            setSeenNewScreen={setSeenNewScreen}
            playAudioFromTable={playAudioFromTable}
            wordsLearned={wordsLearned}
            characterSet={characterSet}
          />
        )}
      </div>
    </div>
  );
}
