import React, { useState, useEffect } from "react";

export default function ResetSettings({}) {
  return (
    <>
      <div className="flex flex-col w-full mt-14">
        <div className="w-full max-w-prose">
          If you're overwhelmed with the number of reviews due, correcting
          course may feel painful in the short term but can be{" "}
          <a
            href="https://www.hackchinese.com/study-guide/our-best-advice-slow-fast"
            className="textlink"
          >
            beneficial in the long run
          </a>
          .
        </div>
        <div className="hidden sm:grid grid-cols-7 w-full gap-2 mb-20 text-xs md:text-sm w-full max-w-screen-lg mt-14">
          <div className="bg-gray-100 dark:bg-gray-700 dark:border-gray-600 border rounded w-full font-semibold">
            <div className="flex              h-24 dark:border-gray-600 md:h-20 p-1 md:p-2 lg:p-3 text-base md:text-lg font-semibold row-span-2">
              Reset Action
            </div>
            <div className="flex items-center h-12 dark:border-gray-600 md:h-10 px-1 md:px-2 lg:px-3 border-t border-b border-gray-300">
              Convert to Assumed
            </div>
            <div className="flex items-center h-12 dark:border-gray-600 md:h-10 px-1 md:px-2 lg:px-3 border-b border-gray-300">
              Remove Weak
            </div>
            <div className="flex items-center h-12 dark:border-gray-600 md:h-10 px-1 md:px-2 lg:px-3 border-b border-gray-300">
              Partial Reset
            </div>
            <div className="flex items-center h-12 dark:border-gray-600 md:h-10 px-1 md:px-2 lg:px-3">
              Full Reset
            </div>
          </div>

          <div className="border dark:border-gray-600 col-span-3 grid grid-cols-3 rounded w-full">
            <div className="flex              h-12 md:h-10 p-1 md:p-2 lg:p-3 col-span-3 text-base md:text-lg font-semibold">
              Words
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r text-gray-600 dark:text-gray-400 font-semibold text-sm lg:text-base">
              Weak
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r text-green font-semibold text-sm lg:text-base">
              Strong
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b text-purple font-semibold text-sm lg:text-base">
              Assumed
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r">
              <span className="text-xs">
                convert to
                <br />
                <span className="text-purple text-semibold">
                  assumed <span className="hidden lg:inline">known</span>
                </span>
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b border-r">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-b">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-r">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 dark:border-gray-600 border-r">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
          </div>

          <div className="border dark:border-gray-600 col-span-2 grid grid-cols-2 rounded w-full">
            <div className="flex              h-12 md:h-10 p-1 md:p-2 lg:p-3 col-span-2 text-base md:text-lg font-semibold">
              Lists
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b border-r dark:border-gray-600 font-semibold text-sm lg:text-base">
              List Queue
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600 font-semibold text-sm lg:text-base">
              Custom Lists
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600 border-r"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600 "></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600 border-r"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600 "></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600 border-r"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600 "></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-r dark:border-gray-600 ">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
          </div>

          <div className="border dark:border-gray-600 rounded w-full">
            <div className="flex              h-12 md:h-10 p-1  md:p-2  lg:p-3 text-base md:text-lg font-semibold">
              <span className="hidden lg:inline">Statistics</span>
              <span className="lg:hidden">Stats</span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600"></div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3 border-b dark:border-gray-600">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
            <div className="flex items-center h-12 md:h-10 px-1 md:px-2 lg:px-3">
              <span className="bg-red bg-opacity-10 dark:bg-opacity-30 px-1 rounded text-red">
                remove
              </span>
            </div>
          </div>
        </div>
        Click here to perform a RESET:
        http://localhost:3000/settings?type=resets
      </div>
    </>
  );
}
