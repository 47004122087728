import React, { useState } from "react";
import { API_ENDPOINT } from "./constants";

export default function SubmitWordForApprovalForm({
  token,
  listsAndFocuses,
  missingWord,
}) {
  const [listToAddTo, setListToAddTo] = useState(listsAndFocuses[0][1]);
  const [submitted, setSubmitted] = useState(false);

  const MakeOption = function (listAndFocus) {
    return <option value={listAndFocus[1]}>{listAndFocus[0]["name"]}</option>;
  };

  const onChange = (event) => {
    const value = event.target.value;
    setListToAddTo(value);
  };

  function submitWordForApproval(e) {
    e.preventDefault();

    const data = {
      missing_word: missingWord,
      focus_id: listToAddTo,
    };

    fetch(`${API_ENDPOINT}/pending_words`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        console.log("Word submitted!");
      } else {
        console.log("Could not submit this word.");
      }
      setSubmitted(true);
    });
  }

  return (
    <form
      className="flex flex-col md:flex-row w-full"
      onChange={onChange}
      onSubmit={(e) => {
        submitWordForApproval(e);
      }}
    >
      <select className="outline-none flex-grow h-14 bg-gray-100 dark:bg-gray-600 rounded-xl p-2">
        {listsAndFocuses.map(MakeOption)}
      </select>
      {!submitted && (
        <input
          className="w-full md:w-28 flex-shrink-0 cursor-pointer flex items-center justify-center font-bold bg-blue/30 hover:bg-blue/20 dark:hover-bg-blue-10 text-blue h-14 rounded-xl mt-6 md:mt-0 md:ml-6"
          type="submit"
          value="Submit"
        />
      )}
      {submitted && (
        <div className="w-full md:w-28 flex-shrink-0 cursor-pointer flex items-center justify-center font-bold bg-gray-200 text-gray-800 h-14 rounded-xl mt-6 md:mt-0 md:ml-6">
          Submitted!
        </div>
      )}
    </form>
  );
}
