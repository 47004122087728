import React, { useState, useEffect } from "react";
import WordImportNav from "./WordImportNav";
import WordImportMatches from "./WordImportMatches";
import WordImportDuplicates from "./WordImportDuplicates";
import WordImportAmbiguous from "./WordImportAmbiguous";
import WordImportNewToDatabase from "./WordImportNewToDatabase";
import WordImportFinish from "./WordImportFinish";

export default function WordImportResults({ token, wordImport }) {
  const numMatches = wordImport["matched_words"]
    .split("/")
    .filter(Boolean).length;
  const numDuplicates = wordImport["duplicate_words"]
    .split("/")
    .filter(Boolean).length;
  const numAmbiguous = Object.keys(
    wordImport.ambiguous_words_with_suggestions
  ).length;
  const numMissing = wordImport["new_to_database_words"]
    .split("/")
    .filter(Boolean).length;

  const [screen, setScreen] = useState(() => {
    if (numAmbiguous > 0) {
      return "ambiguous";
    } else if (numMissing > 0) {
      return "missing";
    } else {
      return "finish";
    }
  });

  const wordImportId = wordImport["id"];
  const listId = wordImport["focus_id"];
  const exitPath = wordImport["exit_path"];

  const [ambiguousComplete, setAmbiguousComplete] = useState(numAmbiguous == 0);
  const [missingComplete, setMissingComplete] = useState(numMissing == 0);

  const [allComplete, setAllComplete] = useState(
    numMissing == 0 && numAmbiguous == 0
  );

  useEffect(() => {
    if (ambiguousComplete && missingComplete) {
      setAllComplete(true);
    }
  }, [ambiguousComplete, missingComplete]);

  const advanceToNextScreen = () => {
    if (screen == "ambiguous" && numMissing == 0) {
      setScreen("finish");
    } else if (screen == "ambiguous" && numMissing > 0) {
      setScreen("missing");
    } else if (screen == "missing") {
      setScreen("finish");
    }
  };

  return (
    <>
      <WordImportNav
        screen={screen}
        setScreen={setScreen}
        numMatches={numMatches}
        numDuplicates={numDuplicates}
        numAmbiguous={numAmbiguous}
        numMissing={numMissing}
        ambiguousComplete={ambiguousComplete}
        missingComplete={missingComplete}
      />
      <div className="a-container px-6 mx-auto flex flex-col text-gray-500 dark:text-gray-300">
        <div className="w-full pt-[196px] pb-12">
          {screen === "matches" && (
            <WordImportMatches
              matches={wordImport["matched_words"]}
              numMatches={numMatches}
            />
          )}

          {screen === "duplicates" && (
            <WordImportDuplicates
              duplicates={wordImport["duplicate_words"]}
              numDuplicates={numDuplicates}
            />
          )}

          {screen === "ambiguous" && (
            <WordImportAmbiguous
              token={token}
              focusId={wordImport.focus_id}
              numAmbiguous={numAmbiguous}
              ambiguousWordsWithSuggestions={
                wordImport.ambiguous_words_with_suggestions
              }
              setAmbiguousComplete={setAmbiguousComplete}
              advanceToNextScreen={advanceToNextScreen}
            />
          )}

          {screen === "missing" && (
            <WordImportNewToDatabase
              token={token}
              focusId={wordImport.focus_id}
              missingWords={wordImport["new_to_database_words"]}
              numMissing={numMissing}
              setMissingComplete={setMissingComplete}
              advanceToNextScreen={advanceToNextScreen}
            />
          )}

          {screen === "finish" && (
            <WordImportFinish
              token={token}
              setScreen={setScreen}
              numMatches={numMatches}
              numDuplicates={numDuplicates}
              numAmbiguous={numAmbiguous}
              numMissing={numMissing}
              ambiguousComplete={ambiguousComplete}
              missingComplete={missingComplete}
              allComplete={allComplete}
              listId={listId}
              wordImportId={wordImportId}
              exitPath={exitPath}
            />
          )}
        </div>
      </div>
    </>
  );
}
