import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { gsap } from "gsap";

export default function CustomStudyModes({
  setMode,
  setStudyModesToDisplay,
  hasAssumed,
  hasHard,
  hasWeak,
  hasStrong,
}) {
  const tl = useRef();
  const backButtonRef = useRef();

  const customStudyModesContainerRef = useRef();

  const csm = gsap.utils.selector(customStudyModesContainerRef);

  function applyHoverAnimations() {
    gsap.utils.toArray(".study-mode-btn.valid").forEach((button) => {
      let hover = gsap.to(button, {
        scale: 1.1,
        duration: 0.075,
        paused: true,
        ease: "power1.inOut",
      });
      button.addEventListener("mouseenter", () => hover.play());
      button.addEventListener("mouseleave", () => hover.reverse());
    });
  }

  function setModeIfValid(newMode) {
    switch (newMode) {
      case "refresh":
        if (hasWeak) {
          setMode("refresh");
        }
        break;
      case "repeat":
        if (hasStrong) {
          setMode("repeat");
        }
        break;
      case "assumed":
        if (hasAssumed) {
          setMode("assumed");
        }
        break;
      case "hard":
        if (hasHard) {
          setMode("hard");
        }
        break;
      default:
      // code block
    }
  }

  useLayoutEffect(() => {
    tl.current = gsap
      .timeline({ onComplete: applyHoverAnimations })
      .from(csm(".study-mode-btn"), {
        x: 50,
        opacity: 0,
        stagger: 0.025,
        duration: 0.2,
      })
      .from(
        backButtonRef.current,
        {
          x: 50,
          opacity: 0,
          stagger: 0.025,
          duration: 0.2,
        },
        ">-.125"
      );
  }, []);

  function swapToRegularStudyModes() {
    tl.current = gsap
      .timeline({
        onComplete: setStudyModesToDisplay,
        onCompleteParams: ["regular"],
      })
      .to(backButtonRef.current, {
        x: 50,
        opacity: 0,
        stagger: 0.025,
        duration: 0.2,
      })
      .to(
        csm(".study-mode-btn"),
        {
          x: 50,
          opacity: 0,
          stagger: -0.025,
          duration: 0.2,
        },
        ">-.125"
      );
  }

  return (
    <div
      className="custom-study-modes flex flex-col lg:flex-row justify-center px-6 pb-16 sm:pb-0"
      ref={customStudyModesContainerRef}
    >
      <div className="fixed top-0 sm:top-14 left-0 w-full">
        <div className="a-container sm:px-6 flex h-16 justify-end">
          <div
            ref={backButtonRef}
            className="nav-btn text-gray-400 text-2xl"
            style={{ border: "none" }}
            onClick={() => swapToRegularStudyModes()}
          >
            <div className="flex flex-col items-center justify-center">
              <i className="fal fa-long-arrow-left"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`study-mode-btn group h-18 ${hasWeak ? "valid" : "invalid"}`}
        onClick={() => setModeIfValid("refresh")}
      >
        <div className="invalid-mode-overlay"></div>{" "}
        <div
          className={`study-mode-btn-panel hidden lg:flex ${
            hasWeak ? "group-hover:bg-gray-400 group-hover:text-white" : ""
          }`}
        >
          <i className="fa-solid fa-recycle"></i>
        </div>
        <div
          className={`study-mode-btn-desc ${
            hasWeak ? "group-hover:border-gray-400" : ""
          }`}
        >
          <div
            className={`study-mode-btn-title ${
              hasWeak ? "group-hover:text-gray-400" : ""
            }`}
          >
            Refresh
          </div>
          <div className="study-mode-btn-details">
            Refresh weak memories but stop before learning anything new.
          </div>
          <div className="study-mode-btn-invalid-details">
            You have no weak memories to refresh!
          </div>
        </div>
      </div>
      <div
        className={`study-mode-btn group h-18 ${
          hasAssumed ? "valid" : "invalid"
        }`}
        onClick={() => setModeIfValid("assumed")}
      >
        <div className="invalid-mode-overlay"></div>{" "}
        <div
          className={`study-mode-btn-panel hidden lg:flex ${
            hasAssumed ? "group-hover:bg-purple group-hover:text-white" : ""
          }`}
        >
          <i className="fa-solid fa-ring"></i>
        </div>
        <div
          className={`study-mode-btn-desc ${
            hasAssumed ? "group-hover:border-purple" : ""
          }`}
        >
          <div
            className={`study-mode-btn-title ${
              hasAssumed ? "group-hover:text-purple" : ""
            }`}
          >
            Assumed
          </div>
          <div className="study-mode-btn-details">
            Check words we assume you know.
          </div>
          <div className="study-mode-btn-invalid-details">
            You have no words that are assumed known!
          </div>
        </div>
      </div>
      <div
        className={`study-mode-btn group h-18 ${
          hasStrong ? "valid" : "invalid"
        }`}
        onClick={() => setModeIfValid("repeat")}
      >
        <div className="invalid-mode-overlay"></div>{" "}
        <div
          className={`study-mode-btn-panel hidden lg:flex ${
            hasStrong ? "group-hover:bg-yellow group-hover:text-white" : ""
          }`}
        >
          <i className="fa-solid fa-repeat"></i>
        </div>
        <div
          className={`study-mode-btn-desc ${
            hasStrong ? "group-hover:border-yellow" : ""
          }`}
        >
          <div
            className={`study-mode-btn-title ${
              hasStrong ? "group-hover:text-yellow" : ""
            }`}
          >
            Repeat
          </div>
          <div className="study-mode-btn-details">
            Review words you have strong memories of (before you theoretically
            need to).
          </div>
          <div className="study-mode-btn-invalid-details">
            You don't have any strong memories to repeat!
          </div>
        </div>
      </div>
      <div
        className="study-mode-btn group h-18 valid"
        onClick={() => setMode("new")}
      >
        <div className="invalid-mode-overlay"></div>{" "}
        <div className="study-mode-btn-panel hidden lg:flex group-hover:bg-blue group-hover:text-white">
          <i className="fa-solid fa-sparkles"></i>
        </div>
        <div className="study-mode-btn-desc group-hover:border-blue">
          <div
            className={`study-mode-btn-title group-hover:text-blue dark:brightness-125`}
          >
            New
          </div>
          <div className="text-xs lg:text-sm lg:text-center">
            <span className="lg:hidden">Jump to learning new words.</span>
            <span className="hidden lg:inline">
              Ignore weak memories and jump straight to learning new words.
            </span>
          </div>
        </div>
      </div>
      <div
        className={`study-mode-btn group h-18 ${hasHard ? "valid" : "invalid"}`}
        onClick={() => setModeIfValid("hard")}
      >
        <div className="invalid-mode-overlay"></div>
        <div
          className={`study-mode-btn-panel hidden lg:flex ${
            hasHard ? "group-hover:bg-red group-hover:text-white" : ""
          }`}
        >
          <i className="fa-solid fa-cloud-showers"></i>
        </div>
        <div
          className={`study-mode-btn-desc ${
            hasHard ? "group-hover:border-red" : ""
          }`}
        >
          <div
            className={`study-mode-btn-title ${
              hasHard ? "group-hover:text-red" : ""
            }`}
          >
            Hard
          </div>
          <div className="study-mode-btn-details">
            Focus on words you have trouble remembering.
          </div>
          <div className="study-mode-btn-invalid-details">
            We haven't yet identified any words that are hard for you.
          </div>
        </div>
      </div>
    </div>
  );
}
