import React, { useState } from 'react';

function VocabSizeWidgetPopup({statValue}) {
  return (
    <div>
      <p className="text-base">
        <span className="font-semibold dark:text-gray-100">Vocabulary Size</span> measures how many words you probably know.
      </p>
      <p className="mt-3">This includes words you've learned with Hack Chinese (with <span className="font-semibold text-green">strong</span> or <span className="font-semibold text-gray-500 dark:text-gray-200">weak</span> memories) and any words we <span className="font-semibold text-purple">assume</span> you know (but haven't yet tested you on).</p>
    </div>
  );
}

export default VocabSizeWidgetPopup;