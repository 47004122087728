import React from "react";
import { API_ENDPOINT } from "./constants";

export default function WordImportFinish({
  token,
  setScreen,
  numMatches,
  numDuplicates,
  numAmbiguous,
  numMissing,
  ambiguousComplete,
  missingComplete,
  allComplete,
  listId,
  wordImportId,
  exitPath,
}) {
  function completeImportAndReturnToList() {
    const data = {
      word_import_id: wordImportId,
      focus_id: listId,
    };

    fetch(`${API_ENDPOINT}/mark_import_complete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        window.location.replace(`${API_ENDPOINT}${exitPath}`);
      } else {
        console.log("Could not mark this import complete.");
      }
    });
  }

  return (
    <div className="flex flex-col">
      <div className="mt-12 w-full max-w-3xl flex flex-col items-center gap-6 text-lg mx-auto">
        <div
          onClick={() => setScreen("matches")}
          className="flex items-center cursor-pointer"
        >
          <div className="flex justify-center w-32">
            <div className="bg-green/20 text-green rounded-xl px-2">
              Complete
            </div>
          </div>
          <div className="w-72 relative">
            <span className="z-20 bg-white dark:bg-gray-800 pr-3 relative">
              Matches
            </span>
            <div className="z-10 flex items-center absolute top-0 left-0 w-full h-full pl-3">
              <div className="w-full border-b-2 border-dashed dark:border-gray-600"></div>
            </div>
          </div>
          <div className="text-center w-32">
            <span className="textlink">{numMatches} words</span>
          </div>
        </div>

        <div
          onClick={() => setScreen("duplicates")}
          className="flex items-center cursor-pointer"
        >
          <div className="flex justify-center w-32">
            <div className="bg-green/20 text-green rounded-xl px-2">
              Complete
            </div>
          </div>
          <div className="w-72 relative">
            <span className="z-20 bg-white dark:bg-gray-800 pr-3 relative">
              Duplicates
            </span>
            <div className="z-10 flex items-center absolute top-0 left-0 w-full h-full pl-3">
              <div className="w-full border-b-2 border-dashed dark:border-gray-600"></div>
            </div>
          </div>
          <div className="text-center w-32">
            <span className="textlink">{numDuplicates} words</span>
          </div>
        </div>

        <div
          onClick={() => setScreen("ambiguous")}
          className="flex items-center cursor-pointer"
        >
          <div className="flex justify-center w-32">
            {ambiguousComplete && (
              <div className="bg-green/20 text-green rounded-xl px-2">
                Complete
              </div>
            )}
            {!ambiguousComplete && (
              <div className="bg-yellow/30 dark:bg-yellow/40 text-gray-900 dark:text-white rounded-xl px-2">
                Incomplete
              </div>
            )}
          </div>
          <div className="w-72 relative">
            <span className="z-20 bg-white dark:bg-gray-800 pr-3 relative">
              Ambiguous
            </span>
            <div className="z-10 flex items-center absolute top-0 left-0 w-full h-full pl-3">
              <div className="w-full border-b-2 border-dashed dark:border-gray-600"></div>
            </div>
          </div>
          <div className="text-center w-32">
            <span className="textlink">{numAmbiguous} words</span>
          </div>
        </div>

        <div
          onClick={() => setScreen("missing")}
          className="flex items-center cursor-pointer"
        >
          <div className="flex justify-center w-32">
            {missingComplete && (
              <div className="bg-green/20 text-green rounded-xl px-2">
                Complete
              </div>
            )}
            {!missingComplete && (
              <div className="bg-yellow/30 dark:bg-yellow/90 text-gray-900 rounded-xl px-2">
                Incomplete
              </div>
            )}
          </div>
          <div className="w-72 relative">
            <span className="z-20 bg-white dark:bg-gray-800 pr-3 relative">
              Missing
            </span>
            <div className="z-10 flex items-center absolute top-0 left-0 w-full h-full pl-3">
              <div className="w-full border-b-2 border-dashed dark:border-gray-600"></div>
            </div>
          </div>
          <div className="text-center w-32">
            <span className="textlink">{numMissing} words</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full max-w-sm mx-auto items-center justify-center mt-12">
        {allComplete && (
          <>
            <p className="text-center">
              You may now safely mark this import complete and return to your
              list.
            </p>
            <div
              onClick={() => completeImportAndReturnToList()}
              className="h-14 mt-6 button text-lg bg-blue-brand text-white rounded-xl flex items-center justify-center px-3"
            >
              Mark Import Complete
            </div>
          </>
        )}
        {!allComplete && (
          <>
            <p className="text-center">
              Please return to incomplete sections above and mark each
              'Complete' before finishing this import.
            </p>
            <div className="h-14 mt-6 text-lg bg-gray-400 dark:bg-gray-600 cursor-not-allowed text-white rounded-xl flex items-center justify-center px-3">
              Mark Import Complete
            </div>
          </>
        )}
      </div>
    </div>
  );
}
