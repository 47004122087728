import React from "react";
import { API_ENDPOINT } from "./constants";

export default function FirstStudySession({ token }) {
  function startStudySession() {
    const data = {
      study_session: {
        limit: "minutes",
        minutes: 2,
        mode: "everyday",
        assumption_check_quota: 3,
      },
    };

    fetch(`${API_ENDPOINT}/study_sessions/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        window.location.href = response.path;
      });
  }

  return (
    <div className="flex mx-auto items-center justify-center">
      <div
        id="onboarding_directions"
        className="hidden items-center justify-center bg-gradient-to-r from-blue-neon to-blue-brand cursor-pointer hover:bg-opacity-90 shadow-xl active:shadow-sm px-10 h-10 font-bold rounded-xl text-white"
        onClick={() => startStudySession()}
      >
        Start Now
      </div>
    </div>
  );
}
