import React, { useState } from "react";

function VelocityWidgetStat({ statValue }) {
  let classifier = "word/day";

  if (statValue > 1) {
    classifier = "words/day";
  }

  if (statValue < 0) {
    return <div className="text-gray-300 dark:text-gray-600">n/a</div>;
  } else if (statValue < 1) {
    return (
      <div>
        <i className="fa-solid fa-less-than text-base"></i>1
        <span className="text-base ml-1">word/day</span>
      </div>
    );
  } else {
    return (
      <div>
        {statValue}
        <span className="text-base ml-1">{classifier}</span>
      </div>
    );
  }
}

export default VelocityWidgetStat;
