import React, { useState } from 'react';

function StudyDurationWidgetPopup({statValue}) {
  return (
    <div>
      <p className="text-base">
        <span className="font-semibold dark:text-gray-100">Study Duration</span> measures how much time you have spent studying.
      </p>
      <p className="mt-3">This includes both reviewing weak memories and learning new words.</p>
    </div>
  );
}

export default StudyDurationWidgetPopup;