import React, { useState } from "react";
import AttemptsWidgetPopup from "./AttemptsWidgetPopup";
import AttemptsWidgetStat from "./AttemptsWidgetStat";
import RetentionRateWidgetPopup from "./RetentionRateWidgetPopup";
import RetentionRateWidgetStat from "./RetentionRateWidgetStat";
import StudyDurationWidgetPopup from "./StudyDurationWidgetPopup";
import StudyDurationWidgetStat from "./StudyDurationWidgetStat";
import StudySessionsWidgetPopup from "./StudySessionsWidgetPopup";
import StudySessionsWidgetStat from "./StudySessionsWidgetStat";
import VelocityWidgetPopup from "./VelocityWidgetPopup";
import VelocityWidgetStat from "./VelocityWidgetStat";
import VocabSizeWidgetPopup from "./VocabSizeWidgetPopup";
import VocabSizeWidgetStat from "./VocabSizeWidgetStat";

const components = {
  vocabSizeWidgetStat: VocabSizeWidgetStat,
  vocabSizeWidgetPopup: VocabSizeWidgetPopup,
  studyDurationWidgetStat: StudyDurationWidgetStat,
  studyDurationWidgetPopup: StudyDurationWidgetPopup,
  retentionRateWidgetPopup: RetentionRateWidgetPopup,
  retentionRateWidgetStat: RetentionRateWidgetStat,
  attemptsWidgetStat: AttemptsWidgetStat,
  attemptsWidgetPopup: AttemptsWidgetPopup,
  studySessionsWidgetStat: StudySessionsWidgetStat,
  studySessionsWidgetPopup: StudySessionsWidgetPopup,
  velocityWidgetStat: VelocityWidgetStat,
  velocityWidgetPopup: VelocityWidgetPopup,
};

export default function MiniDashboardWithDateRangesWidget({
  title,
  statClassifier,
  statValue7Day,
  statValue30Day,
  statValue90Day,
  statValueLifetime,
  rangeToShow,
  statComponent,
  popupComponent,
  popupCssClasses,
  globalRetentionRate,
}) {
  var PopupComponent = components[popupComponent];
  var StatComponent = components[statComponent];

  const [show7day, setShow7day] = useState(true); // TODO: use useEffect hook to do it dynamically later on
  const [show30day, setShow30day] = useState(false);
  const [show90day, setShow90day] = useState(false);
  const [showLifetime, setShowLifetime] = useState(false);

  function hideAll() {
    setShow7day(false);
    setShow30day(false);
    setShow90day(false);
    setShowLifetime(false);
  }

  function handleShow7DayClick() {
    hideAll();
    setShow7day(true);
  }

  function handleShow30DayClick() {
    hideAll();
    setShow30day(true);
  }

  function handleShow90DayClick() {
    hideAll();
    setShow90day(true);
  }

  function handleShowLifetimeClick() {
    hideAll();
    setShowLifetime(true);
  }

  return (
    <div className="w-full bg-white dark:bg-gray-800 group">
      <div className="hover-trigger bg-white dark:bg-gray-800 p-6 z-30 hover:z-50 relative">
        <div className="mini-widget-title">
          {show7day && `7-DAY ${title}`}
          {show30day && `30-DAY ${title}`}
          {show90day && `90-DAY ${title}`}
          {showLifetime && `LIFETIME ${title}`}
        </div>
        <div className="dark:text-gray-300 mt-2">
          <span className="text-3xl font-semibold">
            {show7day && <StatComponent statValue={statValue7Day} />}
            {show30day && <StatComponent statValue={statValue30Day} />}
            {show90day && <StatComponent statValue={statValue90Day} />}
            {showLifetime && <StatComponent statValue={statValueLifetime} />}
          </span>
          <span className="font-normal ml-1">{statClassifier}</span>
        </div>
        <div
          className={`hover-target db-widget-popup w-96 ${popupCssClasses} pointer-events-none text-sm`}
        >
          <div>
            {<PopupComponent globalRetentionRate={globalRetentionRate} />}
          </div>
        </div>
        <div className="absolute bottom-0 right-0 text-xs font-black flex flex-col items-end mr-2 mb-2">
          <button
            id="sevenDayButton"
            className={`font-semibold py-px px-1 ${
              show7day ? "text-blue-neon" : "widget-toggle-text"
            }`}
            onClick={() => handleShow7DayClick()}
          >
            7d
          </button>
          <button
            id="thirtyDayButton"
            className={`font-semibold py-px px-1 ${
              show30day ? "text-blue-neon" : "widget-toggle-text"
            }`}
            onClick={() => handleShow30DayClick()}
          >
            30d
          </button>
          <button
            id="ninetyDayButton"
            className={`font-semibold py-px px-1 ${
              show90day ? "text-blue-neon" : "widget-toggle-text"
            }`}
            onClick={() => handleShow90DayClick()}
          >
            90d
          </button>
          <button
            id="lifetimeButton"
            className={`font-semibold py-px px-1 ${
              showLifetime ? "text-blue-neon" : "widget-toggle-text"
            }`}
            onClick={() => handleShowLifetimeClick()}
          >
            Life
          </button>
        </div>
      </div>
      <div className="hidden sm:block fixed top-0 left-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 dark:group-hover:bg-opacity-40 pointer-events-none w-screen h-screen z-40"></div>
    </div>
  );
}
