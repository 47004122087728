import React, { useState } from 'react';

function AttemptsWidgetStat({statValue}) {
  return (
    <div className="tracking-tight">
      {statValue}
    </div>
  );
}

export default AttemptsWidgetStat;
