import React, { useState } from "react";

export default function ShortcutEncouragement({
  toggleShortcutsReminder, // action of toggle
  shortcutsReminder, // state of toggle
  setRemindedAboutShortcuts, // call on button click (move to animation on load finished)
}) {
  const closeShortcutsReminder = () => {
    setRemindedAboutShortcuts(true);
  };

  return (
    <div className="flex flex-col bg-white shadow-2xl w-96 rounded-xl p-10 absolute top-0 mt-20 right-0 mr-20 text-sm text-gray-500 dark:bg-gray-700 dark:text-gray-300 isolate z-40">
      <div className="text-lg font-semibold text-gray-700 dark:text-white">
        Ditch that mouse. It's slow!
      </div>
      <div className="my-6">
        Keyboard users are more likely to achieve a happy (and efficient!) state
        of flow.
      </div>

      <div className="flex flex-col">
        <div className="flex items-center h-12">
          <div className="flex items-center h-full w-28">
            <div className="ss-hotkey-nocolor bg-yellow text-white">
              <i className="fa-solid fa-caret-left text-base"></i>
            </div>
            <div className="flex items-center justify-center text-gray-300 dark:text-gray-500 w-4">
              /
            </div>
            <div className="ss-hotkey-nocolor bg-green text-white text-sm">
              <i className="fa-solid fa-caret-right text-base"></i>
            </div>
            <div className="flex items-center justify-center text-gray-300 dark:text-gray-500 w-4">
              /
            </div>
            <div className="ss-hotkey-nocolor bg-blue text-white text-sm">
              <i className="fa-solid fa-caret-right text-base"></i>
            </div>
          </div>

          <div className="">
            <span className="font-bold">ARROW KEYS</span> to respond
          </div>
        </div>

        <div className="flex border-t dark:border-gray-600 items-center h-12">
          <div className="flex items-center h-full w-28">
            <div className="flex items-center justify-center w-14 h-3 bg-gray-200 dark:bg-gray-500 rounded-sm text-gray-700 dark:text-gray-300"></div>
          </div>
          <div className="">
            <span className="font-bold">SPACEBAR</span> for settings
          </div>
        </div>
      </div>
      <div
        id="close-shortcuts-popup-btn"
        className="button xbtn bg-blue-neon text-white hover:bg-opacity-90 active:bg-opacity-90 mt-6 mb-6 py-2 text-base"
        onClick={() => closeShortcutsReminder()}
      >
        Cool, thanks!
      </div>
      <div
        className={`ss-toggle ${shortcutsReminder ? "toggle-on" : ""}`}
        onClick={() => toggleShortcutsReminder()}
      >
        <div className="transition duration-500">
          <div className="transition duration-500"></div>
        </div>
        <span className="h-full ml-2 flex items-center text-xs text-gray-400">
          Keep reminding me over and over and over and over and over until I
          remember!
        </span>
      </div>
    </div>
  );
}
