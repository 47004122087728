import React from "react";

export default function ExportSettings({ personalLists }) {
  return (
    <>
      <div className="setting-title mt-4">Export All Learned Words</div>
      <p className="mt-2 no-border">
        Export all the words you've learned in Hack Chinese as a CSV (comma
        separated value) file. CSV files can be opened in programs like
        Microsoft Excel, Google Sheets, and Apple Numbers.
      </p>
      <p className="no-border">
        Students typically do this to share with their teachers and classmates,
        to perform their own analytics, or to import into other software
        programs.
      </p>
      <div className="flex flex-col w-full mt-4 pb-12 border-b-2 border-gray-100 dark:border-gray-600">
        <div className="w-full h-10  flex items-center">
          <a
            href="/all-studied-words.csv"
            className="flex items-center justify-center px-3 h-full rounded-xl border dark:border-gray-500 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
          >
            Export All Learned Words
            <i className="fa-solid fa-file-arrow-down ml-2"></i>
          </a>
        </div>
      </div>

      <div className="setting-title mt-12">
        Export Words from Specific Personal Lists
      </div>

      <div className="grid grid-cols-1 gap-px bg-gray-100 dark:bg-gray-500 w-full mt-2">
        {personalLists.map((list, i) => {
          return (
            <div
              key={`list-${i}`}
              className="w-full h-14  flex items-center bg-white dark:bg-gray-700 py-2"
            >
              <div>{list.name}</div>
              <a
                href={`/custom_focuses/${list.id}/export.csv`}
                className="flex items-center justify-center px-3 h-full rounded-xl border dark:border-gray-500 ml-auto cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                Export <i className="fa-solid fa-file-arrow-down ml-2"></i>
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
}
