import React, { useState } from "react";
import DashboardWidgetExpansionToggle from "./DashboardWidgetExpansionToggle";
import DashboardWidgetRangeSelector from "./DashboardWidgetRangeSelector";
import { API_ENDPOINT } from "./constants";

export default function FutureReviewsWidget({
  token,
  numReviewsDueNow,
  numReviewsDueLaterToday,
  futureReviews,
  userRange,
}) {
  const [range, setRange] = useState(userRange);
  const [expanded, setExpanded] = useState(false);

  function saveSettings(option, choice) {
    const data = {
      option: option,
      choice: choice,
    };

    fetch(`${API_ENDPOINT}/set_study_options`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    })
      // .then((response) => response.json())
      .then((response) => {
        // console.log(`response from server: ${JSON.stringify(response)}`);
      });
  }

  function setAndSaveRange(newRange) {
    setRange(newRange);
    saveSettings("review_schedule_range", newRange);
  }

  function classOfDayItem(i) {
    if (i < 29) {
      return "30";
    } else if (i < 59) {
      return "60";
    } else if (i < 89) {
      return "90";
    } else if (i < 179) {
      return "180";
    } else {
      return "365";
    }
  }

  return (
    <>
      <div className="flex justify-between relative pb-10">
        <div className="text-xl font-bold text-gray-600 dark:text-gray-300 tracking-wide">
          Review Schedule
        </div>
        <div className="flex h-8 text-xs relative ml-auto gap-4">
          <DashboardWidgetExpansionToggle
            expanded={expanded}
            setExpanded={setExpanded}
          />

          <DashboardWidgetRangeSelector
            range={range}
            setAndSaveRange={setAndSaveRange}
          />
        </div>
      </div>
      <div className="flex flex-col w-full pt-3 lg:py-3">
        <div
          className={`grid dynamic-range-${range} justify-end transition-all ${
            expanded ? "pht-expanded" : "h-32"
          } w-full transition-all text-gray-400 dark:text-gray-500 gap-px border-b-2 border-gray-200 dark:border-gray-600`}
        >
          <div className="relative group cursor-pointer flex flex-col justify-end h-full">
            <div
              className="relative flex items-center w-full bg-gray-200 dark:bg-gray-600 group-hover:bg-gray-200 dark:group-hover:bg-gray-500"
              style={{
                height: `${(numReviewsDueNow / futureReviews[range]) * 100}%`,
              }}
            >
              <div
                className={`absolute -top-[26px] w-[70px] left-[50%] -translate-x-1/2 text-xs flex justify-center items-center text-gray-500 dark:text-gray-200 ${
                  numReviewsDueNow == 0 ? "hidden" : ""
                }`}
              >
                <span className="bg-gray-200 dark:bg-gray-600 p-0.5 mr-1 z-70">
                  {numReviewsDueNow}
                </span>
                {numReviewsDueLaterToday > 0 && (
                  <span>+ {numReviewsDueLaterToday}</span>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center justify-center absolute bottom-0 translate-y-full w-full text-xs flex justify-center z-30 text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200">
              <div className="label-weekday pt-2">Today</div>
            </div>
            <div
              className="w-full bg-gray-300 group-hover:bg-gray-300"
              style={{
                height: `${
                  (numReviewsDueLaterToday / futureReviews[range]) * 100
                }%`,
              }}
            ></div>
          </div>

          {futureReviews["days"].map((day, i) => {
            return (
              <div
                key={`fr-${i}`}
                className={`day-group hover:bg-gray-50 dark:hover:bg-gray-700 relative group cursor-pointer flex day-group-${classOfDayItem(
                  i
                )} items-end h-full`}
              >
                <div
                  className={`relative w-full bg-gray-300 group-hover:text-gray-700 dark:bg-gray-600 group-hover:bg-gray-300 dark:group-hover:bg-gray-500 dark:group-hover:text-gray-400`}
                  style={{
                    height: `${(day["reviews"] / futureReviews[range]) * 100}%`,
                  }}
                >
                  <div
                    className={`absolute -top-[22px] w-full text-xs flex justify-center z-10 ${
                      day["reviews"] == 0 ? "hidden" : "day-value-label"
                    }`}
                  >
                    {day["reviews"]}
                  </div>

                  <div className="flex flex-col items-center justify-center absolute bottom-0 translate-y-full w-full text-xs z-10 text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200">
                    <div className="label-day">{day["date-d"]}</div>
                    <div className="label-weekday">{day["date-wd"]}</div>
                    {day["date-d"] === "1" && (
                      <div className="label-month border-l-2 dark:border-gray-600 pl-1 text-gray-400">
                        {day["date-m"]}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
