import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap";

export default function ReviewsScreen({
  activeScreen,
  setActiveScreen,
  automatic,
  setAutomatic,
  playing,
  setPlaying,
  numMemoriesRefreshed,
  numEarlyReviewsCompleted,
  numWordsLearned,
  numAssumptionsChecked,
  seenReviewsScreen,
  seenAssumedScreen,
  seenNewScreen,
  exitPath,
}) {
  const headerBarRef = useRef();
  const reviewsButtonProgressRef = useRef();
  const assumedButtonProgressRef = useRef();
  const newButtonProgressRef = useRef();
  const reviewsSlider = useRef();
  const assumedSlider = useRef();
  const newSlider = useRef();
  const tl = useRef();

  useLayoutEffect(() => {
    tl.current = gsap.timeline({}).from(headerBarRef.current, {
      x: 200,
      opacity: 0,
      duration: 1,
      ease: "back",
    });
  }, []);

  useEffect(() => {
    let screenAfterReviews = "assumed";
    let screenAfterAssumed = "new";

    if (numAssumptionsChecked < 1) {
      if (numWordsLearned > 1) {
        screenAfterReviews = "new";
      } else {
        screenAfterReviews = "reviews";
      }
    }

    if (numWordsLearned < 1) {
      screenAfterAssumed = "assumed";
    }

    reviewsSlider.current = gsap
      .timeline({
        paused: !(activeScreen === "reviews" && automatic),
        onComplete: setActiveScreen,
        onCompleteParams: [screenAfterReviews],
      })
      .to(reviewsButtonProgressRef.current, {
        width: "100%",
        ease: "none",
        duration: 12,
      });

    assumedSlider.current = gsap
      .timeline({
        paused: !(activeScreen === "assumed" && automatic),
        onComplete: setActiveScreen,
        onCompleteParams: [screenAfterAssumed],
      })
      .to(assumedButtonProgressRef.current, {
        width: "100%",
        ease: "none",
        duration: 12,
      });

    newSlider.current = gsap
      .timeline({
        paused: !(activeScreen === "new" && automatic),
      })
      .to(newButtonProgressRef.current, {
        width: "100%",
        ease: "none",
        duration: 12,
      });
  }, [activeScreen]);

  function handleScreenButtonClick(screen) {
    if (activeScreen === screen) {
      if (activeScreen === "reviews") {
        if (playing) {
          reviewsSlider.current.pause();
          setPlaying(false);
        } else {
          reviewsSlider.current.play();
          setPlaying(true);
        }
      }
      if (activeScreen === "assumed") {
        if (playing) {
          assumedSlider.current.pause();
          setPlaying(false);
        } else {
          assumedSlider.current.play();
          setPlaying(true);
        }
      }

      if (activeScreen === "new") {
        if (playing) {
          newSlider.current.pause();
          setPlaying(false);
        } else {
          newSlider.current.play();
          setPlaying(true);
        }
      }
    } else {
      reviewsSlider.current.pause();
      reviewsButtonProgressRef.current.style = { width: "" };
      assumedSlider.current.pause();
      newSlider.current.pause();

      if (activeScreen === "assumed") {
        assumedButtonProgressRef.current.style = { width: "" };
      }
      if (activeScreen === "new") {
        newButtonProgressRef.current.style = { width: "" };
      }

      setActiveScreen(screen);
      setAutomatic(false);
    }
  }

  return (
    <div
      className={`bg-${activeScreen} fixed flex top-0 left-0 items-center w-full transition-all duration-300 ease-in-out text-white h-20 z-50 select-none`}
      ref={headerBarRef}
    >
      <div className="flex items-center h-full w-full max-w-screen-lg pl-3 sm:pr-3 mx-auto">
        <div className="font-bold text-sm xxs:text-base sm:text-lg lg:text-2xl leading-tight antialiased w-[140px] sm:w-auto">
          {activeScreen === "reviews" && <div>WEAK MEMORIES REFRESHED</div>}
          {activeScreen === "assumed" && <div>ASSUMPTIONS CHECKED</div>}
          {activeScreen === "new" && <div>NEW WORDS LEARNED</div>}
        </div>

        <div
          className={`flex gap-px h-full ml-auto font-bold border-l border-r border-white border-opacity-30 cursor-pointer text-sm sm:text-lg`}
        >
          <div
            className={`${
              numMemoriesRefreshed < 1 && numEarlyReviewsCompleted < 1
                ? "hidden"
                : ""
            } relative flex w-[60px] xxs:w-[75px] sm:w-[100px] items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white`}
            onClick={() => handleScreenButtonClick("reviews")}
          >
            {" "}
            <div className="opacity-0">Weak</div>
            <div className="absolute flex justify-center items-center top-0 left-0 w-full h-full z-50">
              Weak
            </div>
            <div
              className={`absolute top-0 left-0 h-full bg-white bg-opacity-10 ${
                !automatic && seenReviewsScreen ? "w-full" : "w-0"
              }`}
              ref={reviewsButtonProgressRef}
            ></div>
          </div>
          <div
            className={`${
              numAssumptionsChecked < 1 ? "hidden" : ""
            } relative flex w-[75px] xxs:w-[100px] items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white`}
            onClick={() => handleScreenButtonClick("assumed")}
          >
            {" "}
            <div className="opacity-0">Assumed</div>
            <div className="absolute flex justify-center items-center top-0 left-0 w-full h-full z-50">
              Assumed
            </div>
            <div
              className={`absolute top-0 left-0 h-full bg-white bg-opacity-10 ${
                !automatic && seenAssumedScreen ? "w-full" : "w-0"
              }`}
              ref={assumedButtonProgressRef}
            ></div>
          </div>
          <div
            className={`${
              numWordsLearned < 1 ? "hidden" : ""
            } relative flex w-[60px] xxs:w-[75px] sm:w-[100px] items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-20 text-white`}
            onClick={() => handleScreenButtonClick("new")}
          >
            {" "}
            <div className="opacity-0">New</div>
            <div className="absolute flex justify-center items-center top-0 left-0 w-full h-full z-50">
              New
            </div>
            <div
              className={`absolute top-0 left-0 h-full bg-white bg-opacity-10 ${
                !automatic && seenNewScreen ? "w-full" : "w-0"
              }`}
              ref={newButtonProgressRef}
            ></div>
          </div>

          <a
            href={exitPath}
            className="relative hidden xs:flex w-[60px] sm:w-[100px] items-center w-full h-full justify-center bg-white bg-opacity-[20%] hover:bg-opacity-30 text-white"
          >
            <div className="absolute flex flex-col justify-center items-center top-0 left-0 w-full h-full z-50 text-sm">
              <i className="fa-solid fa-person-to-door"></i>
              <div className="mt-2">Exit</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
