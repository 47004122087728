let context = null;
let source = null;
let audioBuffer = null;

const clearAudioFromLocalStorage = function () {
  var arr = [];
  for (var i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(0, 6) == "audio-") {
      arr.push(localStorage.key(i));
    }
  }

  for (var i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i]);
  }
};

// Converts an ArrayBuffer to base64, by converting to string
// and then using window.btoa' to base64.
const bufferToBase64 = function (buffer) {
  const bytes = new Uint8Array(buffer);
  const len = buffer.byteLength;
  let binary = "";
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
const base64ToBuffer = function (buffer) {
  const binary = window.atob(buffer);
  const bufferA = new ArrayBuffer(binary.length);
  const bytes = new Uint8Array(bufferA);
  for (let i = 0; i < bufferA.byteLength; i++) {
    bytes[i] = binary.charCodeAt(i) & 0xff;
  }
  return bufferA;
};

function stopSound() {
  if (source) {
    source.stop(0);
  }
}

async function playSound(id) {
  const base64String = localStorage.getItem(`audio-${id}`);
  var snd = Sound(`data:audio/mp3;base64,${base64String}`);
}

function encodeAudio(arrayBuffer, id) {
  const base64String = bufferToBase64(arrayBuffer);
  localStorage.setItem(`audio-${id}`, base64String);
}

async function initSound(id) {
  const base64String = localStorage.getItem(`audio-${id}`);
  const audioFromString = base64ToBuffer(base64String);

  if (base64String === null) {
    return true;
  }

  context = new AudioContext();

  const result = await context.decodeAudioData(
    audioFromString,
    function (buffer) {
      return buffer;
    },
    function (e) {
      console.log("Error decoding file", e);
      return false;
    }
  );
  return result;
}

async function loadSoundFile(url, id) {
  if (url != null) {
    return await fetch(url)
      .then(async (response) => {
        encodeAudio(await response.arrayBuffer(), id);
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}

export {
  loadSoundFile,
  playSound,
  stopSound,
  base64ToBuffer,
  clearAudioFromLocalStorage,
};
