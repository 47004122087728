import React, { useState } from 'react';

function StudySessionsWidgetStat({statValue}) {

  return (
    <div>
      <span className="tracking-tight">{statValue}</span>
    </div>
  );
}

export default StudySessionsWidgetStat;