import React, { useState } from "react";
import { API_ENDPOINT } from "./constants";

export default function FileUploadSingle({ token, focusId }) {
  // IMPORTANT:
  // April 6: This file is NOT currently used, as we decided to implement importing words without uploading a file.
  // -------
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    // upload  file to controller: "focuses", action: "import_words"

    // 👇 Uploading the file using the fetch API to the server

    console.log(`file: ${file}`);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", focusId);

    fetch(`${API_ENDPOINT}/focuses/import_words`, {
      method: "POST",
      body: formData,
      // 👇 Set headers manually for single file upload
      headers: {
        "X-CSRF-Token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />

      <div>{file && `${file.name} - ${file.type}`}</div>

      <button onClick={handleUploadClick}>Upload</button>
    </div>
  );
}
