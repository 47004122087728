import React, { useState, useEffect } from "react";

export default function FontSettings({
  characterSet,
  setCharacterSet,
  toneMarks,
  setToneMarks,
  coloredCharacters,
  setColoredCharacters,
  coloredPinyin,
  setColoredPinyin,
}) {
  return (
    <>
      <div className="mt-14 font-bold">Font for Simplified Characters</div>
      <div className="flex">
        <div className="grid grid-cols-1 gap-2 mt-1 text-3xl text-gray-500">
          <div className="flex flex-col rounded-xl py-2 px-4 bg-blue-neon text-white">
            <div className="text-xs text-white text-opacity-80">
              Device Default
            </div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">Device Default Cursive</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">Noto Sans</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">Noto Serif</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>

          <div className="font-toggle">
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="simplified mt-1">洗手间门口有很多人在排队。</div>
          </div>
          <div>
            <div className="text-xs text-gray-400">ZCOOL XiaoWei</div>
            <div className="font-toggle mt-1">
              <div className="simplified">洗手间门口有很多人在排队。</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-2">
        <div className="font-toggle">Device Default Cursive</div>
        <div className="font-toggle">Device Default</div>
        <div className="font-toggle">Noto Sans</div>
        <div className="font-toggle">Noto Serif</div>
        <div className="font-toggle">ZCOOL Xiaowei</div>
      </div>
      noto sans noto serif ZCOOL XiaoWei ZCOOL Qingke Huangyou Ma Shan Zheng
      ZCOOL Kuaile Zhi Mang Xing Liu Jian Mao Cao Long Cang
      <div className="mt-8 font-bold">Character Set</div>
      <div className="flex mt-1">
        <div className="grid grid-cols-3 gap-0 text-gray-400 font-semibold">
          <div
            className={`multi-toggle ${
              characterSet === "simplified" ? "selected" : ""
            }`}
            onClick={() => setCharacterSet("simplified")}
          >
            Device Default
          </div>
          <div
            className={`multi-toggle ${
              characterSet === "traditional" ? "selected" : ""
            }`}
            onClick={() => setCharacterSet("traditional")}
          >
            Device Default Cursive
          </div>
          <div
            className={`multi-toggle ${
              characterSet === "both" ? "selected" : ""
            }`}
            onClick={() => setCharacterSet("both")}
          >
            ZCOOL Xiaowei
          </div>
        </div>
      </div>
      <p className="mt-3 text-xs font-gray-600 w-full max-w-prose">
        Mainland China uses simplified characters. Taiwan and Hong Kong use
        traditional characters. Most people study simplified characters unless
        they plan to live in Taiwan or Hong Kong.
      </p>
    </>
  );
}
