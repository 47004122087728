import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

export default function CharacterAndFontSettings({
  removeClassByPrefix,
  saveSettings,
  characterSet,
  setCharacterSet,
  toneMarks,
  simplifiedFont,
  setSimplifiedFont,
  traditionalFont,
  setTraditionalFont,
}) {
  let bodyNode = document.getElementsByTagName("body")[0];

  function handleSetCharacterSet(newCharacterSet) {
    setCharacterSet(newCharacterSet);
    saveSettings("character_set", newCharacterSet);
  }

  function handleSetSimplifiedFont(font) {
    setSimplifiedFont(font);
    saveSettings("simplified_font", font);
    removeClassByPrefix(bodyNode, "font-simplified-");
    bodyNode.classList.add(`font-simplified-${font}`);
  }

  function handleSetTraditionalFont(font) {
    setTraditionalFont(font);
    saveSettings("traditional_font", font);

    removeClassByPrefix(bodyNode, "font-traditional-");
    bodyNode.classList.add(`font-traditional-${font}`);
  }

  return (
    <>
      <div className="relative flex items-end h-[100px]">
        <div className="absolute flex text-center items-start">
          <div className="grid grid-cols-6 gap-1">
            {characterSet !== "traditional" && (
              <>
                <div
                  className={`characters simplified font-simplified-${simplifiedFont} text-5xl t2`}
                >
                  老
                </div>
                <div
                  className={`characters simplified font-simplified-${simplifiedFont} text-5xl t3`}
                >
                  板
                </div>

                <div></div>
                <div
                  className={`characters simplified font-simplified-${simplifiedFont} text-5xl ${
                    toneMarks === "original" ? "t4" : "t2"
                  }`}
                >
                  不
                </div>
                <div
                  className={`characters simplified font-simplified-${simplifiedFont} text-5xl t4`}
                >
                  客
                </div>
                <div
                  className={`characters simplified font-simplified-${simplifiedFont} text-5xl t4`}
                >
                  气
                </div>
              </>
            )}
            {characterSet !== "simplified" && (
              <>
                <div
                  className={`characters traditional font-traditional-${traditionalFont} text-5xl t2`}
                >
                  老
                </div>
                <div
                  className={`characters traditional font-traditional-${traditionalFont} text-5xl t3`}
                >
                  闆
                </div>

                <div></div>
                <div
                  className={`characters traditional font-traditional-${traditionalFont} text-5xl ${
                    toneMarks === "original" ? "t4" : "t2"
                  }`}
                >
                  不
                </div>

                <div
                  className={`characters traditional font-traditional-${traditionalFont} text-5xl t4`}
                >
                  客
                </div>
                <div
                  className={`characters traditional font-traditional-${traditionalFont} text-5xl t4`}
                >
                  氣
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="setting-title mt-6">Character Set</div>
      <div className="flex mt-2">
        <div className="grid grid-cols-3 gap-0 text-gray-400 font-semibold">
          <div
            className={`multi-toggle ${
              characterSet === "simplified" ? "selected" : ""
            }`}
            onClick={() => handleSetCharacterSet("simplified")}
          >
            Simplified
          </div>
          <div
            className={`multi-toggle ${
              characterSet === "traditional" ? "selected" : ""
            }`}
            onClick={() => handleSetCharacterSet("traditional")}
          >
            Traditional
          </div>
          <div
            className={`multi-toggle ${
              characterSet === "both" ? "selected" : ""
            }`}
            onClick={() => handleSetCharacterSet("both")}
          >
            Both
          </div>
        </div>
      </div>
      <p>
        Mainland China (1.4B people) uses simplified characters. Taiwan (24M
        people) and Hong Kong (8M people) use traditional characters. Most
        people study simplified characters unless they plan to live in Taiwan or
        Hong Kong.
      </p>

      {characterSet != "traditional" && (
        <>
          <div className="setting-title mt-12">
            Font for Simplified Characters
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full mt-2">
            <div
              className={`font-select-item ${
                simplifiedFont === "cursive" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("cursive")}
            >
              <div className="label">Device Default (Cursive)</div>
              <div className="force-cursive">我看得懂汉字</div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("")}
            >
              <div className="label">Device Default</div>
              <div className="">我看得懂汉字</div>
            </div>

            <div
              className={`font-select-item ${
                simplifiedFont === "noto-sans" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("noto-sans")}
            >
              <div className="label">Noto Sans</div>
              <div className="simplified font-simplified-noto-sans">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "noto-serif" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("noto-serif")}
            >
              <div className="label">Noto Serif</div>
              <div className="simplified font-simplified-noto-serif">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "zcool-xiaowei" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("zcool-xiaowei")}
            >
              <div className="label">ZCOOL XiaoWei</div>
              <div className="simplified font-simplified-zcool-xiaowei">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "zcool-qingke-huangyou" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("zcool-qingke-huangyou")}
            >
              <div className="label">ZCOOL Qingke Huangyou</div>
              <div className="simplified font-simplified-zcool-qingke-huangyou">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "ma-shan-zheng" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("ma-shan-zheng")}
            >
              <div className="label">Ma Shan Zheng</div>
              <div className="simplified font-simplified-ma-shan-zheng">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "zcool-kuaile" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("zcool-kuaile")}
            >
              <div className="label">ZCOOL Kuaile</div>
              <div className="simplified font-simplified-zcool-kuaile">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "zhi-mang-xing" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("zhi-mang-xing")}
            >
              <div className="label">Zhi Mang Xing</div>
              <div className="simplified font-simplified-zhi-mang-xing">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`hidden xfont-select-item ${
                simplifiedFont === "liu-jian-mao-cao" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("liu-jian-mao-cao")}
            >
              <div className="label">Liu Jian Mao</div>
              <div className="simplified font-simplified-liu-jian-mao-cao">
                我看得懂汉字
              </div>
            </div>
            <div
              className={`font-select-item ${
                simplifiedFont === "long-cang" ? "selected" : ""
              }`}
              onClick={() => handleSetSimplifiedFont("long-cang")}
            >
              <div className="label">Long Cang</div>
              <div className="simplified font-simplified-long-cang">
                我看得懂汉字
              </div>
            </div>
          </div>
        </>
      )}
      {characterSet != "simplified" && (
        <>
          <div className="setting-title mt-12">
            Font for Traditional Characters
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full mt-2">
            <div
              className={`font-select-item ${
                traditionalFont === "cursive" ? "selected" : ""
              }`}
              onClick={() => handleSetTraditionalFont("cursive")}
            >
              <div className="label">Device Default (Cursive)</div>
              <div className="traditional force-cursive">我看得懂漢字</div>
            </div>
            <div
              className={`font-select-item ${
                traditionalFont === "" ? "selected" : ""
              }`}
              onClick={() => handleSetTraditionalFont("")}
            >
              <div className="label">Device Default</div>
              <div className="">我看得懂漢字</div>
            </div>

            <div
              className={`font-select-item ${
                traditionalFont === "noto-sans" ? "selected" : ""
              }`}
              onClick={() => handleSetTraditionalFont("noto-sans")}
            >
              <div className="label">Noto Sans</div>
              <div className="traditional font-traditional-noto-sans">
                我看得懂漢字
              </div>
            </div>
            <div
              className={`font-select-item ${
                traditionalFont === "noto-serif" ? "selected" : ""
              }`}
              onClick={() => handleSetTraditionalFont("noto-serif")}
            >
              <div className="label">Noto Serif</div>
              <div className="traditional font-traditional-noto-serif">
                我看得懂漢字
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
