import React, { useState, useEffect, useRef } from "react";

export default function DashboardWidgetRangeSelector({
  range,
  setAndSaveRange,
}) {
  const [showRanges, setShowRanges] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showRanges && ref.current && !ref.current.contains(e.target)) {
        // setShowMoreActions(false);
        toggleShowRanges();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showRanges]);

  function handleSetRange(days) {
    setAndSaveRange(days);
    setShowRanges(false);
  }

  function toggleShowRanges() {
    setShowRanges(!showRanges);
  }

  return (
    <div
      ref={ref}
      className="hidden sm:flex select-none relative items-center bg-white sm:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:active:bg-gray-500 h-full rounded-xl px-3 w-[100px] justify-between cursor-pointer hover:bg-gray-200 active:bg-gray-300"
      onClick={() => toggleShowRanges()}
    >
      <div className="flex items-center justify-between w-full h-full">
        {" "}
        <span className="font-bold">
          <span className="sm:hidden">30</span>
          <span className="hidden sm:inline">{range}</span> days
        </span>
        <i className="fa-solid fa-angle-down text-gray-400"></i>
      </div>

      <div
        className={`${
          showRanges ? "flex" : "hidden"
        } flex-col w-[100px] bg-gray-100 dark:bg-gray-700 rounded-xl absolute top-0 right-0 z-40`}
      >
        <div className="border-b-2 dark:border-gray-600 flex items-center h-8 w-full px-3 rounded-t-xl justify-between">
          <span>Range:</span>
          <i className="fa-solid fa-angle-down text-gray-400 relative top-px"></i>
        </div>
        <div
          className="hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer flex items-center h-8 w-full px-3"
          onClick={() => handleSetRange(30)}
        >
          <span className={`${range === 30 ? "font-bold" : ""}`}>30 days</span>
        </div>
        <div
          className="hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer flex items-center h-8 w-full px-3"
          onClick={() => handleSetRange(60)}
        >
          <span className={`${range === 60 ? "font-bold" : ""}`}>60 days</span>
        </div>
        <div
          className="hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer flex items-center h-8 w-full px-3"
          onClick={() => handleSetRange(90)}
        >
          <span className={`${range === 90 ? "font-bold" : ""}`}>90 days</span>
        </div>
        <div
          className="hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer flex items-center h-8 w-full px-3"
          onClick={() => handleSetRange(180)}
        >
          <span className={`${range === 180 ? "font-bold" : ""}`}>
            180 days
          </span>
        </div>
        <div
          className="hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer flex items-center h-8 w-full px-3 rounded-b-xl"
          onClick={() => handleSetRange(365)}
        >
          <span className={`${range === 365 ? "font-bold" : ""}`}>
            365 days
          </span>
        </div>
      </div>
    </div>
  );
}
