import React, { useState, useEffect } from "react";

export default function StudySessionSizeAdjustments({
  increaseCharacterSize,
  decreaseCharacterSize,
  increasePinyinSize,
  decreasePinyinSize,
  increaseEnglishSize,
  decreaseEnglishSize,
  increaseSentenceCharacterSize,
  decreaseSentenceCharacterSize,
  increaseSentenceEnglishSize,
  decreaseSentenceEnglishSize,
  resetFontSizes,
}) {
  return (
    <div className="grow md:static bottom-0 flex flex-col w-full pb-12 md:pb-6 pt-4 border-t-2 border-gray-100 dark:border-gray-600 transition-all duration-500 ease-out bg-white dark:bg-gray-700">
      <div className="w-full flex justify-between pl-8 pr-8 md:pr-[72px] text-sm text-gray-500 dark:text-gray-300 mb-2 font-medium">
        <span>Size Adjustments</span>
        <div
          className="relative -top-px flex justify-center items-center bg-gray-200 hover:bg-gray-300 dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-gray-300 rounded-xl text-xs py-1 px-2 cursor-pointer"
          onClick={() => resetFontSizes()}
        >
          Reset
        </div>
      </div>
      <div className="w-full pl-5 pr-5 md:pr-[72px] grid grid-cols-5 gap-2 text-gray-500 dark:text-gray-300">
        <div className="flex flex-col text-2xl rounded-xl items-center">
          <div
            className="size-adjustment-arrow"
            onClick={() => increaseCharacterSize()}
          >
            <i className="fa-solid fa-up"></i>
          </div>
          <div className="flex items-center justify-center h-10 w-full text-center text-xl">
            汉子
          </div>
          <div
            className="size-adjustment-arrow"
            onClick={() => decreaseCharacterSize()}
          >
            <i className="fa-solid fa-down"></i>
          </div>
        </div>

        <div className="flex flex-col text-2xl rounded-xl items-center">
          <div
            className="size-adjustment-arrow"
            onClick={() => increasePinyinSize()}
          >
            <i className="fa-solid fa-up"></i>
          </div>
          <div className="flex items-center justify-center h-10 w-full text-center text-xl font-ubuntu-condensed">
            pīnyīn
          </div>
          <div
            className="size-adjustment-arrow"
            onClick={() => decreasePinyinSize()}
          >
            <i className="fa-solid fa-down"></i>
          </div>
        </div>

        <div className="flex flex-col text-2xl rounded-xl items-center">
          <div
            className="size-adjustment-arrow"
            onClick={() => increaseEnglishSize()}
          >
            <i className="fa-solid fa-up"></i>
          </div>
          <div className="flex items-center justify-center h-10 w-full text-center text-base">
            English
          </div>
          <div
            className="size-adjustment-arrow"
            onClick={() => decreaseEnglishSize()}
          >
            <i className="fa-solid fa-down"></i>
          </div>
        </div>
        <div className="relative col-span-2 grid grid-cols-2 gap-2 border-b-2 rounded-lg dark:border-gray-500">
          <div className="absolute flex items-center justify-center -bottom-[20px] left-0 w-full text-xs text-gray-400 font-medim">
            sentences
          </div>
          <div className="flex flex-col text-2xl rounded-xl items-center">
            <div
              className="size-adjustment-arrow"
              onClick={() => increaseSentenceCharacterSize()}
            >
              <i className="fa-solid fa-up"></i>
            </div>
            <div className="flex items-center justify-center h-10 w-full text-center text-sm">
              汉子
            </div>
            <div
              className="size-adjustment-arrow"
              onClick={() => decreaseSentenceCharacterSize()}
            >
              <i className="fa-solid fa-down"></i>
            </div>
          </div>

          <div className="flex flex-col text-2xl rounded-xl items-center">
            <div
              className="size-adjustment-arrow"
              onClick={() => increaseSentenceEnglishSize()}
            >
              <i className="fa-solid fa-up"></i>
            </div>
            <div className="flex items-center justify-center h-10 w-full text-center text-sm">
              English
            </div>
            <div
              className="size-adjustment-arrow"
              onClick={() => decreaseSentenceEnglishSize()}
            >
              <i className="fa-solid fa-down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
