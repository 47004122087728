import React, { useState, useEffect } from "react";

export default function ReviewModeSettings({}) {
  return (
    <div className="flex flex-col grow h-full bg-white px-20">
      <div className="pt-20 text-3xl font-bold">Review Modes</div>
      <div className="flex pt-20">
        <div className="flex bg-gray-200 rounded-full h-10 p-2px mt-20 space-x-2">
          <div className="flex items-center rounded-full px-6 bg-white shadow cursor-pointer">
            Male
          </div>
          <div className="flex items-center rounded-full px-6 cursor-pointer hover:bg-gray-100">
            Female
          </div>
          <div className="flex items-center rounded-full px-6 cursor-pointer hover:bg-gray-100">
            Robot
          </div>
        </div>
      </div>
    </div>
  );
}
