import React, { useState } from 'react';

function VocabSizeWidgetStat({statValue}) {

  return (
    <div>
      <span className="tracking-tight">{statValue}</span>
      <span className="text-base ml-1">words</span>
    </div>
  );
}

export default VocabSizeWidgetStat;