import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function StudySessionToggles({
  darkMode,
  toggleDarkMode,
  showAdvancedResponses,
  toggleShowAdvancedResponses,
  showReplayButtons,
  toggleShowReplayButtons,
  shortcutHints,
  toggleShortcutHints,
  autoCopy,
  toggleAutoCopy,
  autoPlay,
  toggleAutoPlay,
}) {
  return (
    <div className="flex border-b-2 border-gray-100 dark:border-gray-600 transition-all duration-500 ease-out flex-col w-full xpy-8 pt-[32px] pb-[28px] bg-white dark:bg-gray-700">
      <div className="w-full pl-8 pr-[72px] space-y-4">
        <div
          className={`ss-toggle ${darkMode ? "toggle-on" : ""}`}
          onClick={() => toggleDarkMode()}
        >
          <div className="transition duration-500">
            <div className="transition duration-500"></div>
          </div>
          <span className="h-full ml-3 flex items-center">Dark Mode</span>
        </div>

        <div
          className={`ss-toggle mobile ${shortcutHints ? "toggle-on" : ""}`}
          onClick={() => toggleShortcutHints()}
        >
          <div>
            <div></div>
          </div>
          <span className="h-full ml-3 flex items-center">Shortcut Legend</span>
        </div>

        <div
          className={`ss-toggle ${autoCopy ? "toggle-on" : ""}`}
          onClick={() => toggleAutoCopy()}
        >
          <div>
            <div></div>
          </div>
          <span className="h-full ml-3 flex items-center">
            Copy Words to Clipboard
          </span>
        </div>

        {/* <div className={`ss-toggle ${true ? "toggle-on" : ""}`}>
          <div>
            <div></div>
          </div>
          <span className="h-full ml-3 flex items-center">
            Performance Pixels
          </span>
        </div> */}

        <div className="relative dark:border-gray-500 border-l-2 pl-[7px] -left-[9px] -top-[2px] rounded-lg py-2px">
          <div
            className={`ss-toggle ${autoPlay ? "toggle-on" : ""}`}
            onClick={() => toggleAutoPlay()}
          >
            <div>
              <div></div>
            </div>
            <span className="h-full ml-3 flex items-center">Auto-Play</span>
          </div>

          <div
            className={`mt-4 ss-toggle mobile ${
              showReplayButtons ? "toggle-on" : ""
            }`}
            onClick={() => toggleShowReplayButtons()}
          >
            <div>
              <div></div>
            </div>
            <span className="h-full ml-3 flex items-center">Buttons</span>
          </div>

          <div className="absolute flex items-center justify-center top-0 -left-[20px] h-full text-xs text-gray-400">
            <i className="fa-solid fa-volume"></i>
          </div>
        </div>

        <div className={`hidden xss-toggle ${true ? "toggle-on" : ""}`}>
          <div>
            <div></div>
          </div>
          <span className="h-full ml-3 flex items-center">
            Performance Pixels
          </span>
        </div>
        <div
          className={`hidden xss-toggle ${
            showAdvancedResponses ? "toggle-on" : ""
          }`}
          onClick={() => toggleShowAdvancedResponses()}
        >
          <div>
            <div></div>
          </div>
          <span className="h-full ml-3 flex items-center">
            Advanced Responses
          </span>
        </div>
      </div>
    </div>
  );
}

StudySessionToggles.propTypes = {
  darkMode: PropTypes.bool,
  toggleDarkMode: PropTypes.func,
  showAdvancedResponses: PropTypes.bool,
  toggleShowAdvancedResponses: PropTypes.func,
  showReplayButtons: PropTypes.bool,
  toggleShowReplayButtons: PropTypes.func,
  shortcutHints: PropTypes.bool,
  toggleShortcutHints: PropTypes.func,
  autoCopy: PropTypes.bool,
  toggleAutoCopy: PropTypes.func,
  autoPlay: PropTypes.bool,
  toggleAutoPlay: PropTypes.func,
};
