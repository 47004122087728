import React, { useState } from "react";

function RetentionRateWidgetPopup({ globalRetentionRate }) {
  return (
    <div>
      <p className="text-base">
        Your{" "}
        <span className="font-semibold dark:text-gray-100">Retention Rate</span>{" "}
        measures how often you successfully recall the pronunciation and
        definition of a review word.
      </p>
      <p className="mt-3">
        The best way to increase your retention rate is to practice with the
        words you learn (i.e. reading, listening, and speaking).
      </p>
      <p className="mt-3">
        The second best way is to study with Hack Chinese before your memories
        are predicted to expire.
      </p>
      <p className="mt-3">
        The average retention rate across all students using Hack Chinese is
        currently {globalRetentionRate}%.
      </p>
      <p className="mt-3">
        Your retention rate statistics are updated once per day at midnight in
        your timezone.
      </p>
    </div>
  );
}

export default RetentionRateWidgetPopup;
