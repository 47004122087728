import React, { useState } from 'react';

export default function PageTitle({title}) {

  return (
    <div className="main-content">
      <h1 className="page-title">
       {title}
      </h1>
    </div>
  );
}
