import React, { useState, useEffect } from "react";

export default function FinickySettings({
  saveSettings,
  firstDestination,
  setFirstDestination,
  showSentences,
  setShowSentences,
  pinyinOnly,
  setPinyinOnly,
  shortcutReminders,
  setShortcutReminders,
}) {
  function togglePinyinOnly() {
    saveSettings("pinyin_only", !pinyinOnly);
    setPinyinOnly(!pinyinOnly);
  }

  function toggleShortcutReminders() {
    saveSettings("shortcuts_reminder", !shortcutReminders);
    setShortcutReminders(!shortcutReminders);
  }

  function toggleShowSentences() {
    saveSettings("show_sentences", !showSentences);
    setShowSentences(!showSentences);
  }

  function handleSetFirstDestination(destination) {
    saveSettings("landing", destination);
    setFirstDestination(destination);
  }

  return (
    <>
      <div className="setting-title mt-4">Destination After Login</div>
      <div className="flex mt-2">
        <div className="grid grid-cols-2 gap-0 text-gray-400 font-semibold">
          <div
            className={`multi-toggle ${
              firstDestination === "study" ? "selected" : ""
            }`}
            onClick={() => handleSetFirstDestination("study")}
          >
            Study
          </div>
          <div
            className={`hidden xmulti-toggle ${
              firstDestination === "list" ? "selected" : ""
            }`}
            onClick={() => handleSetFirstDestination("list")}
          >
            First List
          </div>
          <div
            className={`multi-toggle ${
              firstDestination === "dashboard" ? "selected" : ""
            }`}
            onClick={() => handleSetFirstDestination("dashboard")}
          >
            Dashboard
          </div>
        </div>
      </div>
      <p>
        The default is <span className="font-bold">Study</span> so you can make
        progress quickly.
      </p>

      <div
        className="flex items-center mt-12"
        onClick={() => toggleShowSentences()}
      >
        <div className={`two-way-toggle ${showSentences ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">Show Example Sentences</div>
      </div>
      <p>
        By default, we include a random example sentence from our database (if
        one exists) alongside each word to make remembering easier. Some
        students like to turn example sentences off, and focus on words in
        isolation.
      </p>
      <div
        className="flex items-center mt-12"
        onClick={() => togglePinyinOnly()}
      >
        <div className={`two-way-toggle ${pinyinOnly ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">
          Learn Pinyin Only (Skip learning characters)
        </div>
      </div>
      <p>
        Some beginner students choose to skip learning Chinese characters.
        Turning this option on means they will only be quizzed on their recall
        of the English definition from the (character + pinyin) combination.
      </p>
      <div
        className="flex items-center mt-12"
        onClick={() => toggleShortcutReminders()}
      >
        <div className={`two-way-toggle ${shortcutReminders ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">
          Remind Me to Use Keyboard Shortcuts (Desktop only)
        </div>
      </div>
    </>
  );
}
