import React, { useState, useEffect } from "react";
import DuChineseLogo from "../../assets/images/duchinese-logo.png";
import TCBLogo from "../../assets/images/tcb-brand-logo.png";
import DongChineseLogo from "../../assets/images/dong-chinese-logo.png";

export default function IntegrationSettings({}) {
  return (
    <>
      <div className="mt-14 text-2xl font-bold dark:text-white">
        Integrations
      </div>

      <div className="text-lg mt-6 dark:text-gray-300">
        These app-to-app integrations enable you add words from your favorite
        learning apps directly into your Lists in Hack Chinese.
      </div>
      <div className="grid grid-cols-1 gap-6 mt-14 integration-links">
        <a
          href="/sync-with-du-chinese"
          className="cursor-pointer group flex items-center justify-between h-28 w-full rounded-xl border-2 border-[#ca3123] p-6 bg-white"
        >
          <img src={DuChineseLogo} className="h-16" />
          <i className="fa-solid fa-chevron-right relative group-hover:left-[4px] left-0 transition-all text-3xl text-[#ca3123]"></i>
        </a>
        <a
          href="/sync-with-the-chairmans-bao"
          className="cursor-pointer group flex items-center justify-between h-28 w-full rounded-xl border-2 border-[#ca3123] p-6 bg-white"
        >
          <img src={TCBLogo} className="h-[60px]" />

          <i className="fa-solid fa-chevron-right relative group-hover:left-[4px] left-0 transition-all text-3xl text-[#ca3123]"></i>
        </a>
        <a
          href="/sync-with-dong-chinese"
          className="hidden cursor-pointer group flex items-center justify-between dong-chinese h-28 w-full rounded-xl border-2 border-[#ac0145] p-6"
        >
          <img src={DongChineseLogo} className="h-16 rounded-xl" />
          <i className="fa-solid fa-chevron-right relative group-hover:left-[4px] left-0 transition-all text-3xl text-[#ac0145]"></i>
        </a>
        <a
          href="/sync-with-maayot"
          className="cursor-pointer group flex items-center justify-between h-28 w-full rounded-xl border-2 border-[#088466] p-6 bg-white"
        ><div class="text-[#088466] text-5xl font-semibold">maayot</div>
          <i className="fa-solid fa-chevron-right relative group-hover:left-[4px] left-0 transition-all text-3xl text-[#088466]"></i>
        </a>
      </div>
    </>
  );
}
