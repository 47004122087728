import React, { useState } from "react";
import DashboardWidgetRangeSelector from "./DashboardWidgetRangeSelector";

export default function ReviewsDueWidget({
  numReviewsDueNow,
  numReviewsDueLaterToday,
}) {
  return (
    <div className="flex flex-col p-6 h-full">
      <div className="flex h-full text-gray-900 dark:text-gray-300">
        <div className="flex flex-col h-full justify-end">
          <div className="text-5xl">{numReviewsDueNow}</div>
          <div className="text-sm">Reviews Due</div>
        </div>

        <div className="w-[2px] h-full bg-gray-100 dark:bg-gray-700 mx-6"></div>

        <div className="flex flex-col h-full justify-end">
          <div className="flex items-end text-4xl h-12">
            {numReviewsDueLaterToday}
          </div>
          <div className="text-sm">Due Later Today</div>
        </div>
      </div>
    </div>
  );
}
