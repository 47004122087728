import React, { useRef, useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap";

export default function ShortcutLegend({ shortcutHints, settingsOpen }) {
  const tl = useRef();
  const legendRef = useRef();
  const q = gsap.utils.selector(legendRef);

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true }).from(q(".legend-row"), {
      x: 30,
      opacity: 0,
      stagger: 0.04,
      duration: 0.4,
    });
  }, []);

  useEffect(() => {
    if (shortcutHints) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [shortcutHints]);

  return (
    <div
      id="shortcut-legend"
      ref={legendRef}
      className={`  ${settingsOpen ? "md:pr-80" : ""}`}
    >
      <div className="relative hidden md:flex flex-col items-center transition-all duration-500">
        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">Respond</div>
          <div className="flex items-center justify-end h-full w-24">
            <div className="ss-hotkey-nocolor bg-yellow text-white">
              <i className="fa-solid fa-caret-left text-base"></i>
            </div>
            <div className="flex items-center justify-center text-gray-300 dark:text-gray-500 w-4">
              /
            </div>
            <div className="ss-hotkey-nocolor bg-green text-white text-sm">
              <i className="fa-solid fa-caret-right text-base"></i>
            </div>
            <div className="flex items-center justify-center text-gray-300 dark:text-gray-500 w-4">
              /
            </div>
            <div className="ss-hotkey-nocolor bg-blue text-white text-sm">
              <i className="fa-solid fa-caret-right text-base"></i>
            </div>
          </div>
        </div>

        <div className="hidden xlegend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">"I'm done studying it"</div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey-nocolor bg-blue">
              <i className="fa-solid fa-caret-right text-base relative text-white"></i>
            </div>
          </div>
        </div>

        <div className="legend-row flex w-full h-6 items-center justify-end mb-1">
          <div className="mr-2">
            Pause / Settings{" "}
            <span className="text-xs text-gray-500 dark:text-gray-400"></span>
          </div>
          <div className="flex items-center justify-end h-full w-16">
            <div className="flex items-center justify-center w-14 h-5 bg-gray-200 dark:bg-gray-500 rounded-sm text-gray-700 dark:text-gray-300">
              space
            </div>
          </div>
        </div>
        <div className="legend-row flex w-full h-2 mb-3 border-gray-200 dark:border-gray-600 items-center justify-end border-b"></div>

        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">Toggle Dark Mode</div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">D</div>
          </div>
        </div>

        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-[32px]">
            <i className="fa-solid fa-volume mr-2"></i>Word
          </div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">
              <i className="fa-solid fa-caret-up text-base"></i>
            </div>
          </div>
        </div>
        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">
            <i className="fa-solid fa-volume mr-2"></i>Sentence
          </div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">
              <i className="fa-solid fa-caret-down text-base"></i>
            </div>
          </div>
        </div>

        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">Add a Note</div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">A</div>
          </div>
        </div>

        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">Memorize</div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">M</div>
          </div>
        </div>
        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">Boost</div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">B</div>
          </div>
        </div>

        <div className="legend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">Block</div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">X</div>
          </div>
        </div>

        <div className="hidden xlegend-row flex w-full justify-end h-6 items-center mb-1">
          <div className="mr-2">Note</div>
          <div className="flex items-center justify-end h-full w-8">
            <div className="ss-hotkey">N</div>
          </div>
        </div>
      </div>
    </div>
  );
}
