import React, { useState, useEffect } from "react";
import { API_ENDPOINT } from "./constants";

export default function PendingWord({ token, pendingWord }) {
  const [simplified, setSimplified] = useState(pendingWord["simplified"]);
  const [traditional, setTraditional] = useState("");
  const [oPinyin, setOPinyin] = useState("");
  const [ePinyin, setEPinyin] = useState("");
  const [english, setEnglish] = useState("");
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [errors, setErrors] = useState("");
  const [displayErrors, setDisplayErrors] = useState(false);

  const [cssClasses, setCSSClasses] = useState("group-hover:bg-blue-brand/5");

  useEffect(() => {
    if (approved) {
      setCSSClasses("bg-green/50 duration-1000 transition");
    } else if (rejected) {
      setCSSClasses("bg-red/50 duration-1000 transition");
    }
  }, [approved, rejected]);

  function approve() {
    const data = {
      pending_word: {
        simplified: simplified,
        traditional: traditional,
        opinyin: oPinyin,
        epinyin: ePinyin,
        english: english,
      },
    };

    fetch(`${API_ENDPOINT}/pending_words/${pendingWord["id"]}/approve`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        setApproved(true);
      } else {
        setErrors(response.errors);
        setDisplayErrors(true);
      }
    });
  }

  function reject() {
    fetch(`${API_ENDPOINT}/pending_words/${pendingWord["id"]}/reject`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    });

    setRejected(true);
  }

  return (
    <>
      <div
        className={`group flex w-full relative ${
          approved || rejected ? "pointer-events-none" : ""
        }`}
      >
        <div
          className={`pointer-events-none absolute inset-0 bg-transparent ${cssClasses}`}
        ></div>
        <input
          className="w-48 flex items-center p-2 border-t-2 dark:border-gray-600 border-r-2 focus:bg-gray-100 dark:focus:bg-gray-700 focus:outline-none placeholder-gray-200 dark:bg-gray-800"
          value={simplified}
          onChange={(e) => setSimplified(e.target.value)}
        />
        <input
          value={traditional}
          onChange={(e) => setTraditional(e.target.value)}
          className="w-48 flex items-center p-2 border-t-2 dark:border-gray-600 border-r-2 focus:bg-gray-100 dark:focus:bg-gray-700 focus:outline-none placeholder-gray-200 dark:bg-gray-800"
        />
        <input
          value={oPinyin}
          onChange={(e) => setOPinyin(e.target.value.toLowerCase())}
          className="pinyin text-2xl w-48 flex items-center p-2 border-t-2 dark:border-gray-600 border-r-2 focus:bg-gray-100 dark:focus:bg-gray-700 focus:outline-none placeholder-gray-200 dark:bg-gray-800"
        />
        <input
          value={ePinyin}
          onChange={(e) => setEPinyin(e.target.value.toLowerCase())}
          className="pinyin text-2xl w-48 flex items-center p-2 border-t-2 dark:border-gray-600 border-r-2 focus:bg-gray-100 dark:focus:bg-gray-700 focus:outline-none placeholder-gray-200 dark:bg-gray-800"
        />
        <input
          value={english}
          onChange={(e) => setEnglish(e.target.value)}
          className="flex items-center p-2 border-t-2 dark:border-gray-600 border-r-2 focus:bg-gray-100 dark:focus:bg-gray-700 focus:outline-none placeholder-gray-200 dark:bg-gray-800 flex-grow"
        />
        <div className="flex items-center justify-center w-[118px] py-[2px] border-t-2 dark:border-t-gray-600">
          <div
            onClick={(__) => approve()}
            className="flex items-center justify-center px-4 h-full rounded-xl bg-green/20 cursor-pointer hover:bg-green/40 text-green"
          >
            <i className="fas fa-thumbs-up"></i>
          </div>
          <div
            onClick={(__) => reject()}
            className="flex items-center justify-center px-4 h-full rounded-xl bg-red/10 dark:bg-red/20 cursor-pointer hover:bg-red/30 text-red ml-[2px]"
          >
            <i className="fas fa-thumbs-down"></i>
          </div>
        </div>
      </div>
      {displayErrors && <div>{errors}</div>}
    </>
  );
}
