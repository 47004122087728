import React from "react";

export default function HistoricBrainRecordBar({
  date,
  numWordsBlocked,
  numLeeches,
  numWordsSuspended,
  numWordsWeak,
  numWordsStrong,
  numWordsStrongAllTypes,
  numWordsMemorized,
  numWordsMastered,
  numWordsAssumed,
  maxWords,
  numWordsKnown,
}) {
  return (
    <>
      <div className="group-hover:hidden w-px h-full absolute top-0 right-0 bg-white dark:bg-gray-800 day-separator"></div>
      <div className="absolute hidden group-hover:flex bottom-0 -mb-7 -ml-24 py-1 pl-4 pr-1 bg-white dark:bg-gray-800 justify-center text-center left-0 w-48 text-xs">
        {date}
      </div>
      <div className="absolute top-0">
        <div className="absolute hidden border-2 border-gray-100 dark:border-gray-700 rounded-xl shadow-sm group-hover:flex text-sm bottom-0 right-0 bg-white dark:bg-gray-800 w-34 p-4 -mr-4 flex-row items-center font-normal pointer-events-none z-20">
          <div className="flex text-xs flex-col w-full">
            {numWordsBlocked > 0 && (
              <div className="blocked-vocab-bar justify-end text-right text-black dark:bg-black dark:text-white">
                blocked{" "}
                <span className="text-right w-12">{numWordsBlocked}</span>
              </div>
            )}
            {numLeeches > 0 && (
              <div className="leeches-vocab-bar justify-end text-right text-black dark:bg-black dark:text-white">
                leeches <span className="text-right w-12">{numLeeches}</span>
              </div>
            )}
            {numLeeches > 0 && numWordsBlocked > 0 && (
              <div className="total-suspended-vocab-bar flex justify-end text-right font-bold border-t-2 border-gray-100 dark:border-gray-700 mt-1 pt-1 mb-4 dark:text-gray-300">
                total{" "}
                <span className="text-right w-12">{numWordsSuspended}</span>
              </div>
            )}
            {(numLeeches > 0 || numWordsBlocked > 0) && (
              <div className="w-full h-2"></div>
            )}
            {numWordsWeak > 0 && (
              <div className="flex justify-end text-right text-gray-400">
                weak <span className="text-right w-12">{numWordsWeak}</span>
              </div>
            )}
            {numWordsStrong > 0 && (
              <div className="strong-vocab-bar flex justify-end text-right text-green">
                strong <span className="text-right w-12">{numWordsStrong}</span>
              </div>
            )}
            {numWordsStrongAllTypes > 0 && (
              <div className="strong-vocab-bar-all-types flex justify-end text-right text-green">
                strong{" "}
                <span className="text-right w-12">
                  {numWordsStrongAllTypes}
                </span>
              </div>
            )}

            {numWordsMemorized > 0 && (
              <div className="memorized-vocab-bar justify-end text-right text-blue-brand">
                memorized{" "}
                <span className="text-right w-12">{numWordsMemorized}</span>
              </div>
            )}
            {numWordsMemorized > 0 && (
              <div className="memorized-vocab-bar justify-end text-right text-blue-brand">
                mastered{" "}
                <span className="text-right w-12">{numWordsMastered}</span>
              </div>
            )}
            {numWordsAssumed > 0 && (
              <div className="flex justify-end text-right text-purple">
                assumed{" "}
                <span className="text-right w-12">{numWordsAssumed}</span>
              </div>
            )}
            <div className="flex justify-end text-right font-bold border-t-2 border-gray-100 dark:border-gray-700 mt-1 pt-1 dark:text-gray-300">
              total <span className="text-right w-12">{numWordsKnown}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="blocked-vocab-bar bg-black w-full"
        style={{
          height: `${(numWordsBlocked / maxWords) * 100}%`,
        }}
      ></div>
      <div
        className="leeches-vocab-bar bg-black/50 w-full"
        style={{
          height: `${(numLeeches / maxWords) * 100}%`,
        }}
      ></div>
      <div
        className="bg-gray-200 dark:bg-gray-600 dark:group-hover:bg-gray-500 group-hover:bg-gray-100 w-full"
        style={{
          height: `${(numWordsWeak / maxWords) * 100}%`,
        }}
      ></div>
      <div
        className="strong-vocab-bar bg-green dark:group-hover:bg-green-light dark:group-hover:bg-opacity-100 group-hover:bg-opacity-70 w-full flex items-end text-white text-xs"
        style={{
          height: `${(numWordsStrong / maxWords) * 100}%`,
        }}
      ></div>
      <div
        className="strong-vocab-bar-all-types bg-green dark:group-hover:bg-green-light dark:group-hover:bg-opacity-100 group-hover:bg-opacity-70 w-full flex items-end text-white text-xs"
        style={{
          height: `${(numWordsStrongAllTypes / maxWords) * 100}%`,
        }}
      ></div>

      <div
        className="memorized-vocab-bar bg-blue-brand group-hover:bg-opacity-70 w-full"
        style={{
          height: `${(numWordsMemorized / maxWords) * 100}%`,
        }}
      ></div>
      <div
        className="mastered-vocab-bar bg-blue-brand group-hover:bg-opacity-70 w-full"
        style={{
          height: `${(numWordsMastered / maxWords) * 100}%`,
        }}
      ></div>
      <div
        className="bg-purple group-hover:bg-opacity-70 w-full"
        style={{
          height: `${(numWordsAssumed / maxWords) * 100}%`,
        }}
      ></div>
    </>
  );
}
