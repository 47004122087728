import React, { useState, useEffect } from "react";
import WordImportAmbiguousRow from "./WordImportAmbiguousRow";
import { API_ENDPOINT } from "./constants";

export default function WordImportAmbiguous({
  token,
  focusId,
  ambiguousWordsWithSuggestions,
  numAmbiguous,
  setAmbiguousComplete,
  advanceToNextScreen,
}) {
  const [chosenWordIds, setChosenWordIds] = useState({});

  useEffect(() => {
    let tempObject = {};

    Object.keys(ambiguousWordsWithSuggestions).map(function (aw, i) {
      tempObject[i] = ambiguousWordsWithSuggestions[aw][0].id;
    });

    setChosenWordIds(tempObject);
  }, []);

  function handleSetAmbiguousComplete() {
    sendChosenWordIds();
    setAmbiguousComplete(true);
    advanceToNextScreen();
  }

  function sendChosenWordIds() {
    const data = {
      word_ids: Object.values(chosenWordIds),
      focus_id: focusId,
    };

    fetch(`${API_ENDPOINT}/focuses/add_words_by_ids`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    });
  }

  return (
    <div className="flex flex-col">
      <div className="text-3xl mx-auto">Ambiguous</div>

      {numAmbiguous == 0 && (
        <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
          There was nothing ambiguous about the words you imported. Easy!
        </div>
      )}

      {numAmbiguous > 0 && (
        <>
          <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
            These words have more than one match in our dictionary. For each,
            we've selected the most common pronunciation & definition, but
            please confirm the selections below to finish the import.
          </div>
          <div className="flex flex-col flex-grow h-full mt-6 border-gray-100">
            {Object.keys(ambiguousWordsWithSuggestions).map(function (aw, i) {
              return (
                <WordImportAmbiguousRow
                  ambiguousWordsWithSuggestions={ambiguousWordsWithSuggestions}
                  aw={aw}
                  key={i}
                  setChosenWordIds={setChosenWordIds}
                  chosenWordIds={chosenWordIds}
                  rowNumber={i}
                />
              );
            })}
          </div>
          <div className="bg-gray-100 dark:bg-gray-700  w-full p-12 rounded-xl flex items-center justify-between">
            <div className="flex flex-col w-1/2">
              <div className="text-xl">
                After making your selections, click 'Confirm' to continue.
              </div>
            </div>
            <div
              onClick={() => handleSetAmbiguousComplete()}
              className="py-3 text-xl font-medium px-6 bg-blue-brand hover:bg-blue-brand/90 cursor-pointer text-white rounded-xl"
            >
              Confirm
              <i className="fa-regular fa-arrow-right-long ml-3"></i>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
