import React, { useState, useEffect } from "react";

export default function StudySessionLayoutOptions({ layout, setLayout }) {
  return (
    <div className="md:flex flex-col w-full pt-4 pb-8 relative bg-white dark:bg-gray-700">
      <div className="w-full pl-8 pr-[72px]">
        <div className="flex justify-between items-center w-full mb-4">
          <div className="text-sm text-gray-500 dark:text-gray-300">Layout</div>
        </div>
        <div className="grid grid-cols-3 gap-3 w-full">
          <div
            id="ss-layout-auto"
            className={`ss-layout-option hover-trigger flex ${
              layout === "auto" ? "selected" : ""
            }`}
            onClick={() => setLayout("auto")}
          >
            <svg
              className="stroke-current stroke-2 h-full layout-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 74.19 50.06"
            >
              <defs>
                <style></style>
              </defs>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    className="cls-1"
                    d="M24.85,42.62v4.59a2,2,0,0,1-2,2h-20a2,2,0,0,1-2-2v-36a2,2,0,0,1,2-2H16.36"
                  />
                  <line
                    className="cls-1"
                    x1="24.85"
                    y1="42.13"
                    x2="24.85"
                    y2="42.62"
                  />
                  <rect
                    className="cls-1"
                    x="5.35"
                    y="28.71"
                    width="6"
                    height="6"
                    rx="2"
                  />
                  <rect
                    className="cls-1"
                    x="5.35"
                    y="38.71"
                    width="6"
                    height="6"
                    rx="2"
                  />
                  <path
                    className="cls-1"
                    d="M16.36,9.21V2.85a2,2,0,0,1,2-2h53a2,2,0,0,1,2,2V39.57a2,2,0,0,1-2,2h-53a2,2,0,0,1-2-2V9.21"
                  />
                  <rect
                    className="cls-1"
                    x="47.04"
                    y="27.7"
                    width="14.25"
                    height="6.11"
                    rx="2"
                  />
                  <rect
                    className="cls-1"
                    x="28.04"
                    y="27.7"
                    width="14.25"
                    height="6.11"
                    rx="2"
                  />
                </g>
              </g>
            </svg>
            <div className="hover-target absolute top-0 w-full h-5 pl-8 pr-[72px] pt-px justify-end flex items-center mt-4 left-0 text-xs text-gray-500 dark:text-gray-400 pr-8">
              Auto (based on screen size)
            </div>
          </div>

          <div
            id="ss-layout-desktop"
            className={`ss-layout-option hover-trigger hidden md:flex ${
              layout === "desktop" ? "selected" : ""
            }`}
            onClick={() => setLayout("desktop")}
          >
            <svg
              className="stroke-current stroke-1 h-full layout-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 58.69 42.42"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <rect
                    className="cls-1"
                    x="0.85"
                    y="0.85"
                    width="56.99"
                    height="40.72"
                    rx="2"
                  />
                  <rect
                    className="cls-1"
                    x="31.54"
                    y="27.7"
                    width="14.25"
                    height="6.11"
                    rx="2"
                  />
                  <rect
                    className="cls-1"
                    x="12.54"
                    y="27.7"
                    width="14.25"
                    height="6.11"
                    rx="2"
                  />
                </g>
              </g>
            </svg>
            <div className="hover-target absolute top-0 w-full h-5 pl-8 pr-[72px] pt-px justify-end flex items-center mt-4 left-0 text-xs text-gray-500 dark:text-gray-400 pr-8">
              Great with desktops & laptops
            </div>
          </div>
          <div
            id="ss-layout-zen"
            className={`ss-layout-option hover-trigger hidden md:flex ${
              layout === "zen" ? "selected" : ""
            }`}
            onClick={() => setLayout("zen")}
          >
            <svg
              className="stroke-current stroke-1 h-full layout-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 58.69 42.42"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <rect
                    className="cls-1"
                    x="0.85"
                    y="0.85"
                    width="56.99"
                    height="40.72"
                    rx="2"
                  />
                </g>
              </g>
            </svg>
            <div className="hover-target absolute top-0 w-full h-5 pl-8 pr-[72px] pt-px justify-end flex items-center mt-4 left-0 text-xs text-gray-500 dark:text-gray-400 pr-8">
              Minimalist (requires hotkeys)
            </div>
          </div>
          <div
            id="ss-layout-tablet"
            className={`ss-layout-option hover-trigger hidden md:flex ${
              layout === "tablet" ? "selected" : ""
            }`}
            onClick={() => setLayout("tablet")}
          >
            <svg
              className="stroke-current stroke-1 h-full layout-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 58.69 42.42"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <rect
                    className="cls-1"
                    x="0.85"
                    y="0.85"
                    width="56.99"
                    height="40.72"
                    rx="2"
                  />
                  <rect
                    className="cls-1"
                    x="47.66"
                    y="22.76"
                    width="6"
                    height="14"
                    rx="2"
                  />
                  <rect
                    className="cls-1"
                    x="5.66"
                    y="22.76"
                    width="6"
                    height="14"
                    rx="2"
                  />
                </g>
              </g>
            </svg>
            <div className="hover-target absolute top-0 w-full h-5 pl-8 pr-[72px] pt-px justify-end flex items-center mt-4 left-0 text-xs text-gray-500 dark:text-gray-400 pr-8">
              Great with tablets
            </div>
          </div>

          <div className="h-12 col-span-2">
            <div className="grid grid-cols-3 gap-3 w-full">
              <div
                id="ss-layout-phone-lh"
                className={`ss-layout-option hover-trigger flex ${
                  layout === "phone-lh" ? "selected" : ""
                }`}
                onClick={() => setLayout("phone-lh")}
              >
                <svg
                  className="stroke-current h-full layout-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25.7 41.7"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <rect
                        className="cls-1"
                        x="0.85"
                        y="0.85"
                        width="24"
                        height="40"
                        rx="2"
                      />
                      <rect
                        className="cls-1"
                        x="5.35"
                        y="20.35"
                        width="6"
                        height="6"
                        rx="2"
                      />
                      <rect
                        className="cls-1"
                        x="5.35"
                        y="30.35"
                        width="6"
                        height="6"
                        rx="2"
                      />
                    </g>
                  </g>
                </svg>
                <div className="hover-target absolute top-0 w-full h-5 pl-8 pr-[72px] pt-px justify-end flex items-center mt-4 left-0 text-xs text-gray-500 dark:text-gray-400 pr-8">
                  Great with mobile devices
                </div>
              </div>
              <div
                id="ss-layout-phone-classic"
                className={`ss-layout-option hover-trigger flex ${
                  layout === "phone-classic" ? "selected" : ""
                }`}
                onClick={() => setLayout("phone-classic")}
              >
                <svg
                  className="stroke-current h-full layout-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25.7 41.7"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <rect
                        className="cls-1"
                        x="0.85"
                        y="0.85"
                        width="24"
                        height="40"
                        rx="2"
                      />
                      <rect
                        className="cls-1"
                        x="14.58"
                        y="30.35"
                        width="6"
                        height="6"
                        rx="2"
                      />
                      <rect
                        className="cls-1"
                        x="5.09"
                        y="30.35"
                        width="6"
                        height="6"
                        rx="2"
                      />
                    </g>
                  </g>
                </svg>
                <div className="hover-target absolute top-0 w-full h-5 pl-8 pr-[72px] pt-px justify-end flex items-center mt-4 left-0 text-xs text-gray-500 dark:text-gray-400 pr-8">
                  Great with mobile devices
                </div>
              </div>
              <div
                id="ss-layout-phone-rh"
                className={`ss-layout-option hover-trigger flex ${
                  layout === "phone-rh" ? "selected" : ""
                }`}
                onClick={() => setLayout("phone-rh")}
              >
                <svg
                  className="stroke-current h-full layout-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25.7 41.7"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <rect
                        className="cls-1"
                        x="0.85"
                        y="0.85"
                        width="24"
                        height="40"
                        rx="2"
                      />
                      <rect
                        className="cls-1"
                        x="14.35"
                        y="20.35"
                        width="6"
                        height="6"
                        rx="2"
                      />
                      <rect
                        className="cls-1"
                        x="14.35"
                        y="30.35"
                        width="6"
                        height="6"
                        rx="2"
                      />
                    </g>
                  </g>
                </svg>
                <div className="hover-target absolute top-0 w-full h-5 pl-8 pr-[72px] pt-px justify-end flex items-center mt-4 left-0 text-xs text-gray-500 dark:text-gray-400 pr-8">
                  Great with mobile devices
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
