import React, { useState } from 'react';

function StudyDurationWidgetStat({statValue}) {
  let hours = Math.floor(statValue / 60);
  let minutes = statValue % 60;

  if (statValue < 60) {
    return (
      <div className="tracking-tight">
        {minutes}<span className="text-base ml-1">m</span>        
      </div>
    );
  } else {
    return (
      <div className="tracking-tight"> 
        {hours}<span className="text-base ml-1">h</span> {minutes}<span className="text-base ml-1">m</span>
      </div>
    );
  };
}

export default StudyDurationWidgetStat;
