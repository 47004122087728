import React, { useState } from "react";
import PendingWord from "./PendingWord";

export default function ApproveOrRejectWord({ token, pendingWords }) {

  return (
    <div className="flex flex-col text-lg mt-12 border-b-2 border-gray-100 dark:border-gray-600">
      <div className="flex w-full">
        <div className="text-gray-400 text-base p-2 w-48">Simplified</div>
        <div className="text-gray-400 text-base p-2 w-48">Traditional</div>

        <div className="text-gray-400 text-base p-2 w-48">oPinyin</div>
        <div className="text-gray-400 text-base p-2 w-48">ePinyin</div>
        <div className="text-gray-400 text-base p-2 flex-grow">English</div>
        <div className="text-gray-400 text-base p-2 w-[118px]"></div>
      </div>

      {pendingWords.map((item, index) => (
        <PendingWord key={index} token={token} pendingWord={item} />
      ))}
    </div>
  );
}
