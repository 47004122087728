import React, { useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

export default function StudySessionEditNoteSlider({
  userWordNote,
  setUserWordNote,
  editingNote,
  handleSaveAndCloseNote,
}) {
  const userWordNoteTextAreaRef = useRef();

  useEffect(() => {
    if (editingNote) {
      setTimeout(() => {
        userWordNoteTextAreaRef.current.focus();
      }, 100);
    }
  }, [editingNote]);

  const handleUserWordNoteChange = (event) => {
    setUserWordNote(event.target.value);
  };

  return (
    <CSSTransition
      in={editingNote}
      timeout={200}
      className="fixed inset-0 items-center justify-center bg-black bg-opacity-0 w-full z-10 pointer-events-none"
      classNames="modal-background"
    >
      <div>
        <div
          className={`edit-note-slider pt-6 pr-8 pl-[92px] pb-8 bg-white z-50 absolute flex top-0 left-0  overflow-auto flex-col pointer-events-auto select-none h-screen w-full md:w-[360px] lg:w-[500px] xl:w-[640px] border-l dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 text-gray-700 text-sm`}
        >
          <div className="flex justify-between">
            <div className="font-semibold text-lg h-10 flex items-center dark:text-gray-300">
              Your notes for this word
            </div>

            <div
              className="ml-auto text-base font-bold cursor-pointer flex items-center justify-center rounded-xl text-white px-3 bg-blue-neon xdark:bg-green-dark hover:bg-opacity-90 h-14 md:h-12 shadow-md active:shadow select-none"
              onClick={() => handleSaveAndCloseNote()}
            >
              Save & Close
            </div>
          </div>
          <textarea
            ref={userWordNoteTextAreaRef}
            className="bg-gray-100 dark:bg-gray-600 dark:text-gray-300 rounded-xl border-2-dashed p-4 text-lg mt-8 h-52 outline-blue-neon"
            placeholder="Mnemonics. Your favorite teacher's explanation. Example sentences. Or just a story about the first time you encountered this word in the wild."
            value={userWordNote == null ? "" : userWordNote}
            onChange={handleUserWordNoteChange}
          ></textarea>

          <div className="hidden mt-8 text-sm text-gray-500 dark:text-gray-300">
            <div className="text-gray-400 pb-1">Hanzi Movie Method (MB)</div>
            <div className="flex flex-col">
              <div className="flex">
                <div className="w-24 font-medium text-gray-700 dark:text-gray-400">
                  vowels
                </div>
                <div>
                  <i className="fa-sharp fa-regular fa-arrow-right mr-4"></i>
                  location
                </div>
              </div>

              <div className="flex">
                <div className="w-24 font-medium text-gray-700 dark:text-gray-400">
                  consonants
                </div>
                <div>
                  <i className="fa-sharp fa-regular fa-arrow-right mr-4"></i>
                  people
                </div>
              </div>

              <div className="flex">
                <div className="w-24 font-medium text-gray-700 dark:text-gray-400">
                  components
                </div>
                <div>
                  <i className="fa-sharp fa-regular fa-arrow-right mr-4"></i>
                  objects
                </div>
              </div>

              <div className="flex">
                <div className="w-24 font-medium text-gray-700 dark:text-gray-400">
                  meaning
                </div>
                <div>
                  <i className="fa-sharp fa-regular fa-arrow-right mr-4"></i>
                  actions
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between border-red mt-auto">
            <div
              className={`hidden xss-toggle ${true ? "toggle-on" : ""}`}
              onClick={() => console.log("click")}
            >
              <div className="transition duration-500">
                <div className="transition duration-500"></div>
              </div>
              <span className="h-full ml-3 flex items-center">
                Mnemonic Guidelines
              </span>
            </div>
            <div className="mt-auto flex items-center text-gray-400 dark:text-gray-400">
              Paused while taking notes.
            </div>
            <div
              className={`hidden mt-auto xss-toggle ${true ? "toggle-on" : ""}`}
              onClick={() => console.log("click")}
            >
              <div className="transition duration-500">
                <div className="transition duration-500"></div>
              </div>
              <span className="h-full ml-3 flex items-center">
                Pause while taking notes
              </span>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
