import React, { useState } from "react";

export default function WordImportMatches({ matches, numMatches }) {
  return (
    <div className="flex flex-col">
      <div className="text-3xl mx-auto">Matches</div>

      {numMatches == 0 && (
        <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
          None of the words you wanted to add were in our dictionary. 糟糕！
        </div>
      )}

      {numMatches > 0 && (
        <>
          <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
            These words were added to your list and require no further action.
          </div>

          <div className="flex flex-wrap mt-6 text-3xl simplified justify-center">
            {matches.split("/").map((word, i) => {
              return (
                <div
                  key={i}
                  className="bg-gray-200 dark:bg-gray-600 p-1 rounded flex items-center justify-center mr-2 mb-2"
                >
                  {word}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
