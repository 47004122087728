import React, { useState } from 'react';
import AttemptsWidgetPopup from './AttemptsWidgetPopup';
import AttemptsWidgetStat from './AttemptsWidgetStat';
import RetentionRateWidgetPopup from './RetentionRateWidgetPopup';
import RetentionRateWidgetStat from './RetentionRateWidgetStat';
import StudyDurationWidgetPopup from './StudyDurationWidgetPopup';
import StudyDurationWidgetStat from './StudyDurationWidgetStat';
import StudySessionsWidgetPopup from './StudySessionsWidgetPopup';
import StudySessionsWidgetStat from './StudySessionsWidgetStat';
import VelocityWidgetPopup from './VelocityWidgetPopup';
import VelocityWidgetStat from './VelocityWidgetStat';
import VocabSizeWidgetPopup from './VocabSizeWidgetPopup';
import VocabSizeWidgetStat from './VocabSizeWidgetStat';

const components = {
    'vocabSizeWidgetStat': VocabSizeWidgetStat,
    'vocabSizeWidgetPopup': VocabSizeWidgetPopup,
    'studyDurationWidgetStat': StudyDurationWidgetStat,
    'studyDurationWidgetPopup': StudyDurationWidgetPopup,
    'retentionRateWidgetPopup': RetentionRateWidgetPopup,
    'retentionRateWidgetStat': RetentionRateWidgetStat,
    'attemptsWidgetStat': AttemptsWidgetStat,
    'attemptsWidgetPopup': AttemptsWidgetPopup,
    'studySessionsWidgetStat': StudySessionsWidgetStat,
    'studySessionsWidgetPopup': StudySessionsWidgetPopup,
    'velocityWidgetStat': VelocityWidgetStat,
    'velocityWidgetPopup': VelocityWidgetPopup,
};

export default function MiniDashboardWidget({title, statValue, statComponent, popupComponent, globalRetentionRate, popupCssClasses, studySessionsDistribution}) {

  var StatComponent = components[statComponent];
  var PopupComponent = components[popupComponent];

  return (
    <div className="w-full bg-white dark:bg-gray-800 group">
        
      <div className="hover-trigger bg-white dark:bg-gray-800 p-6 z-30 hover:z-50 relative">
        <div className="mini-widget-title">
          {title}
        </div>
        <div className="dark:text-gray-300 mt-2">
          <span className="text-3xl font-semibold">{<StatComponent statValue={statValue}  />}</span>
        </div>
        <div className={`hover-target db-widget-popup w-96 pointer-events-none ${popupCssClasses}`}>
          <div>{<PopupComponent globalRetentionRate={globalRetentionRate} studySessionsDistribution={studySessionsDistribution} />}</div>        
        </div>
      </div>
      <div className="hidden sm:block fixed top-0 left-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 dark:group-hover:bg-opacity-40 pointer-events-none w-screen h-screen z-40"></div>
    </div>
  );
}