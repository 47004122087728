import React, { useState } from 'react';

function StudySessionsWidgetPopup({studySessionsDistribution}) {
  return (
    <div className="text-xs">
      <p className="text-base">
        <span className="font-semibold dark:text-gray-100">Study Sessions</span> indicates the number of times you've entered a study session.
      </p>
      
      <div className="mt-6">
        <div className="flex items-center font-bold border-b mb-2 pb-1">
          <div className="text-center w-14">Duration</div>
          <div className="flex-grow text-right">#</div>
          <div className="w-8 text-right">%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3"><i className="fal fa-less-than-equal fa-sm"></i>2m</div>
          <div className="bg-gray-200 dark:bg-gray-600  flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[0][1] + '%'}}></div>
          </div>
          <div className="w-8 text-right">{studySessionsDistribution[0][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[0][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">3m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[1][1] + '%'}}></div>

          </div>
          <div className="w-8 text-right">{studySessionsDistribution[1][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[1][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">4m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[2][1] + '%'}}></div>
          </div>
          <div className="w-8 text-right">{studySessionsDistribution[2][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[2][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">5m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[3][1] + '%'}}></div>
          </div>
          <div className="w-8 text-right">{studySessionsDistribution[3][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[3][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">6m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[4][1] + '%'}}></div>
          </div>
          <div className="w-8 text-right">{studySessionsDistribution[4][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[4][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">7m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[5][1] + '%'}}></div>
          </div>
          <div className="w-8 text-right">{studySessionsDistribution[5][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[5][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">8m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[6][1] + '%'}}></div>

          </div>
          <div className="w-8 text-right">{studySessionsDistribution[6][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[6][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">9m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="w-full h-px absolute bottom-0 bg-white dark:bg-gray-700 bg-opacity-60"></div>
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[7][1] + '%'}}></div>
          </div>
          <div className="w-8 text-right">{studySessionsDistribution[7][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[7][1])}%</div>
        </div>

        <div className="flex items-center">
          <div className="text-right w-12 pr-3">10m</div>
          <div className="bg-gray-200 dark:bg-gray-600 flex-grow h-4 flex relative">
            <div className="bg-gray-400 w-full" style={{width: studySessionsDistribution[8][1] + '%'}}></div>

          </div>
          <div className="w-8 text-right">{studySessionsDistribution[8][0]}</div>
          <div className="w-8 text-right">{Math.ceil(studySessionsDistribution[8][1])}%</div>
        </div>
      </div>
    </div>
  );
}

export default StudySessionsWidgetPopup;