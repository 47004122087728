import React, { useState } from "react";

function AttemptsWidgetPopup() {
  return (
    <div>
      <p className="text-base">
        <span className="font-semibold dark:text-gray-100">Attempts</span>{" "}
        measures the number of times you've tested your knowledge of a word.
      </p>
      <p className="mt-3">
        You might think of this number as a proxy for how much effort you've put
        into your learning.
      </p>
    </div>
  );
}

export default AttemptsWidgetPopup;
