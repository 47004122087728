/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.log("Hello World from Webpacker");

import "../stylesheets/application.scss";
require("trix");
require("@rails/actiontext");

var Trix = require("trix");

// /* what the newly created button does */
// Trix.config.textAttributes.red = {
//     style: { color: "red" },
//     parser: function(element) {
//         return element.style.color === "red"
//     },
//     inheritable: true
// }

Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
};

Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
};

Trix.config.blockAttributes.heading4 = {
  tagName: "h4",
};

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function (event) {
  var buttonH2 =
    '<button type="button" class="trix-button" data-trix-attribute="heading2" title="h2" tabindex="-1">h2</button>';
  var buttonH3 =
    '<button type="button" class="trix-button" data-trix-attribute="heading3" title="h3" tabindex="-1">h3</button>';
  var buttonH4 =
    '<button type="button" class="trix-button" data-trix-attribute="heading4" title="h4" tabindex="-1">h4</button>';
  event.target.toolbarElement
    .querySelector(".trix-button-group")
    .insertAdjacentHTML("beforeend", buttonH2);

  event.target.toolbarElement
    .querySelector(".trix-button-group")
    .insertAdjacentHTML("beforeend", buttonH3);

  event.target.toolbarElement
    .querySelector(".trix-button-group")
    .insertAdjacentHTML("beforeend", buttonH4);
}); // Support component names relative to this directory:

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
