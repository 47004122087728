import React, { useState, useEffect } from "react";
import StudySessionPauseModal from "./StudySessionPauseModal";
import StudySessionEditNoteSlider from "./StudySessionEditNoteSlider";
import useMousetrap from "react-hook-mousetrap";

export default function StudySessionTimer({
  unPause,
  secondsLeft,
  elapsedPercentage,
  studySessionDoneLoading,
  increaseCharacterSize,
  decreaseCharacterSize,
  increasePinyinSize,
  decreasePinyinSize,
  increaseEnglishSize,
  decreaseEnglishSize,
  increaseSentenceCharacterSize,
  decreaseSentenceCharacterSize,
  increaseSentenceEnglishSize,
  decreaseSentenceEnglishSize,
  isPaused,
  settingsOpen,
  setSettingsOpen,
  limitType,
  limitValue,
  layout,
  setLayout,
  darkMode,
  toggleDarkMode,
  showAdvancedResponses,
  toggleShowAdvancedResponses,
  showReplayButtons,
  toggleShowReplayButtons,
  uncoverElements,
  resetFontSizes,
  undo,
  currentCardIndex,
  shortcutHints,
  toggleShortcutHints,
  numCardsToFinish,
  endSessionEarly,
  autoCopy,
  toggleAutoCopy,
  autoPlay,
  toggleAutoPlay,
  userWordNote,
  setUserWordNote,
  saveUserWordNote,
  editingNote,
  setEditingNote,
}) {
  const handleSaveAndCloseNote = () => {
    saveUserWordNote();
    setEditingNote(false);
  };

  function convertSecondsLeftToText(secondsLeft) {
    if (secondsLeft >= 60) {
      return (
        <span className="timer-font">
          {Math.floor(secondsLeft / 60)}
          <span className="font-inter">m</span>
          <span className="ml-1">
            {Math.floor(secondsLeft % 60)}
            <span className="font-inter">s</span>
          </span>
        </span>
      );
    } else if (secondsLeft >= 1) {
      return (
        <span className="timer-font">
          {Math.floor(secondsLeft % 60)}
          <span className="font-inter">s</span>
        </span>
      );
    } else {
      return (
        <span>
          {numCardsToFinish > 1 && (
            <span>{numCardsToFinish} words to finish</span>
          )}
          {numCardsToFinish === 1 && <span>1 word to finish</span>}
        </span>
      );
    }
  }

  return (
    <div>
      <div className="absolute flex md:hidden top-0 left-0 w-screen h-2 bg-gray-100 dark:bg-gray-700">
        <div
          className={`h-full bg-gray-300 dark:bg-gray-500 ${
            limitType === "words"
              ? "transition-all duration-[.6s] ease-[cubic-bezier(0.16,1,0.3,1)]"
              : ""
          }`}
          style={{ width: `${elapsedPercentage}%` }}
        ></div>
        <div
          className={`absolute top-0 right-0 mt-3 mr-1 text-right text-gray-300 dark:text-gray-500`}
        >
          {limitType === "minutes" && (
            <span className="dynamic-timer">
              {convertSecondsLeftToText(secondsLeft)}
            </span>
          )}
          {limitType === "words" && (
            <span>
              {currentCardIndex + 1}
              <span className="px-0.5">/</span>
              {limitValue}
            </span>
          )}
        </div>
      </div>
      <div
        onClick={() => setSettingsOpen(true)}
        className="absolute top-0 right-0 mt-[8px] mr-[8px] md:mt-8 md:mr-[37px] hidden md:flex flex-col-reverse md:flex-row text-gray-400 hover:text-blue-neon group cursor-pointer"
      >
        <div className="w-full md:w-[130px] relative flex flex-col mt-[8px] md:mt-0">
          <div className="flex flex-col h-9">
            <div
              className={`flex w-full bg-gray-100 group-hover:bg-blue-neon group-hover:bg-opacity-20 dark:bg-gray-700 relative rounded transition duration-1000 h-2 mb-[3px]`}
            >
              <div
                className={`h-full bg-gray-300 dark:bg-gray-500 group-hover:bg-blue-neon rounded ${
                  limitType === "words"
                    ? "transition-all duration-[.6s] ease-[cubic-bezier(0.16,1,0.3,1)]"
                    : ""
                }`}
                style={{ width: `${elapsedPercentage}%` }}
              ></div>
            </div>

            <div
              className={`hidden md:block group-hover:text-blue-neon ml-auto mr-2px dynamic-timer ${
                secondsLeft <= 0 ? "text-base" : "text-sm"
              }`}
            >
              {" "}
              {limitType === "minutes" && convertSecondsLeftToText(secondsLeft)}
              {limitType === "words" && (
                <span>
                  {currentCardIndex + 1}
                  <span className="px-0.5">/</span>
                  {limitValue}
                </span>
              )}
            </div>
          </div>
          <div className="pr-2px absolute pointer-events-none transition-all duration-100 bottom-[41px] group-hover:bottom-[46px] opacity-0 group-hover:opacity-100 text-blue-neon group-hover:text-blue-neon text-sm text-right w-full font-bold ">
            Pause / Settings
          </div>
        </div>

        <div className="flex items-center justify-center h-10 w-10 bg-gray-200 dark:bg-gray-600 cursor-pointer group-hover:text-white group-hover:bg-blue-neon rounded-lg pt-px md:ml-[18px] text-gray-500 dark:text-gray-300">
          <i className="fa-solid fa-play-pause"></i>
        </div>
      </div>

      {studySessionDoneLoading && (
        <>
          <StudySessionEditNoteSlider
            userWordNote={userWordNote}
            setUserWordNote={setUserWordNote}
            editingNote={editingNote}
            handleSaveAndCloseNote={handleSaveAndCloseNote}
          />

          <StudySessionPauseModal
            limitType={limitType}
            limitValue={limitValue}
            currentCardIndex={currentCardIndex}
            convertSecondsLeftToText={convertSecondsLeftToText}
            secondsLeft={secondsLeft}
            increaseCharacterSize={increaseCharacterSize}
            decreaseCharacterSize={decreaseCharacterSize}
            increasePinyinSize={increasePinyinSize}
            decreasePinyinSize={decreasePinyinSize}
            increaseEnglishSize={increaseEnglishSize}
            decreaseEnglishSize={decreaseEnglishSize}
            increaseSentenceEnglishSize={increaseSentenceEnglishSize}
            decreaseSentenceEnglishSize={decreaseSentenceEnglishSize}
            increaseSentenceCharacterSize={increaseSentenceCharacterSize}
            decreaseSentenceCharacterSize={decreaseSentenceCharacterSize}
            elapsedPercentage={elapsedPercentage}
            unPause={unPause}
            isPaused={isPaused}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            layout={layout}
            setLayout={setLayout}
            darkMode={darkMode}
            toggleDarkMode={toggleDarkMode}
            showAdvancedResponses={showAdvancedResponses}
            toggleShowAdvancedResponses={toggleShowAdvancedResponses}
            showReplayButtons={showReplayButtons}
            toggleShowReplayButtons={toggleShowReplayButtons}
            uncoverElements={uncoverElements}
            resetFontSizes={resetFontSizes}
            shortcutHints={shortcutHints}
            toggleShortcutHints={toggleShortcutHints}
            endSessionEarly={endSessionEarly}
            autoCopy={autoCopy}
            toggleAutoCopy={toggleAutoCopy}
            autoPlay={autoPlay}
            toggleAutoPlay={toggleAutoPlay}
          />
        </>
      )}

      <div className="absolute top-0 left-0 mt-[8px] ml-[8px] md:mt-8 md:ml-[37px] hidden md:flex items-center text-gray-500 dark:text-gray-300 text-sm space-x-2 md:space-x-[18px] font-bold">
        <div
          className="group flex flex-col items-center justify-between md:justify-center h-10 w-10  bg-gray-200 dark:bg-gray-600 cursor-pointer hover:text-white hover:bg-blue-neon dark:hover:bg-blue-neon rounded-lg pt-px"
          onClick={() => undo()}
        >
          <i className="fa-solid fa-rotate-left relative md:static top-[6px]"></i>
          <div className="text-xxs md:hidden">Undo</div>
          <div className="absolute pointer-events-none transition-all duration-100 md:bottom-[41px] group-hover:bottom-[46px] opacity-0 group-hover:opacity-100 text-blue-neon group-hover:text-blue-neon text-sm tracking-tight">
            Undo
          </div>
        </div>
        <div
          className="group flex flex-col justify-between items-center  md:justify-center h-10 w-10 bg-gray-200 dark:bg-gray-600 cursor-pointer hover:text-white hover:bg-blue-neon dark:hover:bg-blue-neon rounded-lg"
          onClick={() => setEditingNote(true)}
        >
          <i className="fa-solid fa-pen-to-square relative md:static top-[6px]"></i>
          <div className="text-xxs md:hidden">Note</div>
          <div className="absolute pointer-events-none transition-all duration-100 bottom-[41px] group-hover:bottom-[46px] opacity-0 group-hover:opacity-100 text-blue-neon group-hover:text-blue-neon text-sm">
            Note
          </div>
        </div>
      </div>
    </div>
  );
}
