import { useState } from "react";
// IMPORTANT:
// April 6: This file is NOT currently used, as we decided to implement importing words without uploading a file.
// -------
const FileInput = (props) => {
  const [file, setFile] = useState(props.file);

  const handleChange = (onChange, event) => {
    // When user cancels selection
    if (!event.target.files.length) return;

    // Local state to display file/s
    const [file] = event.target.files;
    setFile(URL.createObjectURL(file));

    // Handles file
    onChange(event);
  };

  return (
    <label className="input-file">
      <h6>Select Documents</h6>

      <input
        {...props}
        type="file"
        hidden
        onChange={handleChange.bind(null, props.onChange)}
      />

      {!file && (
        <div className="file-input__unchosen">
          <span>Choose file</span>
          <span>No file chosen</span>
        </div>
      )}
    </label>
  );
};

export default FileInput;
