import React, { useState } from "react";

function VelocityWidgetPopup({ statValue }) {
  return (
    <div>
      <p className="text-base">
        <span className="font-semibold dark:text-gray-100">
          Learning Velocity
        </span>{" "}
        measures how quickly you are learning new words.
      </p>
      <p className="mt-3">
        Slow and steady wins the race: learning just 6 words a day will increase
        your vocabulary by over 2,000 words each year.
      </p>
      <p className="mt-3">
        Velocity calculations include checking assumed words, but do not include
        the action of marking words assumed known.
      </p>
      <p className="mt-3">
        Your learning velocity statistics are updated once per day at midnight
        in your timezone.
      </p>
    </div>
  );
}

export default VelocityWidgetPopup;
