import React from "react";
import { CSSTransition } from "react-transition-group";

import StudySessionEndOrPause from "./StudySessionEndOrPause";
import StudySessionToggles from "./StudySessionToggles";
import StudySessionLayoutOptions from "./StudySessionLayoutOptions";
import StudySessionSizeAdjustments from "./StudySessionSizeAdjustments";

export default function StudySessionPauseModal({
  limitType,
  limitValue,
  currentCardIndex,
  secondsLeft,
  convertSecondsLeftToText,
  increaseCharacterSize,
  decreaseCharacterSize,
  increasePinyinSize,
  decreasePinyinSize,
  increaseEnglishSize,
  decreaseEnglishSize,
  increaseSentenceCharacterSize,
  decreaseSentenceCharacterSize,
  increaseSentenceEnglishSize,
  decreaseSentenceEnglishSize,
  elapsedPercentage,
  unPause,
  isPaused,
  settingsOpen,
  setSettingsOpen,
  layout,
  setLayout,
  darkMode,
  toggleDarkMode,
  showAdvancedResponses,
  toggleShowAdvancedResponses,
  showReplayButtons,
  toggleShowReplayButtons,
  uncoverElements,
  resetFontSizes,
  shortcutHints,
  toggleShortcutHints,
  endSessionEarly,
  autoCopy,
  toggleAutoCopy,
  autoPlay,
  toggleAutoPlay,
}) {
  return (
    <CSSTransition
      in={settingsOpen}
      timeout={200}
      className="fixed inset-0 items-center justify-center bg-black bg-opacity-0 w-full z-10 pointer-events-none"
      classNames="modal-background"
    >
      <div>
        <div
          className={`pause-modal z-50 absolute flex top-0 right-0  overflow-auto flex-col pointer-events-auto select-none h-screen w-full md:w-[360px] border-l dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 text-gray-700 text-sm`}
        >
          <div
            className={`md:h-full bg-white dark:bg-gray-700 transition duration-300 md:opacity-100 ${
              uncoverElements ? "opacity-0" : ""
            }`}
          >
            <StudySessionEndOrPause
              limitType={limitType}
              limitValue={limitValue}
              currentCardIndex={currentCardIndex}
              secondsLeft={secondsLeft}
              convertSecondsLeftToText={convertSecondsLeftToText}
              elapsedPercentage={elapsedPercentage}
              setSettingsOpen={setSettingsOpen}
              currentCardIndex={currentCardIndex}
              endSessionEarly={endSessionEarly}
            />

            <StudySessionToggles
              darkMode={darkMode}
              toggleDarkMode={toggleDarkMode}
              showAdvancedResponses={showAdvancedResponses}
              toggleShowAdvancedResponses={toggleShowAdvancedResponses}
              showReplayButtons={showReplayButtons}
              toggleShowReplayButtons={toggleShowReplayButtons}
              shortcutHints={shortcutHints}
              toggleShortcutHints={toggleShortcutHints}
              autoCopy={autoCopy}
              toggleAutoCopy={toggleAutoCopy}
              autoPlay={autoPlay}
              toggleAutoPlay={toggleAutoPlay}
            />

            <StudySessionLayoutOptions layout={layout} setLayout={setLayout} />
          </div>

          <StudySessionSizeAdjustments
            increaseCharacterSize={increaseCharacterSize}
            decreaseCharacterSize={decreaseCharacterSize}
            increasePinyinSize={increasePinyinSize}
            decreasePinyinSize={decreasePinyinSize}
            increaseEnglishSize={increaseEnglishSize}
            decreaseEnglishSize={decreaseEnglishSize}
            increaseSentenceEnglishSize={increaseSentenceEnglishSize}
            decreaseSentenceEnglishSize={decreaseSentenceEnglishSize}
            increaseSentenceCharacterSize={increaseSentenceCharacterSize}
            decreaseSentenceCharacterSize={decreaseSentenceCharacterSize}
            resetFontSizes={resetFontSizes}
          />
        </div>
      </div>
    </CSSTransition>
  );
}
