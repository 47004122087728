import React, { useState } from 'react';

function RetentionRateWidgetStat({statValue}) {

  return (
    <div>
      {statValue}
      <i className="fa-solid fa-percent text-base ml-1"></i>
    </div>
  );
}

export default RetentionRateWidgetStat;