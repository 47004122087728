import React, { useEffect, useRef } from "react";

export default function Face({
  characterSize,
  pinyinSize,
  englishSize,
  sentenceCharacterSize,
  sentenceEnglishSize,
  settingsOpen,
  face,
  card,
  highlightHard,
  characterSet,
  autoCopy,
  userSentences,
  charactersAdjusting,
  pinyinAdjusting,
  englishAdjusting,
  sentenceCharacterAdjusting,
  sentenceEnglishAdjusting,
  pinyinStyle,
  editingNote,
  userWordNote,
}) {
  const userWordNoteRef = useRef();

  var ePinyinRow = card.epinyin.split(" ");
  var oPinyinRow = card.opinyin.split(" ");
  var tPinyinRow = card.tpinyin.split(" ");

  var effectiveRow = "";
  var originalRow = "";
  var originalRowAltered = "";
  var clarificationRow = "";
  var tonelessRow = "";

  if (pinyinStyle === "none") {
    tonelessRow = tPinyinRow.map((e, i) => {
      return (
        <div
          className={`ss-pinyin pt-2 s-${e.length} ${
            face.pinyin ? "opacity-100" : "opacity-0"
          }`}
          key={`tr-${i}`}
        >
          {e}
        </div>
      );
    });
  }

  if (pinyinStyle === "spoken" || pinyinStyle == "both") {
    effectiveRow = ePinyinRow.map((e, i) => {
      return (
        <div
          className={`ss-pinyin t${card.etones[i]} pt-2 s-${e.length} ${
            face.pinyin ? "opacity-100" : "opacity-0"
          }`}
          key={`er-${i}`}
        >
          {e}
        </div>
      );
    });
  }

  if (pinyinStyle === "original") {
    originalRow = oPinyinRow.map((e, i) => {
      return (
        <div
          className={`ss-pinyin t${card.otones[i]} pt-2 s-${e.length} ${
            face.pinyin ? "opacity-100" : "opacity-0"
          }`}
          key={`or-${i}`}
        >
          {e}
        </div>
      );
    });
  }

  if (pinyinStyle === "both") {
    originalRowAltered = oPinyinRow.map((e, i) => {
      if (ePinyinRow[i] != e) {
        return (
          <div
            className={`ss-pinyin t${card.otones[i]} altered s-${e.length} ${
              face.pinyin ? "opacity-60" : "opacity-0"
            }`}
            key={`ora-${i}`}
          >
            {e}
          </div>
        );
      } else {
        return <div></div>;
      }
    });
  }

  if (pinyinStyle === "both") {
    if (card.epinyin != card.opinyin) {
      clarificationRow = ePinyinRow.map((e, i) => {
        if (ePinyinRow[i] != oPinyinRow[i]) {
          return (
            <div
              className={`text-gray-400 pt-3 pb-2 ${
                face.pinyin ? "opacity-100" : "opacity-0"
              }`}
            >
              <i className="fa-regular fa-up-long"></i>
            </div>
          );
        } else {
          return <div key={`cr-${i}`}></div>;
        }
      });
    }
  }

  useEffect(() => {
    if (autoCopy) {
      if (characterSet != "traditional") {
        navigator.clipboard.writeText(card.simplified);
      } else {
        navigator.clipboard.writeText(card.traditional);
      }
    }
  }, [card]);

  function highlight_word(sentence, word) {
    let result = "";
    if (sentence != null) {
      if (word.includes("…")) {
        let word1 = word.split("…")[0];
        let word2 = word.split("…")[1];

        if (word1 != word2) {
          result = `${
            sentence.split(word1)[0]
          }<span class='word-highlight'>${word1}</span>${
            sentence.split(word1)[1].split(word2)[0]
          }<span class='word-highlight'>${word2}</span>${
            sentence.split(word1)[1].split(word2)[1]
          }`;
        } else {
          result = `${
            sentence.split(word1)[0]
          }<span class='word-highlight'>${word1}</span>${
            sentence.split(word1)[1].split(word2)[0]
          }<span class='word-highlight'>${word2}</span>${
            sentence
              .substring(sentence.indexOf(word1) + word1.length)
              .split(word2)[1]
          }`;
        }
      } else if (!sentence.includes(word)) {
        result = sentence;
      } else {
        result = `${sentence.substring(
          0,
          sentence.indexOf(word)
        )}<span class='word-highlight'>${word}</span>${sentence.substring(
          sentence.indexOf(word) + word.length
        )}`;
      }
    }
    return result;
  }

  return (
    <div
      id="face-container"
      className={`flex flex-col px-6 pb-[324px] lg:pb-[268px] text-center mt-10 md:mt-20 transition-all duration-200 md:ease-[cubic-bezier(0.34,1.56,0.64,1)]
      ss-character-size-${characterSize}
      ss-pinyin-size-${pinyinSize}
      ss-english-size-${englishSize}
      ss-sentence-character-size-${sentenceCharacterSize}
      ss-sentence-english-size-${sentenceEnglishSize}
      ${face.pinyin ? "" : "hide-tone-color"}

      ${
        charactersAdjusting ? "ss-character-size-adjustment-in-progress" : ""
      } ${pinyinAdjusting ? "ss-pinyin-size-adjustment-in-progress" : ""} ${
        englishAdjusting ? "ss-english-size-adjustment-in-progress" : ""
      } 
      
      ${
        sentenceCharacterAdjusting
          ? "ss-sentence-character-size-adjustment-in-progress"
          : ""
      }
      ${
        sentenceEnglishAdjusting
          ? "ss-sentence-english-size-adjustment-in-progress"
          : ""
      }
      ${card.type === "new" ? "new-card" : ""}
      ${card.type === "assumed" ? "assumed-card" : ""}
      ${highlightHard && card.hard ? "hard-card" : ""} ${
        settingsOpen ? "md:pr-[344px]" : ""
      }
      ${editingNote ? "md:pl-[640px]" : ""}`}
    >
      <div
        className={`grid grid-flow-row grid-cols-${card.simplified.length} auto-rows-min mx-auto
        
       `}
      >
        {(characterSet === "simplified" || characterSet === "both") &&
          card.simplified.split("").map((e, i) => {
            return (
              <div
                className={`ss-character t${card.etones[i]} simplified ${
                  face.character ? "opacity-100" : "opacity-0"
                }`}
                key={`simp-${i}`}
              >
                {e}
              </div>
            );
          })}

        {(characterSet === "traditional" ||
          (characterSet === "both" && card.simplified !== card.traditional)) &&
          card.traditional.split("").map((e, i) => {
            return (
              <div
                className={`ss-character t${card.etones[i]} traditional ${
                  face.character ? "opacity-100" : "opacity-0"
                }`}
                key={`trad-${i}`}
              >
                {e}
              </div>
            );
          })}

        {(pinyinStyle === "spoken" || pinyinStyle === "both") && effectiveRow}
        {pinyinStyle === "both" && clarificationRow}
        {pinyinStyle === "both" && originalRowAltered}

        {pinyinStyle === "original" && originalRow}
        {pinyinStyle === "none" && tonelessRow}
      </div>

      <div
        className={`mt-3 lg:mt-6 flex flex-wrap mx-auto max-w-sm justify-center ${
          face.english ? "opacity-100" : "opacity-0"
        }`}
      >
        {card.common_definitions.slice(0, 6).map((defn, i) => {
          return (
            <div key={`cd-${defn} ${i}`} className="ss-english">
              {" "}
              {defn}{" "}
            </div>
          );
        })}
        {card.proper_nouns.map((defn, i) => {
          return (
            <div key={`pn-${defn} ${i}`} className="ss-english">
              {" "}
              {defn}{" "}
            </div>
          );
        })}
      </div>

      {userSentences && card.sentence && (
        <div
          id="ss-sentence-container"
          className={`w-full flex mt-1 lg:mt-2 ${
            face.sentence ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`ss-sentence w-full max-w-prose text-center mx-auto text-gray-500 dark:text-gray-300 ${
              face.sentence_cn ? "opacity-100" : "opacity-0"
            }`}
          >
            {characterSet != "traditional" && (
              <div
                className="ss-sentence-character simplified mb-1 md:mb-2"
                dangerouslySetInnerHTML={{
                  __html: highlight_word(
                    card.sentence.simplified,
                    card.simplified
                  ),
                }}
              ></div>
            )}
            {characterSet != "simplified" && (
              <div
                className="ss-sentence-character traditional mb-1 md:mb-2"
                dangerouslySetInnerHTML={{
                  __html: highlight_word(
                    card.sentence.traditional,
                    card.traditional
                  ),
                }}
              ></div>
            )}
            <div
              className={`ss-sentence-english ${
                face.sentence_en ? "opacity-100" : "opacity-0"
              }`}
            >
              {card.sentence.english}
            </div>
          </div>
        </div>
      )}

      {(face.note || editingNote) && (
        <div className="w-full max-w-lg mx-auto mt-3 lg:mt-6 relative">
          <div className="shadow-user-word-note">{userWordNote}</div>
          <textarea
            readOnly
            className={`ss-user-word-note ${
              userWordNote == null || userWordNote === "" ? "empty" : ""
            }`}
            ref={userWordNoteRef}
            value={userWordNote}
          />
        </div>
      )}
    </div>
  );
}
