import React, { useState } from "react";

export default function SettingsMenu({
  activeScreen,
  setActiveScreen,
  darkMode,
  toggleDarkMode,
}) {
  return (
    <>
      <div className="text-8xl text-gray-100 dark:text-gray-600 absolute -top-[2px] left-[16px] z-10">
        <i className="fa-solid fa-gears rotate-[120deg]"></i>
      </div>
      <div className="relative text-4xl flex items-center mr-auto ml-6 font-bold text-gray-500 dark:text-gray-300 z-20">
        Settings
      </div>

      <div
        className="settings-menu-option group mt-20"
        onClick={() => setActiveScreen("characters")}
      >
        <div
          className={`${
            activeScreen === "characters"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          } `}
        >
          <div>
            <i className="fa-solid fa-pen-nib"></i>
          </div>
          <div>Characters</div>
        </div>
      </div>

      <div
        className="settings-menu-option group"
        onClick={() => setActiveScreen("tones")}
      >
        <div
          className={`${
            activeScreen === "tones"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          } `}
        >
          <div>
            <i className="fa-solid fa-microphone"></i>
          </div>
          <div>Tones</div>
        </div>
      </div>

      <div
        className="settings-menu-option group"
        onClick={() => setActiveScreen("audio")}
      >
        <div
          className={`${
            activeScreen === "audio"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-volume"></i>
          </div>
          <div>Audio</div>
        </div>
      </div>

      <div
        className="settings-menu-option group"
        onClick={() => setActiveScreen("study")}
      >
        <div
          className={`${
            activeScreen === "study"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-gamepad-modern"></i>
          </div>
          <div>Study</div>
        </div>
      </div>

      <div
        className="settings-menu-option group"
        onClick={() => setActiveScreen("algorithm")}
      >
        <div
          className={`${
            activeScreen === "algorithm"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-binary"></i>
          </div>
          <div>Algorithm</div>
        </div>
      </div>

      <div
        className="hidden  group"
        onClick={() => setActiveScreen("reminders")}
      >
        <div
          className={`${
            activeScreen === "reminders"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-alarm-clock"></i>
          </div>
          <div>Reminders</div>
        </div>
      </div>

      <div
        className="settings-menu-option group"
        onClick={() => setActiveScreen("integrations")}
      >
        <div
          className={`${
            activeScreen === "integrations"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-link-horizontal"></i>
          </div>
          <div>Integrations</div>
        </div>
      </div>

      <div
        className="settings-menu-option group"
        onClick={() => setActiveScreen("finicky")}
      >
        <div
          className={`${
            activeScreen === "finicky"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-wine-glass-crack"></i>
          </div>
          <div>Finicky</div>
        </div>
      </div>

      <div
        className="settings-menu-option group"
        onClick={() => setActiveScreen("export")}
      >
        <div
          className={`${
            activeScreen === "export"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-file-arrow-down"></i>
          </div>
          <div>Export</div>
        </div>
      </div>

      <div
        className="hidden xsettings-menu-option group"
        onClick={() => setActiveScreen("resets")}
      >
        <div
          className={`${
            activeScreen === "resets"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-circle-exclamation"></i>
          </div>
          <div>Resets</div>
        </div>
      </div>

      <div
        className="flex items-center mt-4 pt-6 border-t-2 border-gray-100 dark:border-gray-600 pl-7"
        onClick={() => toggleDarkMode()}
      >
        <div className={`two-way-toggle ${darkMode ? "on" : ""}`}>
          <div></div>
        </div>
        <div className="setting-title ml-4">Dark Mode</div>
      </div>

      <div
        className="hidden xsettings-menu-option group mt-auto"
        onClick={() => setActiveScreen("profile")}
      >
        <div
          className={`${
            activeScreen === "profile"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-circle-user"></i>
          </div>
          <div>Profile</div>
        </div>
      </div>
      <div
        className="hidden xsettings-menu-option group mb-20"
        onClick={() => setActiveScreen("billing")}
      >
        <div
          className={`${
            activeScreen === "billing"
              ? "bg-gray-200 dark:bg-gray-500 text-black dark:text-white"
              : "group-hover:bg-gray-100 dark:group-hover:bg-gray-600 group-active:bg-gray-200 dark:group-active:bg-gray-500"
          }`}
        >
          <div>
            <i className="fa-solid fa-circle-dollar"></i>
          </div>
          <div>Billing</div>
        </div>
      </div>
    </>
  );
}
