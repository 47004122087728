import React, { useState, useEffect } from "react";

export default function StudySessionEndOrPause({
  limitType,
  limitValue,
  currentCardIndex,
  secondsLeft,
  convertSecondsLeftToText,
  elapsedPercentage,
  setSettingsOpen,
  endSessionEarly,
}) {
  return (
    <div className="flex border-b-2 border-gray-100 dark:border-gray-600 bg-white dark:bg-gray-700 transition-all duration-500 flex-col items-center justify-between text-sm w-full pt-6 pl-8 pr-8 md:pr-[72px] pb-8">
      <div className="grid grid-cols-2 gap-6 md:gap-4 w-full font-semibold text-lg mb-4">
        <div className="text-gray-500 dark:text-gray-300 text-right">
          PAUSED
        </div>
        <div className="text-timer text-blue-neon">
          {limitType === "minutes" && convertSecondsLeftToText(secondsLeft)}
          {limitType === "words" && (
            <span>
              {currentCardIndex + 1}
              <span className="px-0.5">/</span>
              {limitValue}
            </span>
          )}
        </div>
      </div>
      <div className="w-full h-5 md:h-4 bg-gray-200 dark:bg-gray-500 flex mb-6 md:mb-5 rounded-xl">
        <div
          className="timer-bar-interior h-full bg-gray-400 dark:bg-gray-300 text-sm text-white rounded-xl font-semibold flex justify-end items-center pr-2"
          style={{ width: `${elapsedPercentage}%` }}
        ></div>
      </div>
      <div className="grid grid-cols-2 gap-6 md:gap-5 w-full font-semibold text-base">
        <div
          id="end-session-button"
          className="font-normal cursor-pointer flex items-center justify-center rounded-xl px-2 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 border-2 dark:border-gray-500 shadow-md h-14 md:h-12 active:shadow"
          onClick={() => endSessionEarly()}
        >
          End Now
        </div>
        <div
          onClick={() => setSettingsOpen(false)}
          id="keep-studying-button"
          className="font-bold cursor-pointer flex items-center justify-center rounded-xl text-white px-2 bg-blue-neon xdark:bg-green-dark hover:bg-opacity-90 h-14 md:h-12 shadow-md active:shadow select-none"
        >
          <i className="fas fa-play mr-2"></i>Resume
        </div>
      </div>
    </div>
  );
}
