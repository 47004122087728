import React, { useEffect, useState } from "react";
import { API_ENDPOINT } from "./constants";

export default function ImportWordsForm({ token, focusId, exitPath }) {
  const [rawWords, setRawWords] = useState("");
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [importLimitUsedPercent, setImportLimitUsedPercent] = useState(0);
  const [skipKnownWords, setSkipKnownWords] = useState(false);
  const [skipSomeWords, setSkipSomeWords] = useState(false);
  const [useParser, setUseParser] = useState(false);
  const [importLimitCSS, setImportLimitCSS] = useState("opacity-0 bg-gray-400");

  useEffect(() => {
    if (!rawWords) {
      setShowPlaceholder(true);
    } else {
      setShowPlaceholder(false);
    }

    setImportLimitUsedPercent(rawWords.length / 30);
  }, [rawWords]);

  useEffect(() => {
    if (importLimitUsedPercent > 90) {
      setImportLimitCSS("bg-gradient-to-r from-red via-red to-gray-400");
    } else if (importLimitUsedPercent > 75) {
      setImportLimitCSS("bg-gradient-to-r from-yellow via-yellow to-gray-400");
    } else {
      setImportLimitCSS("bg-gray-400");
    }
  }, [importLimitUsedPercent]);

  function toggleSkipKnownWords() {
    setSkipKnownWords(!skipKnownWords);
  }

  function toggleSkipSomeWords() {
    setSkipSomeWords(!skipSomeWords);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("raw_words", rawWords);
    formData.append("id", focusId);
    formData.append("use_parser", useParser);
    formData.append("exit_path", exitPath);

    fetch(`${API_ENDPOINT}/focuses/import_words`, {
      method: "POST",
      body: formData,
      // 👇 Set headers manually for single file upload
      headers: {
        "X-CSRF-Token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => window.location.replace(`${API_ENDPOINT}/import`))
      .catch((err) => console.error(err));
  };

  return (
    <>
      <div
        onClick={() => setImportModalOpen(true)}
        className="hidden md:flex cursor-pointer md:flex-col md:items-end group md:w-14"
      >
        <div className="text-right justify-end md:justify-center pr-2 md:p-0 md:text-center h-10 w-24 md:w-12 md:h-12 flex items-center md:items-start md:order-last">
          Fast Add
        </div>
        <div className="h-10 w-10 md:h-12 md:w-12 mb-2 rounded-xl bg-gray-50 dark:bg-gray-700 group-hover:bg-gray-100 border dark:border-gray-600  dark:group-hover:bg-gray-500 flex items-center justify-center">
          <i className="fa-solid fa-file-import fa-lg"></i>
        </div>
      </div>

      <div
        className={`${
          importModalOpen ? "" : "hidden"
        } inset-0 z-50 fixed flex items-center justify-center bg-black/50`}
      >
        <div className="relative flex flex-col w-full max-w-3xl bg-white dark:bg-gray-700 rounded-xl shadow-xl">
          <div
            onClick={() => setImportModalOpen(false)}
            className="absolute top-0 right-0 mt-5 mr-6 hidden md:flex items-center justify-center h-12 w-12 cursor-pointer text-gray-400 dark:text-gray-400  hover:bg-gray-100 dark:hover:bg-gray-600 rounded-2xl text-2xl z-30"
          >
            <i className="fa-regular fa-xmark"></i>
          </div>

          <div className="p-10 border-b-2 border-gray-100 dark:border-gray-600">
            <div className="text-2xl">Add Words to this List</div>
          </div>
          <div className="p-10">
            <p className="text-lg">
              Type (or paste) Chinese characters below to add them to this list.
            </p>
            <form
              className="relative mt-10"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <textarea
                className="bg-gray-100 dark:bg-gray-600 w-full h-[260px] rounded-xl p-6 resize-none text-2xl simplified outline-blue-brand border-2 border-gray-100 dark:border-gray-600"
                name="raw_words"
                value={rawWords}
                onChange={(e) => setRawWords(e.target.value)}
              />
              <div className="h-10 w-full relative">
                <div
                  className={`flex transition duration-300 absolute top-0 left-0 w-full h-full items-center justify-end ${
                    importLimitUsedPercent > 50 ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <div className="flex items-center h-1 rounded-full bg-gray-200 dark:bg-gray-500 w-24">
                    <div
                      className={`h-full ${importLimitCSS}`}
                      style={{ width: importLimitUsedPercent }}
                    ></div>
                  </div>
                  <div
                    className={`flex items-center w-9 justify-end ${
                      importLimitUsedPercent > 90 ? "text-red" : ""
                    }`}
                  >
                    {importLimitUsedPercent.toFixed(0)}%
                  </div>
                </div>
              </div>
              <div
                className={`w-full pointer-events-none absolute h-full top-0 left-0 p-6 text-2xl text-gray-400 simplified ${
                  showPlaceholder ? "" : "hidden"
                }`}
              >
                <span className="font-inter text-sm">Line by Line:</span>
                <br />
                你
                <br />
                苹果
                <br />
                出租车
                <br />
                <span className="inline-block inter my-3 font-inter text-sm">
                  --- or ---
                </span>
                <br />
                <span className="font-inter text-sm">Parse Text:</span>
                <br />
                我朋友说她明年会去中国。我也想去！
              </div>

              <div className="grid grid-cols-2 w-full gap-10 text-sm">
                <div
                  onClick={() => setUseParser(false)}
                  className={`bg-white dark:bg-gray-600 p-6 rounded-xl relative border-4 cursor-pointer ${
                    useParser
                      ? "dark:border-gray-500 dark:hover:border-blue-neon/75 hover:border-blue-brand/50"
                      : "border-blue-brand dark:border-blue-neon"
                  }`}
                >
                  {!useParser && (
                    <div className="absolute checkmark top-0 right-0 mt-3 mr-3 text-blue-brand dark:text-blue-neon text-xl">
                      <i className="fa-solid fa-circle-check"></i>
                    </div>
                  )}
                  <div className="text-lg font-semibold">Line by Line</div>
                  <p className="mt-3">
                    Each line is treated as a separate word.
                  </p>
                  <p className="mt-3">
                    Words not found in our dictionary can be submitted for
                    review.
                  </p>
                </div>
                <div
                  onClick={() => setUseParser(false)}
                  className={`bg-white dark:bg-gray-600 p-6 rounded-xl relative border-4 cursor-pointer ${
                    useParser
                      ? "border-blue-brand dark:border-blue-neon"
                      : "dark:border-gray-500 xdark:hover:border-blue-neon/75 xhover:border-blue-brand/50"
                  }`}
                >
                  <div className="absolute top-0 left-0 w-full h-full z-10 bg-gray-900/40 rounded-xl flex text-white text-xl">
                    <div className="ml-auto mt-3 mr-3">Coming Soon</div>
                  </div>
                  {useParser && (
                    <div className="absolute checkmark top-0 right-0 mt-3 mr-3 text-blue-brand dark:text-blue-neon text-xl">
                      <i className="fa-solid fa-circle-check"></i>
                    </div>
                  )}
                  <div className="text-lg font-semibold">Parse Text</div>
                  <span className="text-sm inline-block bg-purple/20 dark:bg-black/20 rounded-lg px-2 font-medium text-purple">
                    Experimental
                  </span>
                  <p className="mt-3">
                    Whole paragraphs will be parsed for words.
                  </p>
                  <p className="mt-3">
                    Words not found in our dictionary will have their
                    single-character components added instead.
                  </p>
                </div>
              </div>

              <div className="mt-8 text-xl w-full flex items-center">
                <div className="hidden flex-col">
                  <div
                    onClick={() => toggleSkipKnownWords()}
                    className={`ss-toggle ${
                      skipKnownWords ? "toggle-on" : "text-gray-500"
                    }`}
                  >
                    <div>
                      <div></div>
                    </div>
                    <span className="h-full ml-3 flex items-center">
                      Skip words I've already learned
                    </span>
                  </div>
                  <div
                    className={`mt-4 ss-toggle ${
                      skipSomeWords ? "toggle-on" : "text-gray-500"
                    }`}
                  >
                    <div onClick={() => toggleSkipSomeWords()}>
                      <div></div>
                    </div>
                    <span className="h-full ml-3 flex items-center">
                      Skip words from{" "}
                      <select className="mx-2 border rounded px-1 py-0.5">
                        <option>HSK 1</option>
                        <option>HSK 2</option>
                        <option>HSK 3</option>
                        <option>HSK 4</option>
                        <option>HSK 5</option>
                        <option>HSK 6</option>
                      </select>{" "}
                      and below
                    </span>
                  </div>
                </div>

                {importLimitUsedPercent < 100 && (
                  <input
                    className="ml-auto text-xl font-medium h-14 px-6 bg-gradient-to-r button from-blue-neon to-blue-brand text-white"
                    type="submit"
                    value="Add to List"
                  />
                )}
                {importLimitUsedPercent >= 100 && (
                  <div className="w-full flex justify-end h-14">
                    <div className="h-full flex items-center">
                      Too many words. :)
                    </div>
                    <div className="ml-3 text-xl rounded-xl flex items-center justify-center font-medium h-full px-6 bg-gray-400 cursor-not-allowed text-white">
                      Add to List
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
