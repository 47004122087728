import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

export default function AdvancedResponseTooltips({
  showBlockTooltip,
  setShowBlockTooltip,
  setBlockTooltipRequired,
  showBoostTooltip,
  setShowBoostTooltip,
  setBoostTooltipRequired,
  showMemorizeTooltip,
  setShowMemorizeTooltip,
  setMemorizeTooltipRequired,
  positiveResponse,
  block,
}) {
  const cancelBlockTooltip = () => {
    setBlockTooltipRequired(true);
    setShowBlockTooltip(false);
  };

  const cancelBoostTooltip = () => {
    setBoostTooltipRequired(true);
    setShowBoostTooltip(false);
  };

  const cancelMemorizeTooltip = () => {
    setMemorizeTooltipRequired(true);
    setShowMemorizeTooltip(false);
  };

  return (
    <>
      <CSSTransition
        in={showBlockTooltip}
        timeout={400}
        className={`fixed inset-0 items-center justify-center bg-black bg-opacity-0 w-full ${
          showBlockTooltip ? "" : "pointer-events-none"
        }`}
        classNames="modal-background"
      >
        <div>
          <div className="advanced-response-tooltip">
            <div className="flex flex-col justify-center items-center w-full md:w-[394px] mx-auto">
              <div className="text-5xl text-black dark:text-white">
                <i className="fa-regular fa-ban"></i>
              </div>

              <p className="mt-6 text-center">
                <span className="font-black text-black dark:text-white">
                  BLOCK
                </span>{" "}
                words you don't know and don't need to learn. You won't be
                tested on them again.
              </p>
              <div className="grid grid-cols-2 gap-6 md:gap-5 w-full font-semibold text-base mt-8">
                <div
                  className="transition-all duration-500 ease-out font-normal cursor-pointer flex items-center justify-center rounded-xl px-2 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 border-2 dark:border-gray-500 shadow-md h-14 md:h-12 active:shadow"
                  onClick={() => cancelBlockTooltip()}
                >
                  Cancel
                </div>
                <div
                  onClick={() => block()}
                  className="transition-all duration-500 ease-out font-bold cursor-pointer flex items-center justify-center rounded-xl text-white dark:text-black px-2 bg-black dark:bg-white hover:bg-opacity-90 h-14 md:h-12 shadow-md active:shadow select-none"
                >
                  Block
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={showBoostTooltip}
        timeout={400}
        className={`fixed inset-0 items-center justify-center bg-black bg-opacity-0 w-full ${
          showBoostTooltip ? "" : "pointer-events-none"
        }`}
        classNames="modal-background"
      >
        <div>
          <div className="advanced-response-tooltip">
            <div className="flex flex-col justify-center items-center w-full md:w-[394px] mx-auto">
              <div className="text-5xl text-green">
                <i className="fa-solid fa-rocket-launch"></i>
              </div>

              <p className="mt-6 text-center">
                Save time by
                <span className="font-black text-green"> BOOSTING</span> words
                you know well. We'll wait longer than usual before testing you
                again.
              </p>
              <div className="grid grid-cols-2 gap-6 md:gap-5 w-full font-semibold text-base mt-8">
                <div
                  className="transition-all duration-500 ease-out font-normal cursor-pointer flex items-center justify-center rounded-xl px-2 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 border-2 dark:border-gray-500 shadow-md h-14 md:h-12 active:shadow"
                  onClick={() => cancelBoostTooltip()}
                >
                  Cancel
                </div>
                <div
                  onClick={() => positiveResponse("boost")}
                  className="transition-all duration-500 ease-out font-bold cursor-pointer flex items-center justify-center rounded-xl text-white px-2 bg-green  hover:bg-opacity-90 h-14 md:h-12 shadow-md active:shadow select-none"
                >
                  Boost
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={showMemorizeTooltip}
        timeout={400}
        className={`fixed inset-0 items-center justify-center bg-black bg-opacity-0 w-full ${
          showMemorizeTooltip ? "" : "pointer-events-none"
        }`}
        classNames="modal-background"
      >
        <div>
          <div className="advanced-response-tooltip">
            <div className="flex flex-col justify-center items-center w-full md:w-[394px] mx-auto">
              <div className="text-6xl text-green">
                <i className="fa-regular fa-hundred-points"></i>
              </div>

              <p className="mt-6 text-center">
                Mark words{" "}
                <span className="font-black text-green">MEMORIZED</span> when
                you are certain you'll never forget them. You won't be tested on
                them again.
              </p>
              <div className="grid grid-cols-2 gap-6 md:gap-5 w-full font-semibold text-base mt-8">
                <div
                  id="end-session-button"
                  className="transition-all duration-500 ease-out font-normal cursor-pointer flex items-center justify-center rounded-xl px-2 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 border-2 dark:border-gray-500 shadow-md h-14 md:h-12 active:shadow"
                  onClick={() => cancelMemorizeTooltip()}
                >
                  Cancel
                </div>
                <div
                  onClick={() => positiveResponse("memorize")}
                  className="transition-all duration-500 ease-out font-bold cursor-pointer flex items-center justify-center rounded-xl text-white px-2 bg-green hover:bg-opacity-90 h-14 md:h-12 shadow-md active:shadow select-none"
                >
                  Memorize
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
