import React, { useRef, useState, useLayoutEffect } from "react";

import { gsap } from "gsap";
import { _round } from "gsap/gsap-core";

export default function WordsLearnedList({
  list,
  delay,
  topMargin,
  seenNewScreen,
}) {
  const barWidthRef = useRef();
  const listRowRef = useRef();
  const explosionDecorationRef = useRef();
  const awardNoticeRef = useRef();
  const awardNoticeTextRef = useRef();
  const r = gsap.utils.selector(listRowRef);

  const explosionTL = useRef();

  const numWordsLearned =
    list.num_words_known_at_end - list.num_words_known_at_start;

  const wordsLearnedNumber = { val: 0 }; // starts at 0, will tween to numWordsLearned
  const wordsLearnedNumberRef = useRef(); // reference to DOM element we will modify with current value of wordsLearnedNumber

  const wordsRemainingNumber = { val: 0 }; // starts at 0, will tween to numWordsLearned
  const wordsRemainingNumberRef = useRef(); // reference to DOM element we will modify with current value of wordsRemaining

  const wordsKnownNumber = { val: list.num_words_known_at_start };
  const wordsKnownNumberRef = useRef(); // reference to DOM element we will modify

  const wordsKnownPercent = { val: 0 };
  const wordsKnownPercentRef = useRef(); // reference to DOM element we will modify

  const snapWordsLearned = () => {
    var final = gsap.utils.snap(1, wordsLearnedNumber.val);
    wordsLearnedNumberRef.current.innerHTML = final;
  };

  const snapWordsRemaining = () => {
    var final = gsap.utils.snap(1, wordsRemainingNumber.val);
    wordsRemainingNumberRef.current.innerHTML = final;
  };

  const snapWordsKnown = () => {
    var final = gsap.utils.snap(1, wordsKnownNumber.val);
    wordsKnownNumberRef.current.innerHTML = final;
  };

  const snapWordsKnownPercent = () => {
    var final = gsap.utils.snap(1, wordsKnownPercent.val);
    wordsKnownPercentRef.current.innerHTML = final;
  };

  function explodeIfNecessary() {
    if (list.num_words_known_at_end === list.word_ids.length) {
      explosionTL.current = gsap
        .timeline()
        .to(barWidthRef.current, {
          backgroundColor: "#fdd065",
          scale: 1.1,
          duration: 0.3,
        })
        .to(barWidthRef.current, {
          backgroundColor: "#118ab2",
          scale: 1.0,
          duration: 0.3,
        })

        .to(
          awardNoticeRef.current,
          {
            opacity: 1,
            duration: 0.3,
          },
          "<"
        )
        .from(r(".award-elem"), {
          x: 200,
          opacity: 0,
          scale: 0.8,
          stagger: 0.1,
          duration: 0.6,
          ease: "back",
        })

        .to(listRowRef.current, {
          color: "white",
          duration: 0,
        })
        .to(
          awardNoticeRef.current,
          {
            opacity: 0,
            duration: 1,
          },
          ">+2"
        )
        .to(
          r(".award-elem"),
          {
            scale: 0.8,
            opacity: 0,
            x: -50,
            stagger: 0.1,
            duration: 0.4,
            ease: "back",
          },
          "<-0.8"
        );
    }
  }

  useLayoutEffect(() => {
    let tl = gsap
      .timeline({ onComplete: explodeIfNecessary })
      .to(barWidthRef.current, {
        width: `${(list.num_words_known_at_end / list.word_ids.length) * 100}%`,
        duration: 2,
        delay: delay,
        ease: "power2.in",
      })
      .fromTo(
        wordsLearnedNumber,
        { val: 0 },
        {
          val: numWordsLearned,
          duration: 2,
          onUpdate: snapWordsLearned,
          ease: "power2.in",
        },
        "<"
      )
      .fromTo(
        wordsRemainingNumber,
        { val: list.word_ids.length - list.num_words_known_at_start },
        {
          val: list.word_ids.length - list.num_words_known_at_end,
          duration: 2,
          onUpdate: snapWordsRemaining,
          ease: "power2.in",
        },
        "<"
      )
      .fromTo(
        wordsKnownNumber,
        { val: list.num_words_known_at_start },
        {
          val: list.num_words_known_at_end,
          duration: 2,
          onUpdate: snapWordsKnown,
          ease: "power2.in",
        },
        "<"
      )
      .fromTo(
        wordsKnownPercent,
        { val: (list.num_words_known_at_start / list.word_ids.length) * 100 },
        {
          val: (list.num_words_known_at_end / list.word_ids.length) * 100,
          duration: 2,
          onUpdate: snapWordsKnownPercent,
          ease: "power2.in",
        },
        "<"
      );

    if (seenNewScreen) {
      tl.progress({ value: 1, suppressEvents: true });
    }

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div
      className="tr relative flex dark:text-gray-300"
      key={`i-${list.focus_id}`}
      ref={listRowRef}
    >
      <div className="px-3 h-10 bg-gray-100 dark:bg-gray-600 border-white dark:border-gray-800 rounded-l-xl flex-grow">
        <div className="flex items-center absolute justify-between top-0 left-0 h-10 w-full z-40 px-3">
          <div>{list.title}</div>
        </div>
        <div
          ref={barWidthRef}
          className={`explodable absolute top-[${topMargin}px] left-0 h-10 w-full z-30 bg-gray-300 dark:bg-gray-600 rounded-xl`}
          style={{
            width: `${
              (list.num_words_known_at_start / list.word_ids.length) * 100
            }%`,
          }}
        ></div>
        <div
          ref={explosionDecorationRef}
          className={`absolute opacity-0 top-[${topMargin}px] left-0 h-10 w-full z-30 bg-yellow rounded-xl`}
        ></div>
        <div
          ref={awardNoticeRef}
          className={`absolute flex items-center justify-center opacity-0 top-[${topMargin}px] left-0 h-10 w-full z-50 text-white rounded-xl bg-award sm:text-lg lg:text-2xl`}
        >
          <span ref={awardNoticeTextRef}>
            <i className="fa-solid fa-star mx-1 sm:mx-2 award-elem"></i>
            <i className="fa-solid fa-star mx-1 sm:mx-2 award-elem"></i>
            <i className="fa-solid fa-star mx-1 sm:mx-2 award-elem"></i>
            <div className="inline-block text-lg font-black award-elem mx-2 sm:mx-4">
              List Complete!
            </div>
            <i className="fa-solid fa-star mx-1 sm:mx-2 award-elem"></i>
            <i className="fa-solid fa-star mx-1 sm:mx-2 award-elem"></i>
            <i className="fa-solid fa-star mx-1 sm:mx-2 award-elem"></i>
          </span>
        </div>
      </div>

      <div className="hidden sm:table-cell relative w-[100px] px-3 h-10 text-center explodable-text">
        <div className="absolute flex items-center justify-center top-0 left-0 w-full h-full z-40 px-3">
          {" "}
          +<span ref={wordsLearnedNumberRef}></span>
        </div>
        <div className="absolute top-0 left-0 h-10 w-full z-10 bg-gray-100 dark:bg-gray-700"></div>
      </div>

      <div className="hidden sm:table-cell relative w-[100px] px-3 h-10 text-center explodable-text">
        <div
          className="absolute flex items-center justify-center top-0 left-0 w-full h-10 z-40 px-3"
          ref={wordsRemainingNumberRef}
        ></div>
        <div className="absolute top-0 left-0 h-10 w-full z-10 bg-gray-100 dark:bg-gray-700"></div>
      </div>

      <div className="relative w-[132px] px-3 h-10 text-center rounded-r-xl">
        <div className="absolute flex items-center justify-end top-0 left-0 w-full h-full z-40 pr-3">
          {" "}
          <div className="text-right">
            <span ref={wordsKnownNumberRef}></span>/{list.word_ids.length}
          </div>
          <div className="text-right ml-3">
            (<span ref={wordsKnownPercentRef}></span>
            %)
          </div>
        </div>
        <div
          className={`absolute top-[${topMargin}px] left-0 h-10 w-full z-10 bg-gray-100 dark:bg-gray-700 rounded-r-xl`}
        ></div>
      </div>
    </div>
  );
}
