import React, { useState, useEffect } from "react";

export default function ImportExportSettings({}) {
  return (
    <>
      <div className="pt-14 text-4xl font-bold">Import / Export</div>
      <div className="flex pt-20">
        import from.... excel to start. export a list of learned words.
      </div>
    </>
  );
}
