import React, { useEffect, useState } from "react";
import { API_ENDPOINT } from "./constants";

export default function AudioSettings({
  token,
  saveSettings,
  playbackGender,
  setPlaybackGender,
  playbackSpeed,
  setPlaybackSpeed,
}) {
  const [sentenceAudioMaleSlow, setSentenceAudioMaleSlow] = useState("");
  const [sentenceAudioMaleMedium, setSentenceAudioMaleMedium] = useState("");
  const [sentenceAudioMaleFast, setSentenceAudioMaleFast] = useState("");
  const [sentenceAudioFemaleSlow, setSentenceAudioFemaleSlow] = useState("");
  const [sentenceAudioFemaleMedium, setSentenceAudioFemaleMedium] =
    useState("");
  const [sentenceAudioFemaleFast, setSentenceAudioFemaleFast] = useState("");

  useEffect(() => {
    fetch(`${API_ENDPOINT}/voice_examples`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setSentenceAudioMaleSlow(response.data.sentence_audio_url_ms);
        setSentenceAudioMaleMedium(response.data.sentence_audio_url_mm);
        setSentenceAudioMaleFast(response.data.sentence_audio_url_mf);
        setSentenceAudioFemaleSlow(response.data.sentence_audio_url_fs);
        setSentenceAudioFemaleMedium(response.data.sentence_audio_url_fm);
        setSentenceAudioFemaleFast(response.data.sentence_audio_url_ff);
      });
  }, []);

  function playAudioById(id) {
    document.getElementById(id).play();
  }

  function handleSetPlaybackGender(gender) {
    setPlaybackGender(gender);
    saveSettings("voice_gender", gender);
  }

  function handleSetPlaybackSpeed(speed) {
    setPlaybackSpeed(speed);
    saveSettings("voice_speed", speed);
  }

  return (
    <>
      <div className="hidden">
        <div className="setting-title mt-4">Pronunciation</div>
        <div className="flex mt-2">
          <div className="grid grid-cols-2 gap-0 text-gray-400 font-semibold">
            <div
              className={`multi-toggle ${
                playbackGender === "female" ? "selected" : ""
              }`}
              onClick={() => setPlaybackGender("female")}
            >
              Mainland
            </div>
            <div
              className={`multi-toggle ${
                playbackGender === "male" ? "selected" : ""
              }`}
              onClick={() => setPlaybackGender("male")}
            >
              Taiwanese
            </div>
          </div>
        </div>

        <div className="mt-3 text-sm w-full px-1 text-gray-400 xpb-10 xborder-b xborder-gray-100 xdark:border-gray-600">
          While there are numerous dialects of Chinese, most students learn
          standard "Beijing" pronunciation. If you are living in Taiwan, there
          are some noticeable differences.
        </div>
        <div className="flex items-center mt-3 pb-10 border-b border-gray-100 dark:border-gray-600">
          <div className="flex items-center justify-center w-9 h-9 rounded-full bg-gray-100 dark:bg-gray-700 text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer group-hover:text-gray-500 dark:group-hover:text-gray-400">
            <i className="fas fa-play relative left-[2px]"></i>
          </div>
          <div className="simplified text-2xl ml-3">
            你有问题可以打电话给我。
          </div>
        </div>
      </div>

      <div className="setting-title mt-4">Playback Gender</div>
      <div className="flex mt-2">
        <div className="grid grid-cols-2 gap-0 text-gray-400 font-semibold">
          <div
            className={`multi-toggle ${
              playbackGender === "female" ? "selected" : ""
            }`}
            onClick={() => handleSetPlaybackGender("female")}
          >
            Female
          </div>
          <div
            className={`multi-toggle ${
              playbackGender === "male" ? "selected" : ""
            }`}
            onClick={() => handleSetPlaybackGender("male")}
          >
            Male
          </div>
        </div>
      </div>

      <div
        className="flex items-center mt-3"
        onClick={() =>
          playAudioById(`sentence_audio_${playbackGender}_${playbackSpeed}`)
        }
      >
        <div className="flex items-center justify-center w-9 h-9 rounded-full bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer group-hover:text-gray-500 dark:group-hover:text-gray-400 shrink-0 ">
          <i className="fas fa-play relative left-[2px]"></i>
        </div>
        <div className="simplified text-2xl ml-3">你有问题可以打电话给我。</div>
      </div>
      <p>
        Listening to (and then emulating) your own gender may help you sound
        more natural.
      </p>
      <div className="setting-title mt-12">Playback Speed</div>
      <div className="flex mt-2">
        <div className="grid grid-cols-3 gap-0 text-gray-400 font-semibold">
          <div
            className={`multi-toggle ${
              playbackSpeed === "slow" ? "selected" : ""
            }`}
            onClick={() => handleSetPlaybackSpeed("slow")}
          >
            Slow
          </div>
          <div
            className={`multi-toggle ${
              playbackSpeed === "medium" ? "selected" : ""
            }`}
            onClick={() => handleSetPlaybackSpeed("medium")}
          >
            Medium
          </div>
          <div
            className={`multi-toggle ${
              playbackSpeed === "fast" ? "selected" : ""
            }`}
            onClick={() => handleSetPlaybackSpeed("fast")}
          >
            Fast
          </div>
        </div>
      </div>

      <div
        className="flex items-center mt-3"
        onClick={() =>
          playAudioById(`sentence_audio_${playbackGender}_${playbackSpeed}`)
        }
      >
        <div className="flex items-center justify-center w-9 h-9 rounded-full cursor-pointer bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-400 hover:text-gray-500 dark:hover:text-gray-400">
          <i className="fas fa-play relative left-[2px]"></i>
        </div>
        <div className="simplified text-2xl ml-3">你有问题可以打电话给我。</div>
      </div>
      <p className="no-border">
        We recommend beginners focus on pronunciation and tones with slow spoken
        audio, while advanced listeners focus on increasing the speed at which
        they can comprehend what they are hearing.
      </p>
      <audio id="sentence_audio_male_slow" src={sentenceAudioMaleSlow}></audio>
      <audio
        id="sentence_audio_male_medium"
        src={sentenceAudioMaleMedium}
      ></audio>
      <audio id="sentence_audio_male_fast" src={sentenceAudioMaleFast}></audio>
      <audio
        id="sentence_audio_female_slow"
        src={sentenceAudioFemaleSlow}
      ></audio>
      <audio
        id="sentence_audio_female_medium"
        src={sentenceAudioFemaleMedium}
      ></audio>
      <audio
        id="sentence_audio_female_fast"
        src={sentenceAudioFemaleFast}
      ></audio>
    </>
  );
}
