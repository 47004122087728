import React, { useRef, useState, useLayoutEffect, useEffect } from "react";

import { gsap } from "gsap";
import { _round } from "gsap/gsap-core";

export default function NewScreen({
  wordAssumptionsChecked,
  activeScreen,
  seenAssumedScreen,
  setSeenAssumedScreen,
  characterSet,
}) {
  // Calculated Variables
  const numAssumedCardsKnown = wordAssumptionsChecked.filter((card) => {
    return (
      card.first_result === "pass" ||
      card.first_result === "boost" ||
      card.first_result === "memorized"
    );
  }).length;
  const numAssumedCardsUnknown =
    wordAssumptionsChecked.length - numAssumedCardsKnown;

  const roundAssumptionsKnownNumber = () => {
    var final = gsap.utils.snap(1, assumptionsKnownNumber.val);
    assumptionsKnownNumberRef.current.innerHTML = final;
  };
  const roundAssumptionsUnKnownNumber = () => {
    var final = gsap.utils.snap(1, assumptionsUnknownNumber.val);
    assumptionsUnknownNumberRef.current.innerHTML = final;
  };

  // DOM References & Tween Values
  const knownWordsAdviceRef = useRef();
  const unknownWordsAdviceRef = useRef();
  const assumedKnownCorrectBoxRef = useRef();
  const assumedKnownIncorrectBoxRef = useRef();
  const assumedScoreBoxesRef = useRef();
  const q = gsap.utils.selector(assumedScoreBoxesRef);
  const assumedWordsBoxRef = useRef();
  const r = gsap.utils.selector(assumedWordsBoxRef);
  const assumptionsKnownNumberRef = useRef();
  const assumptionsKnownNumber = { val: 0 };
  const assumptionsUnknownNumberRef = useRef();
  const assumptionsUnknownNumber = { val: 0 };

  useLayoutEffect(() => {
    let tl = gsap
      .timeline({
        onComplete: setSeenAssumedScreen,
        onCompleteParams: [true],
      })
      .from(q("div.scorebox"), {
        scale: 0.5,
        opacity: 0,
        stagger: 0.2,
        duration: 1,
        ease: "back",
      })
      .from(r("div"), {
        scale: 0.75,
        opacity: 0,
        stagger: 0.01,
        duration: 0.5,
        ease: "back",
      })
      .fromTo(
        assumptionsKnownNumber,
        { val: 0 },
        {
          val: numAssumedCardsKnown,
          duration: 25 * 0.03 + 0.3,
          onUpdate: roundAssumptionsKnownNumber,
        }
      )
      .to(
        r("div.known"),
        {
          backgroundColor: "#0ad6a0",
          stagger: { each: 0.03, from: "random" },
          duration: 0.3,
        },
        "<"
      )
      .from(knownWordsAdviceRef.current, { opacity: 0, y: -10, duration: 0.6 })
      .fromTo(
        assumptionsUnknownNumber,
        { val: 0 },
        {
          val: numAssumedCardsUnknown,
          duration: 20 * 0.03 + 0.3,
          onUpdate: roundAssumptionsUnKnownNumber,
        }
      )
      .to(
        r("div.unknown"),
        {
          backgroundColor: "#118ab2",
          stagger: { each: 0.03, from: "random" },
          duration: 0.3,
        },
        "<"
      )
      .from(unknownWordsAdviceRef.current, {
        opacity: 0,
        y: -10,
        duration: 0.6,
      });

    if (seenAssumedScreen) {
      tl.progress(1);
    }

    return () => {
      setSeenAssumedScreen(true);
      tl.kill();
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full max-w-screen-lg px-3">
      <div
        className="grid grid-cols-2 gap-3 md:gap-6 mx-auto w-full max-w-xl text-base sm:text-lg lg:text-2xl"
        ref={assumedScoreBoxesRef}
      >
        <div
          className="scorebox flex flex-col items-center justify-center bg-green rounded-xl p-3 sm:p-6 lg:p-10 text-white"
          ref={assumedKnownCorrectBoxRef}
        >
          <div
            className="text-5xl sm:text-6xl lg:text-7xl font-black"
            ref={assumptionsKnownNumberRef}
          >
            0
          </div>
          <div className="text-center mt-1 sm:mt-2 lg:mt-3">
            Words You
            <br />
            Knew
          </div>
        </div>
        <div
          className="scorebox flex flex-col items-center justify-center bg-blue rounded-xl p-3 sm:p-6 lg:p-10 text-white"
          ref={assumedKnownIncorrectBoxRef}
        >
          <div
            className="text-5xl sm:text-6xl lg:text-7xl font-black"
            ref={assumptionsUnknownNumberRef}
          >
            0
          </div>
          <div className="text-center mt-1 sm:mt-2 lg:mt-3">
            Words You
            <br />
            Did Not Know
          </div>
        </div>
        <p
          className="text-sm sm:text-base lg:text-lg text-green text-center px-6"
          ref={knownWordsAdviceRef}
        >
          Given longer intervals than new words.
        </p>
        <p
          className="text-sm sm:text-base lg:text-lg text-blue dark:brightness-125 text-center px-6 "
          ref={unknownWordsAdviceRef}
        >
          Scheduled for reviews as new words.
        </p>
      </div>

      <div
        className="transition-all duration-[3s] flex text-2xl simplified text-gray-600 mt-6 w-full max-w-screen-sm mx-auto flex-wrap justify-center pb-24"
        ref={assumedWordsBoxRef}
      >
        {wordAssumptionsChecked.map((card, i) => {
          return (
            <div
              key={`wac-${i}`}
              className={`${
                card.first_result === "pass" ? "known" : "unknown"
              } flex items-center justify-center mr-1 mb-1 py-1 px-2 bg-purple text-white rounded-xl`}
            >
              {characterSet === "simplified" && card.simplified}
              {characterSet != "simplified" && card.traditional}
            </div>
          );
        })}
      </div>
    </div>
  );
}
