import React, { useState } from "react";
import { API_ENDPOINT } from "./constants";

export default function SubmitWordForApprovalFromImport({
  token,
  missingWord,
  key,
  listToAddTo,
}) {
  const [submitted, setSubmitted] = useState(false);

  function submitWordForApproval(e) {
    e.preventDefault();

    const data = {
      missing_word: missingWord,
      focus_id: listToAddTo,
    };

    fetch(`${API_ENDPOINT}/pending_words`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        setSubmitted(true);
      } else {
        console.log("Could not submit this word.");
      }
    });
  }

  return (
    <form
      className="flex w-full"
      onSubmit={(e) => {
        submitWordForApproval(e);
      }}
    >
      <div
        className={`group relative w-full items-center flex text-sm lg:text-base h-16 ${
          key > 0 ? "border-t dark:border-gray-600" : ""
        }`}
      >
        <div className="absolute w-full h-full top-[2px] left-0 z-0 px-3">
          <div
            className={`${
              submitted ? "border-blue-brand" : ""
            } transition-colors duration-75 w-full h-1/2 border-b-2 border-dashed`}
          ></div>
        </div>
        <div className="flex items-center h-12 flex-grow z-10">
          <div
            className={`flex items-center justify-center text-3xl rounded-xl px-2 h-full ${
              submitted
                ? "bg-blue-brand text-white"
                : "bg-gray-100 dark:bg-gray-600"
            }  simplified`}
          >
            {missingWord}
          </div>
        </div>
        <div className="z-10 w-28 flex-shrink-0 flex items-center justify-center font-semibold bg-white h-12 ml-6">
          {!submitted && (
            <input
              className="z-10 w-full cursor-pointer flex items-center justify-center bg-blue-neon hover:bg-blue-neon/90 dark:hover-bg-blue-10 text-white h-full rounded-xl"
              type="submit"
              value="Submit"
            />
          )}
          {submitted && (
            <div className="z-10 w-full cursor-pointer flex items-center justify-center bg-blue-brand text-white h-full rounded-xl">
              Submitted!
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
