import React, { useState, useRef, useEffect, useLayoutEffect } from "react";

import { gsap } from "gsap";
import { _round } from "gsap/gsap-core";
import WordsLearnedList from "./WordsLearnedList";

export default function NewScreen({
  cards,
  vocabularyLists,
  seenNewScreen,
  setSeenNewScreen,
  playAudioFromTable,
  characterSet,
}) {
  const wordsLearned = cards.filter((card) => {
    return card.type === "new" && card.first_result != null;
  });

  const newWordsProgressBarsRef = useRef();
  const newWordsTableRef = useRef();

  const q = gsap.utils.selector(newWordsProgressBarsRef);
  const r = gsap.utils.selector(newWordsTableRef);

  useLayoutEffect(() => {
    let tl = gsap
      .timeline({ onComplete: setSeenNewScreen, onCompleteParams: [true] })
      .from(q(".tr"), {
        y: -100,
        opacity: 0,
        scale: 0.8,
        stagger: 0.1,
        duration: 0.6,
        ease: "back",
      })
      .from(r("tr"), {
        y: -100,
        opacity: 0,
        scale: 0.8,
        stagger: 0.1,
        duration: 0.6,
        ease: "back",
      });

    if (seenNewScreen) {
      tl.progress(1);
    }

    return () => {
      setSeenNewScreen(true);
      tl.kill();
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full max-w-screen-lg px-3 text-gray-600">
      <div
        id="this-is-the-table"
        className="flex flex-col space-y-[2px] w-full"
        ref={newWordsProgressBarsRef}
      >
        <div className="tr flex w-full text-sm antialiased font-semibold text-center">
          <div className="flex items-center px-3 h-10 bg-gray-100 dark:bg-gray-700 rounded-l-xl border-r border-white dark:border-gray-600 text-gray-400 text-left flex-grow">
            LIST
          </div>

          <div className="hidden sm:flex items-center  w-[100px] px-3 h-10 bg-gray-100 dark:bg-gray-700 border-x border-white dark:border-gray-600 justify-center text-gray-400">
            LEARNED
          </div>

          <div className="hidden sm:flex items-center w-[100px] px-3 h-10 bg-gray-100 dark:bg-gray-700 border-x border-white dark:border-gray-600 justify-center text-gray-400">
            REMAINING
          </div>
          <div className="flex items-center w-[132px] px-3 h-10 bg-gray-100 dark:bg-gray-700 border-l border-white dark:border-gray-600 justify-center text-gray-400 rounded-r-xl">
            KNOWN
          </div>
        </div>

        {vocabularyLists[0] && (
          <WordsLearnedList
            list={vocabularyLists[0]}
            delay={1}
            seenNewScreen={seenNewScreen}
          />
        )}
        {vocabularyLists[1] && (
          <WordsLearnedList
            list={vocabularyLists[1]}
            delay={3}
            seenNewScreen={seenNewScreen}
          />
        )}
        {vocabularyLists[2] && (
          <WordsLearnedList
            list={vocabularyLists[2]}
            delay={4}
            seenNewScreen={seenNewScreen}
          />
        )}
      </div>

      <div className="mt-10 pb-32 sm:pb-24">
        <div className="hidden font-medium text-xl font-bold mb-3">
          Words learned:
        </div>
        <table
          className="victory-table-striped text-lg w-full pb-24"
          ref={newWordsTableRef}
        >
          <tbody>
            {wordsLearned.map((card, i) => {
              return (
                <tr key={i} className="dark:text-gray-300">
                  <td className="px-2 py-1 w-[64px]">
                    {" "}
                    <div
                      className="group flex items-center justify-center cursor-pointer w-12 h-12 text-sm"
                      onClick={() => playAudioFromTable(card.word_id)}
                    >
                      <audio
                        id={card.word_id}
                        src={card.word_audio_url}
                      ></audio>
                      <div className="victory-screen-play-button-container">
                        <i className="fas fa-play ml-2px"></i>
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-1 text-3xl simplified text-blue">
                    <span className="dark:brightness-125">
                      {characterSet === "simplified" && card.simplified}
                      {characterSet != "simplified" && card.traditional}
                    </span>
                  </td>
                  <td className="hidden sm:table-cell px-4 py-1 text-xl font-ubuntu-condensed">
                    {card.opinyin}
                  </td>

                  <td className="hidden sm:table-cell px-2 py-1 font-ubuntu-condensed">
                    {card.common_definitions[0]}
                    {card.proper_nouns[0]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
