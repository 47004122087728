import React from "react";
import SubmitWordForApprovalFromImport from "./SubmitWordForApprovalFromWordImport";

export default function WordImportNewToDatabase({
  token,
  focusId,
  missingWords,
  numMissing,
  setMissingComplete,
  advanceToNextScreen,
}) {
  function handleSetMissingComplete() {
    setMissingComplete(true);
    advanceToNextScreen();
  }

  return (
    <div className="flex flex-col">
      <div className="text-3xl mx-auto">Missing</div>

      {numMissing == 0 && (
        <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
          Every word you added to this list was already in our dictionary. How
          about that!
        </div>
      )}

      {numMissing > 0 && (
        <>
          <div className="mt-3 w-full max-w-prose text-lg mx-auto text-center">
            These words were not found. If you have typed them correctly and
            they belong in our dictionary, please submit them for review. If
            approved, we will add them to our dictionary and to your list.{" "}
          </div>

          <div className="flex flex-col mt-6">
            {missingWords.split("/").map((word, i) => {
              return (
                <SubmitWordForApprovalFromImport
                  token={token}
                  missingWord={word}
                  key={i}
                  focusId={focusId}
                />
              );
            })}
          </div>

          <div className="bg-gray-100 dark:bg-gray-700  w-full p-12 rounded-xl flex items-center justify-between">
            <div className="flex flex-col w-1/2">
              <div className="text-xl">
                After choosing which words you'd like to submit for review,
                click 'Confirm' to mark this page complete and continue with
                your import.
              </div>
            </div>
            <div
              onClick={() => handleSetMissingComplete()}
              className="py-3 text-xl font-medium px-6 bg-blue-brand hover:bg-blue-brand/90 cursor-pointer text-white rounded-xl"
            >
              Confirm
              <i className="fa-regular fa-arrow-right-long ml-3"></i>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
