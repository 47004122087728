import React, { useState, useEffect } from "react";
import { API_ENDPOINT } from "./constants";

export default function PreviouslySubmittedWordRow({
  token,
  listsAndFocuses,
  pendingWord,
}) {
  const [listToAddTo, setListToAddTo] = useState(listsAndFocuses[0][1]);
  const [cssClasses, setCSSClasses] = useState("pointer-events-none");
  const [reSubmitted, setReSubmitted] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const MakeOption = function (listAndFocus, i) {
    return (
      <option key={i} value={listAndFocus[1]}>
        {listAndFocus[0]["name"]}
      </option>
    );
  };

  useEffect(() => {
    if (reSubmitted) {
      setCSSClasses("bg-green/50 duration-1000 transition");
    } else if (cancelled) {
      setCSSClasses("bg-red/50 duration-1000 transition");
    }
  }, [reSubmitted, cancelled]);

  const onChange = (event) => {
    const value = event.target.value;
    setListToAddTo(value);
  };

  function reSubmitWordForApproval(e) {
    e.preventDefault();

    setReSubmitted(true);

    const data = {
      missing_word: pendingWord.simplified,
      focus_id: listToAddTo,
    };

    fetch(`${API_ENDPOINT}/pending_words`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
      } else {
        console.log("Could not submit this word.");
      }
    });
  }

  function cancel() {
    setCancelled(true);

    fetch(`${API_ENDPOINT}/words/${pendingWord["id"]}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    }).then((response) => {});
  }

  return (
    <form
      className="relative flex w-full h-14"
      onChange={onChange}
      onSubmit={(e) => {
        reSubmitWordForApproval(e);
      }}
    >
      <div className={`absolute inset-0 bg-transparent ${cssClasses}`}></div>
      <div className="flex-grow flex items-center">
        <div className="bg-gray-100 flex items-center justify-center px-2 rounded-xl h-full">
          {pendingWord.simplified}
        </div>
      </div>
      <select className="outline-none  h-14 w-96 bg-gray-100 dark:bg-gray-600 rounded-xl p-2">
        {listsAndFocuses.map(MakeOption)}
      </select>
      {!reSubmitted && (
        <input
          className="w-28 flex-shrink-0 cursor-pointer flex items-center justify-center font-bold bg-blue/30 hover:bg-blue/20 dark:hover-bg-blue-10 text-blue h-full rounded-xl ml-6"
          type="submit"
          value="Submit"
        />
      )}
      {reSubmitted && (
        <div className="w-28 flex-shrink-0 cursor-pointer flex items-center justify-center font-bold bg-gray-200 text-gray-800 h-full rounded-xl ml-6">
          Submitted!
        </div>
      )}
      <div
        onClick={() => cancel()}
        className="w-28 border-2 border-red text-red hover:bg-red/10 flex-shrink-0 cursor-pointer flex items-center justify-center font-bold h-full rounded-xl ml-6"
      >
        Delete <i className="fa-regular fa-xmark ml-3 text-xl"></i>
      </div>
    </form>
  );
}
