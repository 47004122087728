import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { gsap } from "gsap";

export default function RegularStudyModes({
  setMode,
  setStudyModesToDisplay,
  controller,
}) {
  const tl = useRef();
  const exitTL = useRef();
  const regularStudyModesRef = useRef();
  const rsm = gsap.utils.selector(regularStudyModesRef);
  const closeModalButtonRef = useRef();

  function closeNewStudySessionModal() {
    document.getElementById("new-study-session-modal").style.display = "none";
  }

  function applyHoverAnimations() {
    gsap.utils.toArray(".study-mode-btn").forEach((button) => {
      let hover = gsap.to(button, {
        scale: 1.1,
        duration: 0.075,
        paused: true,
        ease: "power1.inOut",
      });
      button.addEventListener("mouseenter", () => hover.play());
      button.addEventListener("mouseleave", () => hover.reverse());
    });
  }

  useLayoutEffect(() => {
    tl.current = gsap
      .timeline({ onComplete: applyHoverAnimations })

      .from(closeModalButtonRef.current, {
        x: -50,
        opacity: 0,
        stagger: 0.025,
        duration: 0.2,
      })

      .from(
        rsm(".study-mode-btn"),
        {
          x: -50,
          opacity: 0,
          stagger: 0.025,
          duration: 0.2,
        },
        ">-.125"
      );
  }, []);

  function swapToCustomStudyModes() {
    exitTL.current = gsap
      .timeline({
        onComplete: setStudyModesToDisplay,
        onCompleteParams: ["custom"],
      })
      .to(rsm(".study-mode-btn"), {
        x: -50,
        opacity: 0,
        stagger: 0.025,
        duration: 0.2,
      })
      .to(
        closeModalButtonRef.current,
        {
          x: -50,
          opacity: 0,
          stagger: 0.025,
          duration: 0.2,
        },
        ">-.125"
      );
  }
  return (
    <div
      className="regular-study-modes flex flex-col lg:flex-row justify-center px-6"
      ref={regularStudyModesRef}
    >
      <div
        className={`${
          controller === "study_sessions" ? "opacity-0 pointer-events-none" : ""
        } fixed top-0 sm:top-14 left-0 w-full`}
      >
        <div className="a-container sm:px-6 flex h-16 justify-end">
          <div
            ref={closeModalButtonRef}
            className="nav-btn text-gray-400 text-2xl"
            style={{ border: "none" }}
            onClick={() => closeNewStudySessionModal()}
          >
            <div className="flex flex-col items-center justify-center">
              <i className="fa-regular fa-xmark"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        className="study-mode-btn group major h-24"
        onClick={() => setMode("everyday")}
      >
        <div className="study-mode-btn-panel flex w-1/3 lg:w-full group-hover:border-green group-hover:bg-green group-hover:text-white">
          <i className="fa-solid fa-seedling"></i>
        </div>
        <div className="study-mode-btn-desc major w-2/3 lg:w-full group-hover:border-green">
          <div className="lg:text-xl group-hover:text-green font-semibold mb-1 lg:mb-4 dark:text-white">
            Everyday
          </div>
          <div className="text-xs lg:text-sm lg:text-center">
            Jump into a study session optimized for long-term growth.
          </div>
        </div>
      </div>

      <div
        className="study-mode-btn group major h-24"
        onClick={() => swapToCustomStudyModes()}
      >
        <div className="study-mode-btn-panel flex w-1/3 lg:w-full group-hover:bg-gradient-to-r group-hover:text-white group-hover:from-blue-neon group-hover:to-blue-brand">
          <i className="fa-solid fa-sliders"></i>
        </div>
        <div className="w-full h-full lg:w-auto text-base lg:text-xl lg:h-1/2 py-2px pr-2px lg:p-2px rounded-xl rounded-l-none lg:rounded-xl lg:rounded-t-none bg-gray-200 dark:bg-gray-600 group-hover:bg-gradient-to-r group-hover:from-blue-neon group-hover:to-blue-brand">
          <div className="bg-white dark:bg-gray-800 w-full h-full px-3 lg:p-4 flex flex-col lg:items-center justify-center lg:justify-start rounded-xl rounded-l-none lg:rounded-xl lg:rounded-t-none">
            <div className="flex">
              <div className="lg:text-xl font-semibold mb-1 lg:mb-4 bg-gray-600 dark:bg-white group-hover:bg-gradient-to-r from-blue-neon to-blue-brand bg-clip-text text-transparent">
                Custom
              </div>
            </div>
            <div className="text-xs lg:text-sm lg:text-center">
              Explore a variety of custom study modes.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
