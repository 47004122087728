import React, { useState } from "react";
import DashboardWidgetRangeSelector from "./DashboardWidgetRangeSelector";

export default function ConsistencyWidget({ consistency }) {
  return (
    <div className="flex flex-col p-6 h-full">
      <div className="flex text-xs gap-6">
        {consistency.map((month, i) => {
          return (
            <div key={i} className="flex flex-col">
              <div>{month.month}</div>
              <div className="mt-1 grid grid-cols-7 gap-px">
                {month.days.map((day, z) => {
                  return (
                    <div
                      key={`month-${i}-day-${z}`}
                      className={`h-3 w-3 rounded-sm ${
                        day >= 5 ? "bg-green" : "bg-gray-200"
                      }`}
                    ></div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
