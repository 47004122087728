import React, { useState, useEffect, useRef } from "react";

export default function QuestionResponse({
  toggleOpenSettings,
  highlightHard,
  face,
  card,
  positiveResponse,
  confirmDoneStudying,
  negativeResponse,
  isStudying,
  showAdvancedResponses,
  showReplayButtons,
  undo,
  isStudyingNew,
  playAudio,
  mouseClicks,
  setMouseClicks,
  block,
  boost,
  memorize,
  showMoreResponses,
  setShowMoreResponses,
  isPaused,
  editingNote,
}) {
  const handleUndo = () => {
    undo();
    setShowMoreResponses(false);
  };

  const handleNegativeResponseMouseClick = () => {
    setMouseClicks(mouseClicks + 1);
    negativeResponse();
  };

  const handlePositiveResponseMouseClick = () => {
    setMouseClicks(mouseClicks + 1);
    positiveResponse();
  };

  const handleConfirmDoneStudyingMouseClick = () => {
    setMouseClicks(mouseClicks + 1);
    confirmDoneStudying();
  };

  return (
    <div id="question-response-container">
      <div
        id="question-response-background"
        className="flex flex-col items-end justify-center"
      >
        <div id="question-container" className="relative px-[8px] sm:px-0">
          {card.type === "assumed" && (
            <div className="text-sm md:text-base text-purple text-center mb-4 w-full">
              You may already know this word,
              <br />
              but let's make sure.
            </div>
          )}

          <div className="mb-8 text-base xxs:text-lg md:text-xl text-center text-gray-800 dark:text-gray-200 w-full">
            {isStudyingNew && (
              <div className="q-do-you-know-confirm x">
                This word is new to you.
                <br />
                Take a moment to study it.
              </div>
            )}
            {!isStudyingNew && face.you_forgot && (
              <div className="you-forgot w-full text-center">
                You forgot this word.
                <br />
                Take a moment to study it.
              </div>
            )}
            {face.q_do_you_know && (
              <div className="q-do-you-know w-full text-center">
                Do you know this word?
              </div>
            )}

            {face.q_pinyin_confirm && (
              <div className="q-pinyin-confirm">
                Did you know the pronunciation?
              </div>
            )}
            {face.q_pinyin && (
              <div className="q-english">How do you pronounce this word?</div>
            )}
            {face.q_english && (
              <div className="q-english">
                What is the definition of this word ?
              </div>
            )}
            {face.q_english_confirm && (
              <div className="q-english-confirm">
                Did you know the definition?
              </div>
            )}
            {face.q_do_you_know_confirm && (
              <div className="q-do-you-know-confirm">Were you correct?</div>
            )}
          </div>
          {highlightHard && card.hard && (
            <div className="hard-word-notice">
              This word is difficult for you. Take your time.
            </div>
          )}
        </div>

        <div id="mobile-response-buttons">
          <div className="prime-response-space relative">
            <div
              className={`regular-responses  ${
                showMoreResponses ? "uncover-advanced-responses" : ""
              }`}
            >
              {!isStudying && (
                <div
                  className="response-button negative transition"
                  onClick={() => negativeResponse()}
                >
                  {face.r_no_yes && <span className="text-2xl">No</span>}
                  {face.r_no_think_so && (
                    <span>
                      I don't
                      <br className="lh-rh-tablet-break" /> know
                    </span>
                  )}
                </div>
              )}

              {!isStudying && (
                <div
                  className="response-button positive transition"
                  onClick={() => positiveResponse()}
                >
                  {face.r_no_yes && <span className="text-2xl">Yes</span>}
                  {face.r_no_think_so && (
                    <span>
                      I think
                      <br className="lh-rh-tablet-break" /> I know
                    </span>
                  )}
                </div>
              )}

              {isStudying && (
                <div
                  className="response-button studying col-span-2 bg-blue transition"
                  onClick={() => confirmDoneStudying()}
                >
                  <div>I'm done studying it</div>
                </div>
              )}

              {isStudying && (
                <div
                  className="response-button studying col-span-2 bg-blue transition tablet-only"
                  onClick={() => confirmDoneStudying()}
                >
                  <div>I'm done studying it</div>
                </div>
              )}
            </div>

            <div
              className={`advanced-responses select-none ${
                showMoreResponses ? "show-advanced-responses" : ""
              }`}
            >
              <div
                className="response-button undo"
                onClick={() => handleUndo()}
              >
                Undo
              </div>

              <div className="response-button block" onClick={() => block()}>
                Block
              </div>
              <div className="response-button boost" onClick={() => boost()}>
                <i className="hidden fa-solid fa-rocket-launch"></i>
                <div className="mt-1">Boost</div>
              </div>
              <div
                className="response-button forever"
                onClick={() => memorize()}
              >
                Memorize
              </div>
            </div>
          </div>
          <div className="other-actions select-none">
            <div
              className="response-button settings"
              onClick={() => toggleOpenSettings()}
            >
              <div>
                Pause & <br />
                Settings
              </div>
            </div>

            {showMoreResponses && (
              <div
                className="response-button hide-more"
                onClick={() => setShowMoreResponses(false)}
              >
                {" "}
                <i className="fa-solid fa-ellipsis text-base"></i>
                More
              </div>
            )}

            {!showMoreResponses && (
              <div
                className="response-button show-more"
                onClick={() => setShowMoreResponses(true)}
              >
                {" "}
                <i className="fa-solid fa-ellipsis text-base"></i>
                More
              </div>
            )}

            <div className="other-actions-tablet-spacer"></div>

            <div
              className="response-button word"
              onClick={() => playAudio("word", card.word_id, card.simplified)}
            >
              <i className="fa-solid fa-play mt-2px"></i>
              <div className="mt-[4px]">Word</div>
            </div>
            <div
              className="response-button sentence"
              onClick={() =>
                playAudio("sentence", card.word_id, card.simplified)
              }
            >
              <i className="fa-solid fa-play mt-2px"></i>
              <div className="mt-[4px]">Sentence</div>
            </div>
          </div>
        </div>

        <div id="response-buttons" className="text-xl">
          <div id="replay-spacer" className="opacity-0 select-none"></div>
          {!isStudying && (
            <div
              id="negative-response-button"
              className=""
              onClick={() => handleNegativeResponseMouseClick()}
            >
              <div className="prb-text">
                {face.r_no_yes && <span className="text-2xl">No</span>}
                {face.r_no_think_so && "I don't know"}
              </div>
            </div>
          )}
          {!isStudying && (
            <div
              id="positive-response-button"
              className=""
              onClick={() => handlePositiveResponseMouseClick()}
            >
              <div className="prb-text">
                {face.r_no_yes && <span className="text-2xl">Yes</span>}
                {face.r_no_think_so && "I think I know"}
              </div>
            </div>
          )}

          {isStudying && (
            <div
              className="col-span-4 select-none cursor-pointer flex items-center justify-center h-20 xw-36 rounded-xl text-white hover:bg-opacity-90 bg-blue shadow-lg active:shadow-blue active:shadow"
              onClick={() => handleConfirmDoneStudyingMouseClick()}
            >
              I'm done studying it
            </div>
          )}

          <div
            id="replay-buttons"
            className={`${
              showReplayButtons ? "" : "opacity-0 pointer-events-none"
            }`}
          >
            <div
              id="replay-audio-buttons-label"
              className="absolute flex w-full justify-center items-center text-xs"
            >
              <i className="fa-solid fa-volume"></i>
            </div>
            <div
              className="cursor-pointer w-full flex justify-center items-center shadow active:shadow-sm dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer rounded-xl border-2"
              onClick={() => playAudio("word", card.word_id, card.simplified)}
            >
              Word
            </div>
            <div
              className={`cursor-pointer w-full flex justify-center items-center shadow active:shadow-sm dark:border-gray-600  rounded-xl border-2 ${
                card.sentence
                  ? "cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
                  : "cursor-not-allowed bg-gray-100 dark:bg-gray-600"
              }`}
              onClick={() =>
                playAudio("sentence", card.word_id, card.simplified)
              }
            >
              Sent<span className="hidden md:inline">ence</span>
            </div>
          </div>
        </div>

        <div
          id="advanced-responses"
          className={`hidden md:flex ${
            showAdvancedResponses
              ? "mt-8 h-6 cursor-pointer"
              : "h-0 opacity-0 events-none"
          } items-center justify-center text-xl mt-8 text-gray-400 dark:text-gray-400 transition-all duration-1000 relative w-72`}
        >
          <div
            className="w-[60px] h-[50px] flex items-center justify-center text-gray-400 dark:text-gray-300 hover:text-white dark:hover:text-black group relative"
            onClick={() => block()}
          >
            <div className="bg-gray-200 dark:bg-gray-600 group-hover:bg-black dark:group-hover:bg-white w-[32px] h-[32px] rounded-full flex items-center justify-center text-base">
              <i className="fa-solid fa-ban "></i>
            </div>

            <div className="absolute left-0 top-[37px] group-hover:top-[52px] w-full  opacity-0 transition-all duration-100 group-hover:opacity-100 text-sm text-center pointer-events-none">
              <span className="font-bold text-black dark:text-white">
                Block
              </span>
            </div>
          </div>
          <div
            className="w-[60px] h-[50px] flex items-center justify-center text-gray-400 dark:text-gray-300 hover:text-white dark:hover:text-white group relative"
            onClick={() => boost()}
          >
            <div className="bg-gray-200 dark:bg-gray-600 group-hover:bg-green w-[32px] h-[32px] rounded-full flex items-center justify-center text-base">
              <i className="fa-solid fa-rocket-launch relative top-[1px]"></i>
            </div>
            <div className="absolute left-0 top-[37px] group-hover:top-[52px] w-full  opacity-0 transition-all duration-100 group-hover:opacity-100 text-sm text-center pointer-events-none">
              <span className="font-bold text-green">Boost</span>
            </div>
          </div>
          <div
            className="w-[60px] h-[50px] flex items-center justify-center text-gray-400 dark:text-gray-300 hover:text-white dark:hover:text-white group relative"
            onClick={() => memorize()}
          >
            <div className="bg-gray-200 dark:bg-gray-600 group-hover:bg-green w-[32px] h-[32px] rounded-full flex items-center justify-center text-base ">
              <i className="fa-solid fa-hundred-points relative top-[1px]"></i>
            </div>

            <div className="absolute left-0 top-[37px] group-hover:top-[52px] w-full  opacity-0 transition-all duration-100 group-hover:opacity-100 text-sm text-center pointer-events-none">
              <span className="font-bold text-green">Memorize</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
